import { PaymentState, PaymentActionType } from '../types';
import { PAYMENT_GET_METHODS_ERROR, PAYMENT_GET_METHODS_PENDING, PAYMENT_GET_METHODS_SUCCESS } from '../constants';

const initialState: PaymentState = {
    paymentSources: [],
    getPaymentMethods: {
        state: 'success',
    },
};

export default (state = initialState, action: PaymentActionType): PaymentState => {
    const { type } = action;
    type ActionConverter<A> = Extract<PaymentActionType, { type: A }>;
    switch (type) {
        case PAYMENT_GET_METHODS_PENDING: {
            return {
                ...state,
                getPaymentMethods: {
                    state: 'peding',
                },
            };
        }
        case PAYMENT_GET_METHODS_SUCCESS: {
            const {
                data: { paymentSources },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                paymentSources,
                getPaymentMethods: {
                    state: 'success',
                },
            };
        }
        case PAYMENT_GET_METHODS_ERROR: {
            const {
                data: { errorMessage },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                paymentSources: [],
                getPaymentMethods: {
                    state: 'error',
                    errorMessage,
                },
            };
        }
        default:
            return state;
    }
};
