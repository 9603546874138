import { ICaregiverSubmitOrderParams } from '../../lib/api';
import { IApiAddress, ICaregiverVisitCart } from '../../lib/types';

import { DispatchType } from '../';
import {
    CAREGIVER_CLEAR_CART,
    CAREGIVER_ORDER_SUBMIT_ERROR,
    CAREGIVER_ORDER_SUBMIT_PENDING,
    CAREGIVER_ORDER_SUBMIT_SUCCESS,
    CAREGIVER_REORDER_CART,
    CAREGIVER_SET_CART_DELIVERY_ADDRESS,
    CAREGIVER_SET_CART_END_DATE,
    CAREGIVER_SET_CART_INSTRUCTIONS,
    CAREGIVER_SET_CART_START_DATE,
} from '../constants';
import { submitOrderHelper } from '../utils';

export const submitCaregiverOrderAction = submitOrderHelper<ICaregiverSubmitOrderParams>(
    CAREGIVER_ORDER_SUBMIT_PENDING,
    CAREGIVER_ORDER_SUBMIT_SUCCESS,
    CAREGIVER_ORDER_SUBMIT_ERROR
);

export const setCaregiverVisitCartDeliveryAddressAction = (dispatch: DispatchType) => (
    deliveryAddress: IApiAddress | undefined
) =>
    dispatch({
        type: CAREGIVER_SET_CART_DELIVERY_ADDRESS,
        data: {
            deliveryAddress,
        },
    });

export const setCaregiverVisitCartEndDateAction = (dispatch: DispatchType) => (endDate: Date | undefined) =>
    dispatch({
        type: CAREGIVER_SET_CART_END_DATE,
        data: {
            endDate: endDate?.toISOString(),
        },
    });

export const setCaregiverVisitCartInstructionsAction = (dispatch: DispatchType) => (instructions: string) =>
    dispatch({
        type: CAREGIVER_SET_CART_INSTRUCTIONS,
        data: {
            instructions,
        },
    });

export const setCaregiverVisitCartStartDateAction = (dispatch: DispatchType) => (startDate: Date | undefined) =>
    dispatch({
        type: CAREGIVER_SET_CART_START_DATE,
        data: {
            startDate: startDate?.toISOString(),
        },
    });

export const clearCaregiverVisitCartAction = (dispatch: DispatchType) => () =>
    dispatch({
        type: CAREGIVER_CLEAR_CART,
    });

export const reorderCaregiverCartAction = (dispatch: DispatchType) => (caregiverVisitCart: ICaregiverVisitCart) =>
    dispatch({
        type: CAREGIVER_REORDER_CART,
        data: {
            caregiverVisitCart,
        },
    });
