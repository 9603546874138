import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentMethod } from '@stripe/stripe-js';

import { AddressAutoFillObjectType } from '../../../lib/types';

import AddressAutoFill from '../../../components/AddressAutoFill/AddressAutoFill';
import StripeCardForm from '../../../components/StripeCardForm/StripeCardForm';
import CreditCardUi from '../../../components/CreditCardUi/CreditCardUi';

const stripePromise = process.env.REACT_APP_STRIPE_API_KEY ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY) : null;

export interface FormState {
    cardHolderName?: string;
    errorMessage?: string;
    secondAddressLine?: string;
    state?: string;
    streetAddress?: string;
    stripePaymentMethod?: PaymentMethod;
    zipCode?: string;
}

interface Props {
    formState: FormState;
    setFormState: (newFormState: FormState) => void;
}

const PaymentDialogContent: React.FC<Props> = ({ formState, setFormState }) => {
    const {
        cardHolderName,
        errorMessage,
        secondAddressLine,
        state,
        streetAddress,
        stripePaymentMethod,
        zipCode,
    } = formState;
    const addressAutoFillValue: AddressAutoFillObjectType | undefined = streetAddress
        ? { address: streetAddress, addressState: state, addressZipCode: zipCode }
        : undefined;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box mb={1}>
                    <h5>Card information</h5>
                </Box>
            </Grid>
            {errorMessage ? (
                <Grid item xs={12}>
                    <Typography className="error-message">{errorMessage}</Typography>
                </Grid>
            ) : null}
            <Elements stripe={stripePromise}>
                {stripePaymentMethod && cardHolderName ? (
                    <CreditCardUi cardHolderName={cardHolderName} stripePaymentMethod={stripePaymentMethod} />
                ) : (
                    <StripeCardForm
                        onSuccess={({ cardHolderName, stripePaymentMethod }) =>
                            setFormState({
                                ...formState,
                                cardHolderName,
                                stripePaymentMethod,
                            })
                        }
                        onError={(errorMessage) =>
                            setFormState({
                                ...formState,
                                errorMessage,
                            })
                        }
                    />
                )}
            </Elements>
            <Grid item xs={12}>
                <Box mt={3}>
                    <h5>Billing information</h5>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <AddressAutoFill
                    baseInput
                    placeholder="Street address"
                    parentCallback={(addressObject) => {
                        const { address: streetAddress, addressState: state, addressZipCode: zipCode } =
                            addressObject || {};
                        setFormState({
                            ...formState,
                            streetAddress,
                            state,
                            zipCode,
                        });
                    }}
                    value={addressAutoFillValue}
                />
            </Grid>
            <Grid item xs={12}>
                <input
                    type="text"
                    className="dashboard-input"
                    placeholder="Apartment, suite, unit, building, floor, etc."
                    name="secondAddressLine"
                    onChange={({ target: { value: secondAddressLine } }) =>
                        setFormState({
                            ...formState,
                            secondAddressLine,
                        })
                    }
                    value={secondAddressLine || ''}
                />
            </Grid>
            <Grid item xs={6}>
                <input
                    type="text"
                    className="dashboard-input"
                    placeholder="State"
                    value={state}
                    onChange={({ target: { value: state } }) =>
                        setFormState({
                            ...formState,
                            state,
                        })
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <input
                    type="text"
                    className="dashboard-input"
                    placeholder="Zip code"
                    value={zipCode}
                    onChange={({ target: { value: zipCode } }) =>
                        setFormState({
                            ...formState,
                            zipCode,
                        })
                    }
                />
            </Grid>
        </Grid>
    );
};

export default PaymentDialogContent;
