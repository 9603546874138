import React from 'react';

import { Grid, GridSize } from '@material-ui/core';

interface GridStringInputItemProps {
    placeholder: string;
    setValue: (value: string) => void;
    value: string | undefined;
    xs: GridSize;
}

export const GridStringInputItem: React.FC<GridStringInputItemProps> = ({ value, setValue, placeholder, xs }) => (
    <Grid item xs={xs}>
        <input
            type="text"
            className="dashboard-input"
            placeholder={placeholder}
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
        />
    </Grid>
);

interface GridNumberInputItemProps {
    placeholder: string;
    setValue: (value: number | undefined) => void;
    value: number | undefined;
    xs: GridSize;
}

export const GridNumberInputItem: React.FC<GridNumberInputItemProps> = ({ value, setValue, placeholder, xs }) => (
    <Grid item xs={xs}>
        <input
            type="number"
            className="dashboard-input"
            placeholder={placeholder}
            value={value}
            onChange={({ target: { value: stringValue } }) => {
                if ('' === stringValue) {
                    setValue(undefined);
                } else {
                    const value = Number.parseFloat(stringValue);
                    if (!Number.isNaN(value)) {
                        setValue(value);
                    }
                }
            }}
        />
    </Grid>
);

export const GridIntegerInputItem: React.FC<GridNumberInputItemProps> = ({ value, setValue, placeholder, xs }) => (
    <Grid item xs={xs}>
        <input
            type="number"
            className="dashboard-input"
            placeholder={placeholder}
            value={value}
            onChange={({ target: { value: stringValue } }) => {
                if ('' === stringValue) {
                    setValue(undefined);
                } else {
                    const value = Number.parseInt(stringValue);
                    if (!Number.isNaN(value)) {
                        setValue(value);
                    }
                }
            }}
        />
    </Grid>
);
