import React from 'react';

import { IInsuranceProviderMetaData } from '../../lib/types';

import { Dropdown, TypeSelectValue } from '../../components/Dropdown/Dropdown';

import INSURANCE_PROVIDERS from './insurance_providers';

interface Props {
    insuranceProviderMetaData?: IInsuranceProviderMetaData;
    setInsuranceProviderMetaData: (insuranceProviderMetaData: IInsuranceProviderMetaData | undefined) => void;
}

const InsuranceProviderDropdown: React.FC<Props> = ({ insuranceProviderMetaData, setInsuranceProviderMetaData }) => {
    let selectedValue: TypeSelectValue | undefined = undefined;
    if (insuranceProviderMetaData) {
        const { id: value, name: label } = insuranceProviderMetaData;
        selectedValue = {
            label,
            value,
        };
    }
    return (
        <Dropdown
            selectedValue={selectedValue}
            onChange={({ value }) =>
                typeof value === 'string'
                    ? setInsuranceProviderMetaData(INSURANCE_PROVIDERS.find(({ id }) => id === value))
                    : undefined
            }
            placeholder="Insurance provider"
            options={INSURANCE_PROVIDERS.map(({ name, id }) => ({
                label: name,
                value: id,
            }))}
            wide
        />
    );
};

export default InsuranceProviderDropdown;
