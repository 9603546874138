import React from 'react';
import Cards from 'react-credit-cards';

import { PaymentMethod } from '@stripe/stripe-js';

import 'react-credit-cards/es/styles-compiled.css';

interface Props {
    cardHolderName: string;
    stripePaymentMethod: PaymentMethod;
}

const CreditCardUi: React.FC<Props> = ({ cardHolderName, stripePaymentMethod: { card } }) => {
    if (card) {
        const { brand, last4, exp_month, exp_year } = card;
        return (
            <Cards
                cvc="***"
                expiry={`${exp_month}/${exp_year}`}
                name={cardHolderName}
                number={`**** **** **** ${last4}`}
                issuer={brand}
                preview
            />
        );
    }
    return null;
};

export default CreditCardUi;
