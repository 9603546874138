import {
    IOverTheCounterMedicationCart,
    IOverTheCounterMedicationItem,
    IPrescriptionMedicationCart,
    IPrescriptionMedicationItem,
} from '../../lib/types';
import { IMedicationsSubmitOrderParams } from '../../lib/api';

import { DispatchType } from '../';

import {
    MEDICATIONS_ADD_OVER_THE_COUNTER_MEDICATION_TO_CART,
    MEDICATIONS_ADD_PRESCRIPTION_MEDICATION_TO_CART,
    MEDICATIONS_CLEAR_CARTS,
    MEDICATIONS_ORDER_SUBMIT_ERROR,
    MEDICATIONS_ORDER_SUBMIT_PENDING,
    MEDICATIONS_ORDER_SUBMIT_SUCCESS,
    MEDICATIONS_REORDER_CART,
} from '../constants';
import { submitOrderHelper } from '../utils';

export const submitMedicationsOrderAction = submitOrderHelper<IMedicationsSubmitOrderParams>(
    MEDICATIONS_ORDER_SUBMIT_PENDING,
    MEDICATIONS_ORDER_SUBMIT_SUCCESS,
    MEDICATIONS_ORDER_SUBMIT_ERROR
);

export const addPrescriptionMedicationToCartAction = (dispatch: DispatchType) => (item: IPrescriptionMedicationItem) =>
    dispatch({
        type: MEDICATIONS_ADD_PRESCRIPTION_MEDICATION_TO_CART,
        data: {
            item,
        },
    });

export const addOverTheCouterMedicationToCartAction = (dispatch: DispatchType) => (
    item: IOverTheCounterMedicationItem
) =>
    dispatch({
        type: MEDICATIONS_ADD_OVER_THE_COUNTER_MEDICATION_TO_CART,
        data: {
            item,
        },
    });

export const clearMedicationCartsAction = (dispatch: DispatchType) => () =>
    dispatch({
        type: MEDICATIONS_CLEAR_CARTS,
    });

export const reorderMedicationCartAction = (dispatch: DispatchType) => ({
    overTheCounterMedicationCart,
    prescriptionMedicationCart,
}: {
    overTheCounterMedicationCart: IOverTheCounterMedicationCart;
    prescriptionMedicationCart: IPrescriptionMedicationCart;
}) =>
    dispatch({
        type: MEDICATIONS_REORDER_CART,
        data: { overTheCounterMedicationCart, prescriptionMedicationCart },
    });
