import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Grid } from '@material-ui/core';

import { RideType } from '../../lib/types';

// styles
import './styles.scss';

interface Props {
    onChange: (value: RideType) => void;
    value: RideType;
}

const RideButtonGroup: React.FC<Props> = ({ value, onChange }) => {
    return (
        <ToggleButtonGroup
            exclusive
            onChange={({}, value) => onChange(value)}
            aria-label="text alignment"
            className="ride-button-group"
        >
            {/* TODO: move icon fill options to CSS rules .module-ride > .zmdi-info */}
            {/* TODO: add info popup to info icon */}
            <ToggleButton
                value={RideType.ASSIST}
                aria-label="left aligned"
                className={`ride-button-group__button ${
                    RideType.ASSIST === value ? 'ride-button-group__button--active' : ''
                }`}
            >
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <h5>{RideType.ASSIST}</h5>
                        <p>Special assistance</p>
                    </Grid>
                </Grid>
            </ToggleButton>
            <ToggleButton
                value={RideType.UBERX}
                aria-label="centered"
                className={`ride-button-group__button ${
                    RideType.UBERX === value ? 'ride-button-group__button--active' : ''
                }`}
            >
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <h5>{RideType.UBERX}</h5>
                        <p>Fastest ride</p>
                    </Grid>
                </Grid>
            </ToggleButton>
            <ToggleButton
                value={RideType.POOL}
                aria-label="right aligned"
                className={`ride-button-group__button ${
                    RideType.POOL === value ? 'ride-button-group__button--active' : ''
                }`}
            >
                <Grid alignItems="center" container>
                    <Grid item xs={12}>
                        <h5>{RideType.POOL}</h5>
                        <p>Cheapest ride</p>
                    </Grid>
                </Grid>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default RideButtonGroup;
