import { combineReducers } from 'redux';
import user from './userReducer';
import groceries from './groceriesReducer';
import rides from './ridesReducer';
import medications from './medicationsReducer';
import doctorAppointments from './doctorAppointmentsReducer';
import caregiver from './caregiverReducer';
import payment from './paymentReducer';
import insurance from './insuranceReducer';

import { LOGOUT } from '../constants';

const rootReducer = combineReducers({
    caregiver,
    doctorAppointments,
    groceries,
    insurance,
    medications,
    payment,
    rides,
    user,
});

const logoutHandlingReducer: typeof rootReducer = (state, action) => {
    const { type } = action;
    switch (type) {
        case LOGOUT:
            state = undefined;
    }

    return rootReducer(state, action);
};

export default logoutHandlingReducer;
