import { IGroceriesApi, GroceriesApi, IGroceriesSubmitOrderParams } from './groceries_api';
import {
    IAddAddressParams,
    IChangePasswordParams,
    IGetGroceryListParams,
    IHideServices,
    IInvitedCaregiverInfo,
    ISetIsFirstVisitParams,
    IUpdateSelectedServiceParams,
    IUserApi,
    IUserInfo,
    IUserLoginParams,
    IUserLoginResult,
    IUserResetParams,
    UserApi,
} from './user_api';
import { IRidesApi, IRidesSubmitOrderParams, RidesApi } from './rides_api';
import { IMedicationsApi, IMedicationsSubmitOrderParams, MedicationsApi } from './medications_api';
import {
    IDoctorAppointmentsApi,
    DoctorAppointmentsApi,
    IDoctorAppointmentsSubmitOrderParams,
} from './doctor_appointments_api';
import { CaregiverApi, ICaregiverApi, ICaregiverSubmitOrderParams } from './caregiver_api';
import { IRequestsApi, RequestsApi } from './requests_api';
import { IPaymentApi, PaymentApi, IGetPaymentSourcesParams } from './payment_api';
import { InsuranceApi, IInsuranceApi, IGetInsuranceProvidersParams } from './insurance_api';

import { ApiBase } from './api_base';

export type {
    IAddAddressParams,
    ICaregiverApi,
    ICaregiverSubmitOrderParams,
    IChangePasswordParams,
    IDoctorAppointmentsApi,
    IDoctorAppointmentsSubmitOrderParams,
    IGetGroceryListParams,
    IGetInsuranceProvidersParams,
    IGetPaymentSourcesParams,
    IGroceriesApi,
    IGroceriesSubmitOrderParams,
    IHideServices,
    IInsuranceApi,
    IInvitedCaregiverInfo,
    IMedicationsApi,
    IMedicationsSubmitOrderParams,
    IPaymentApi,
    IRequestsApi,
    IRidesApi,
    IRidesSubmitOrderParams,
    ISetIsFirstVisitParams,
    IUpdateSelectedServiceParams,
    IUserApi,
    IUserInfo,
    IUserLoginParams,
    IUserLoginResult,
    IUserResetParams,
};

export const createApis = (baseURL: string, getAccesToken: () => string | undefined) => {
    const apiBase = new ApiBase(baseURL, getAccesToken);
    const groceriesApi: IGroceriesApi = new GroceriesApi(apiBase);
    const userApi: IUserApi = new UserApi(apiBase);
    const ridesApi: IRidesApi = new RidesApi(apiBase);
    const medicationsApi: IMedicationsApi = new MedicationsApi(apiBase);
    const doctorAppointmentsApi: IDoctorAppointmentsApi = new DoctorAppointmentsApi(apiBase);
    const caregiverApi: ICaregiverApi = new CaregiverApi(apiBase);
    const requestsApi: IRequestsApi = new RequestsApi(apiBase);
    const paymentApi: IPaymentApi = new PaymentApi(apiBase);
    const insuranceApi: IInsuranceApi = new InsuranceApi(apiBase);

    return {
        caregiverApi,
        doctorAppointmentsApi,
        groceriesApi,
        insuranceApi,
        medicationsApi,
        paymentApi,
        requestsApi,
        ridesApi,
        userApi,
    };
};
