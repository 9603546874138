import React, { useState } from 'react';

import { ConnectedProps, connect } from 'react-redux';

import { Avatar, Box, Grid } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon, RadioButtonUnchecked as RadioButtonUncheckedIcon } from '@material-ui/icons';

import moment from 'moment';

import {
    IDoctorAppointmentOrder,
    IGroceryCart,
    IOrderHistoryItem,
    IOverTheCounterMedicationCart,
    IOverTheCounterMedicationItem,
    IPrescriptionMedicationCart,
    IPrescriptionMedicationItem,
    OrderStatusString,
    EXISTING_DOCTOR_APPOINTMENT_REQUEST,
    NEW_DOCTOR_APPOINTMENT_REQUEST,
    OVER_THE_COUNTER_MEDICATION,
    PRESCRIPTION_MEDICATION,
} from '../../../lib/types';

import { reorderCaregiverCartAction } from '../../../state/actions/caregiverActions';
import { reorderGroceryCartAction } from '../../../state/actions/groceriesActions';
import { reorderMedicationCartAction } from '../../../state/actions/medicationsActions';
import { reorderRideCartAction } from '../../../state/actions/ridesActions';
import {
    reorderNewDoctorAppointmentCartAction,
    reorderExistingDoctorAppointmentCartAction,
    setDoctorAppointmentIsExistingDoctorAppointmentAction,
} from '../../../state/actions/doctorAppointmentsActions';
import { DispatchType } from '../../../state';

import BaseButton from '../../../components/BaseButton/BaseButton';
import Card from '../../../components/Card/Card';
import CaregiverVisitCartList from '../../../components/CaregiverVisitCartList';
import DoctorAppointmentCartList from '../../../components/DoctorAppointmentCartList';
import GroceryCartList from '../../../components/GroceryCartList';
import MedicationCartList from '../../../components/MedicationCartList';
import RideCartList from '../../../components/RideCartList';

import OrderInfoModal from './OrderInfoModal';

// styles
import './styles.scss';

type orderTypes = { [index: string]: { type: string; text: string } };

const ORDER_TYPES: orderTypes = {
    groceries: {
        type: 'groceries',
        text: 'Groceries delivery',
    },
    ride: {
        type: 'ride',
        text: 'Ride',
    },
    medication: {
        type: 'medication',
        text: 'Medication',
    },
    doctor: {
        type: 'doctor',
        text: 'Doctor appointment',
    },
    caregiver: {
        type: 'caregiver',
        text: 'Caregiver visit',
    },
};

const mapDispatch = (dispatch: DispatchType) => ({
    reorderCaregiverCart: reorderCaregiverCartAction(dispatch),
    reorderExistingDoctorAppointmentCart: reorderExistingDoctorAppointmentCartAction(dispatch),
    reorderGroceryCart: reorderGroceryCartAction(dispatch),
    reorderMedicationCart: reorderMedicationCartAction(dispatch),
    reorderNewDoctorAppointmentCart: reorderNewDoctorAppointmentCartAction(dispatch),
    reorderRideCart: reorderRideCartAction(dispatch),
    setDoctorAppointmentIsExistingDoctorAppointment: setDoctorAppointmentIsExistingDoctorAppointmentAction(dispatch),
});

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    orderData: IOrderHistoryItem;
    goToDashboard: () => void;
}

const orderStatusToIcon = (orderStatus: OrderStatusString) => {
    switch (orderStatus) {
        case 'fulfilled':
            return <CheckCircleIcon />;
        case 'created':
            return <RadioButtonUncheckedIcon />;
    }
    return null;
};

const OrderCard: React.FC<Props & PropsFromRedux> = ({
    goToDashboard,
    orderData,
    reorderCaregiverCart,
    reorderExistingDoctorAppointmentCart,
    reorderGroceryCart,
    reorderMedicationCart,
    reorderNewDoctorAppointmentCart,
    reorderRideCart,
    setDoctorAppointmentIsExistingDoctorAppointment,
}) => {
    const { orderDate, orderStatus, orderType, order } = orderData;
    const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);

    type OrderConverter<A> = Extract<IOrderHistoryItem, { orderType: A }>;

    const { text } = ORDER_TYPES[orderType];

    let modalTitle = '';
    let orderAvatar: string | undefined = undefined;
    let orderDetailsComponent: React.ReactNode;
    let total: string | undefined = undefined;
    let resubmitOrder: () => void = () => undefined;
    try {
        switch (orderType) {
            case 'groceries': {
                const {
                    order: { items, sumAmount },
                } = orderData as OrderConverter<typeof orderType>;
                modalTitle = 'Grocery items';
                orderAvatar = 'img/orderhistory/orderhistory_pic01.jpg';
                orderDetailsComponent = <GroceryCartList items={items} />;
                total = sumAmount;
                resubmitOrder = () => {
                    const cart: IGroceryCart = {};
                    items.forEach((item) => {
                        const {
                            product: { id },
                        } = item;
                        cart[id] = item;
                    });
                    reorderGroceryCart(cart);
                };
                break;
            }
            case 'medication': {
                const {
                    order: { items },
                } = orderData as OrderConverter<typeof orderType>;
                modalTitle = 'Medication items';
                orderAvatar = 'img/orderhistory/orderhistory_pic03.jpg';
                orderDetailsComponent = <MedicationCartList items={items} />;
                resubmitOrder = () => {
                    const overTheCounterMedicationCart: IOverTheCounterMedicationCart = [];
                    const prescriptionMedicationCart: IPrescriptionMedicationCart = [];
                    type ItemConverter<A> = Extract<
                        IOverTheCounterMedicationItem | IPrescriptionMedicationItem,
                        { type: A }
                    >;
                    items.forEach((item) => {
                        const { type } = item;
                        switch (type) {
                            case PRESCRIPTION_MEDICATION: {
                                prescriptionMedicationCart.push(item as ItemConverter<typeof type>);
                                break;
                            }
                            case OVER_THE_COUNTER_MEDICATION: {
                                overTheCounterMedicationCart.push(item as ItemConverter<typeof type>);
                                break;
                            }
                        }
                    });
                    reorderMedicationCart({
                        overTheCounterMedicationCart,
                        prescriptionMedicationCart,
                    });
                };
                break;
            }
            case 'ride': {
                const { order } = orderData as OrderConverter<typeof orderType>;
                modalTitle = 'Ride details';
                orderAvatar = 'img/orderhistory/orderhistory_pic02.jpg';
                orderDetailsComponent = <RideCartList order={order} />;
                const { rideCart } = order;
                resubmitOrder = () => reorderRideCart(rideCart);
                break;
            }
            case 'caregiver': {
                const { order } = orderData as OrderConverter<typeof orderType>;
                modalTitle = 'Caregiver visit';
                orderAvatar = 'img/orderhistory/orderhistory_pic04.jpg';
                orderDetailsComponent = <CaregiverVisitCartList order={order} />;
                const { caregiverVisitCart } = order;
                resubmitOrder = () => reorderCaregiverCart(caregiverVisitCart);
                break;
            }
            case 'doctor': {
                const { order } = orderData as OrderConverter<typeof orderType>;
                type DoctorAppointmentOrderConverter<A> = Extract<IDoctorAppointmentOrder, { type: A }>;
                const { type } = order;
                switch (type) {
                    case EXISTING_DOCTOR_APPOINTMENT_REQUEST: {
                        const { cart } = order as DoctorAppointmentOrderConverter<typeof type>;
                        modalTitle = 'Existing doctor appointment';
                        resubmitOrder = () => {
                            reorderExistingDoctorAppointmentCart(cart);
                            setDoctorAppointmentIsExistingDoctorAppointment(true);
                        };
                        break;
                    }
                    case NEW_DOCTOR_APPOINTMENT_REQUEST: {
                        const { cart } = order as DoctorAppointmentOrderConverter<typeof type>;
                        modalTitle = 'New doctor appointment';
                        resubmitOrder = () => {
                            reorderNewDoctorAppointmentCart(cart);
                            setDoctorAppointmentIsExistingDoctorAppointment(false);
                        };
                        break;
                    }
                }
                orderAvatar = 'img/orderhistory/orderhistory_pic04.jpg';
                orderDetailsComponent = <DoctorAppointmentCartList order={order} />;
                break;
            }
        }
    } catch (e) {}

    return (
        <Grid item xs={12} className="order-card">
            <Card>
                <Grid container alignItems="center">
                    <Grid container item xs={12} sm={4} alignItems="center">
                        <Box className="order-card__info-col">
                            <Avatar variant="rounded" src={orderAvatar} style={{ width: 60, height: 60 }} />
                            <Box>
                                <p className="order-name">{text}</p>
                                <p className="order-date">{moment(orderDate).format('L LT')}</p>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box className="order-card__price-col">
                            <p>${total || 'n/a'}</p>
                        </Box>
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems="center"
                        xs={12}
                        sm={2}
                        className={`order-card__status-col order-card__status-col--${orderStatus}`}
                    >
                        {orderStatusToIcon(orderStatus)}
                        <span className="order-card__capitalize">{orderStatus}</span>
                    </Grid>
                    <Grid container item xs={12} sm={4} className="order-card__actions-col">
                        <Box>
                            <BaseButton disabled={!order} onClick={() => setOpenMoreInfoModal(true)} text="More info" />
                            <OrderInfoModal
                                onBackPressed={() => setOpenMoreInfoModal(false)}
                                onOrderAgainPressed={() => {
                                    resubmitOrder();
                                    goToDashboard();
                                }}
                                open={openMoreInfoModal}
                                title={modalTitle}
                                total={total}
                            >
                                {orderDetailsComponent}
                            </OrderInfoModal>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};

export default connector(OrderCard);
