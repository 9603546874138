import React from 'react';

import { IconButton } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

import classNames from 'classnames';

import './styles.scss';

interface Props {
    active: boolean;
    onClick: () => void;
}

const ActiveIconButton: React.FC<Props> = ({ active, onClick }) => (
    <IconButton
        className={classNames('active-icon-button__active-icon', {
            'active-icon-button__active-icon--active': active,
            'active-icon-button__active-icon--disabled': !active,
        })}
        disableFocusRipple={true}
        disableRipple={true}
        edge={false}
        onClick={onClick}
    >
        <CheckCircleIcon />
    </IconButton>
);

export default ActiveIconButton;
