import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Card from '../../Card/Card';

import ActiveIconButton from '../../../components/ActiveIconButton';

// styles
import './styles.scss';

type Props = {
    active?: boolean;
    bottomActions?: React.ReactNode;
    cardContent?: string;
    cardIcon?: string | React.ReactNode;
    cardTitle?: string;
    onActiveIconClicked?: () => void;
};

const ServiceCard = ({
    active,
    bottomActions,
    cardContent,
    cardIcon,
    cardTitle,
    onActiveIconClicked,
}: Props): JSX.Element => {
    return (
        <Card>
            <Grid container justify="space-between">
                {cardIcon && (
                    <Grid item className="service-card__icon-holder">
                        <figure>
                            {typeof cardIcon === 'string' ? <img src={cardIcon} alt="Home icon | CAN" /> : cardIcon}
                        </figure>
                    </Grid>
                )}
                {typeof active !== 'undefined' && onActiveIconClicked && (
                    <Grid item>
                        <ActiveIconButton active={active} onClick={onActiveIconClicked} />
                    </Grid>
                )}
            </Grid>
            <Box className="service-card__address-info">
                <p className="service-card__address-info--title">{cardTitle}</p>
                {cardContent && <p className="service-card__address-info--address">{cardContent}</p>}
            </Box>
            <Box className="service-card__address-actions">{bottomActions}</Box>
        </Card>
    );
};

export default ServiceCard;
