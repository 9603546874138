import React from 'react';

import Grid from '@material-ui/core/Grid';

import AccessTimeIcon from '@material-ui/icons/AccessTime';

import MomentUtils from '@date-io/moment';

import { MuiPickersUtilsProvider, TimePicker as MuiTimePicker } from '@material-ui/pickers';

import { Moment } from 'moment';

import './styles.scss';

interface Props {
    readonly onChange: (newTime?: Moment) => void;
    readonly placeholder?: string;
    readonly value?: Moment;
}

const TimePicker: React.FC<Props> = ({ placeholder, onChange, value }) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container>
            <Grid item xs={9}>
                <MuiTimePicker
                    clearable
                    margin="none"
                    id="time-picker"
                    value={value || null}
                    onChange={(date) => onChange(date || undefined)}
                    emptyLabel={placeholder}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    style={{ width: '100%' }}
                />
            </Grid>
            <Grid item container xs={3} justify="center" alignItems="center" className="TimePicker__icon">
                <AccessTimeIcon fontSize="small" />
            </Grid>
        </Grid>
    </MuiPickersUtilsProvider>
);

export default TimePicker;
