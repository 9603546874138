import React from 'react';
import { Grid, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import './AddYourselfAsEmergencyContact.scss';

interface Props {
    action: any;
    closeModal: any;
    closeParentModal?: any;
}

const AddYourselfAsEmergencyContact = (props: Props): JSX.Element => {
    const save = () => {
        return props.action().then(() => {
            props.closeModal();

            if (props.closeParentModal) {
                props.closeParentModal();
            }
        });
    };

    const exitWithoutSaving = () => {
        props.closeModal();

        if (props.closeParentModal) {
            props.closeParentModal();
        }
    };

    return (
        <div className="emergency-contact">
            <Grid container item direction="row" justify="space-between" alignItems="center">
                <Grid item className="emergency-contact__title">
                    Save Changes
                </Grid>
                <Grid item className="emergency-contact__close-icon" onClick={props.closeModal()}>
                    <CloseIcon />
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="column"
                justify="flex-start"
                alignItems="center"
                className="emergency-contact__main-container"
            >
                <Grid item>
                    <Grid item className="emergency-contact__main-text">
                        Add yourself as Emergency Contact
                    </Grid>
                    <Grid item className="emergency-contact__explanation-text gray-text">
                        <div>Are you sure you want the cane to call your number in the case of emergency?</div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item direction="row" justify="flex-end" alignItems="center" className="footer">
                <Grid item>
                    <Button fullWidth variant="outlined" onClick={exitWithoutSaving}>
                        No
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className={'emergency-contact__save-button'}
                        onClick={save}
                    >
                        Yes
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddYourselfAsEmergencyContact;
