/**
 * @interface MdSpeciality
 * @field id is a unique positive integer identifier
 * @field label is a human readable description
 */
export interface MdSpeciality {
    id: number;
    label: string;
}

const MD_SPECIALITIES: MdSpeciality[] = [
    { id: 1, label: 'Annual Physical' },
    { id: 2, label: 'Dermatology Consultation' },
    { id: 3, label: 'Annual Pap Smear / GYN Exam' },
    { id: 4, label: 'Dental Cleaning' },
    { id: 5, label: 'General Eye Consultation' },
    { id: 6, label: 'Acupuncturist' },
    { id: 7, label: 'Allergist (Immunologist)' },
    { id: 8, label: 'Audiologist' },
    { id: 9, label: 'Cardiologist (Heart Doctor)' },
    { id: 10, label: 'Cardiothoracic Surgeon' },
    { id: 11, label: 'Chiropractor' },
    { id: 12, label: 'Colorectal Surgeon' },
    { id: 13, label: 'Dentist' },
    { id: 14, label: 'Dermatologist' },
    { id: 15, label: 'Dietitian / Nutritionist' },
    {
        id: 16,
        label: 'Ear, Nose & Throat Doctor (ENT / Otolaryngologist)',
    },
    {
        id: 17,
        label: 'Endocrinologist (incl Diabetes Specialists)',
    },
    { id: 18, label: 'Eye Doctor' },
    { id: 19, label: 'Gastroenterologist' },
    { id: 20, label: 'Geriatrician' },
    { id: 21, label: 'Hearing Specialist' },
    { id: 22, label: 'Hematologist (Blood Specialist)' },
    { id: 23, label: 'Infectious Disease Specialist' },
    { id: 24, label: 'Infertility Specialist' },
    { id: 25, label: 'Midwife' },
    { id: 26, label: 'Naturopathic Doctor' },
    { id: 27, label: 'Nephrologist (Kidney Specialist)' },
    { id: 28, label: 'Neurologist (incl Headache Specialists)' },
    { id: 29, label: 'Neurosurgeon' },
    { id: 30, label: 'OB-GYN (Obstetrician-Gynecologist)' },
    { id: 31, label: 'Oncologist' },
    { id: 32, label: 'Ophthalmologist' },
    { id: 33, label: 'Optometrist' },
    { id: 34, label: 'Oral Surgeon' },
    { id: 35, label: 'Orthodontist' },
    { id: 36, label: 'Orthopedic Surgeon (Orthopedist)' },
    { id: 37, label: 'Pain Management Specialist' },
    { id: 38, label: 'Pediatric Dentist' },
    { id: 39, label: 'Pediatrician' },
    { id: 40, label: 'Physiatrist (Physical Medicine)' },
    { id: 41, label: 'Physical Therapist' },
    { id: 42, label: 'Plastic Surgeon' },
    { id: 43, label: 'Podiatrist (Foot and Ankle Specialist)' },
    { id: 44, label: 'Primary Care Physician (PCP)' },
    { id: 45, label: 'Prosthodontist' },
    { id: 46, label: 'Psychiatrist' },
    { id: 47, label: 'Psychologist' },
    { id: 48, label: 'Pulmonologist (Lung Doctor)' },
    { id: 49, label: 'Radiologist' },
    { id: 50, label: 'Rheumatologist' },
    { id: 51, label: 'Sleep Medicine Specialist' },
    { id: 52, label: 'Sports Medicine Specialist' },
    { id: 53, label: 'Surgeon' },
    { id: 54, label: 'Therapist / Counselor' },
    { id: 55, label: 'Urgent Care Specialist' },
    { id: 56, label: 'Urological Surgeon' },
    { id: 57, label: 'Urologist' },
    { id: 58, label: 'Vascular Surgeon' },
];

export default MD_SPECIALITIES;
