import React from 'react';
import RDatePicker from 'react-datepicker';
import moment from 'moment';
import { FormControl, TextField } from '@material-ui/core';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';

import './DatePicker.scss';

interface Props {
    error?: boolean;
    helperText?: string;
    onBlur?: StandardInputProps['onBlur'];
    onChange: (date: Date | null) => void;
    placeholder: string;
    value?: string;
}

const DatePicker: React.FC<Props> = ({ error, helperText, onBlur, onChange, placeholder, value }) => {
    const DatePickerCustomInput = ({ value, onClick }: any) => (
        <FormControl fullWidth required>
            <TextField
                value={value && moment(value).format('YYYY/MM/DD')}
                error={error}
                helperText={helperText}
                autoComplete="none"
                onClick={onClick}
                placeholder={placeholder}
                onBlur={onBlur}
            />
        </FormControl>
    );

    return (
        <RDatePicker
            className="user-info-part-input"
            wrapperClassName="form-width"
            isClearable
            placeholderText="Date of birth"
            customInput={<DatePickerCustomInput />}
            selected={value ? new Date(value) : null}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={30}
            maxDate={new Date(moment(new Date()).add(-18, 'y').format('YYYY-MM-DD'))}
            onChange={onChange}
        />
    );
};

export default DatePicker;
