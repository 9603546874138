import React from 'react';

import Grid from '@material-ui/core/Grid';

import './AddedCount.scss';

interface Props {
    readonly count: number;
}

export const AddedCount: React.FC<Props> = ({ count }) => {
    return (
        <Grid container justify="space-between" className="AddedCount__container">
            {count > 0 && (
                <>
                    <Grid
                        container
                        item
                        xs={3}
                        justify="center"
                        alignContent="center"
                        className="AddedCount__productCount"
                    >
                        {count}
                    </Grid>
                    <Grid item justify="center" alignContent="center" className="AddedCount__btnAdded">
                        Added
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default AddedCount;
