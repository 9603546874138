import React, { useEffect, useState, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { History } from 'history';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { PaymentMethod } from '@stripe/stripe-js';
import queryString from 'query-string';
import ReactPixel from 'react-facebook-pixel';

import {
    FormControl,
    IconButton,
    Button,
    Link,
    CssBaseline,
    Dialog,
    FormControlLabel,
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    ListSubheader,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    FormHelperText,
    Tooltip,
    Checkbox,
} from '@material-ui/core';

// Icons
import {
    StarBorder as StarBorderIcon,
    AddCircleOutline as AddCircleOutlineIcon,
    LocationOnOutlined as LocationOnOutlinedIcon,
    HomeOutlined as HomeOutlinedIcon,
    Info as InfoIcon,
    ArrowBackIos as ArrowBackIosIcon,
    FavoriteBorder as FavoriteBorderIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons';

import { checkProperties } from '../../../components/helpers';
import AddressAutoFill from '../../../components/AddressAutoFill/AddressAutoFill';
import AddYourselfAsEmergencyContact from '../../../components/AddYourselfAsEmergencyContact/AddYourselfAsEmergencyContact';
import CreditCardUi from '../../../components/CreditCardUi/CreditCardUi';
import DatePicker from '../../../components/DatePicker';
import INSURANCE_PROVIDERS from '../../../components/InsuranceProvidersDropdown/insurance_providers';
import StripeCardForm, { OnSuccessParams } from '../../../components/StripeCardForm/StripeCardForm';
import PrescriptionInfoStep from '../PrescriptionInfoStep';
import CaregiverSignUp from '../CaregiverSignUp/CaregiverSignUp';
import CheckPin from '../CheckPin/CheckPin';
import FinalMessage from '../FinalMessage/FinalMessage';
import PanelWithImage from '../PanelWithImage/PanelWithImage';

import { addressValidationHelper, validationHelper, checkForErrorMessage } from '../../../input-validation.service';
import inputValidation from '../../../input-validation.service';
import { ApisContext } from '../../../lib/contexts';
import {
    AddressAutoFillCallback,
    HomeAddressFields,
    HomeAddressParams,
    IInsuranceProviderParams,
    IPaymentSourceParams,
    IPrescriptionInfo,
} from '../../../lib/types';
import { DispatchType, RootState } from '../../../state';
import { signUpAction, loginAction } from '../../../state/actions/userActions';

import PhoneInput from './PhoneInput';

import groceriesData from '../groceriesData';
import 'react-phone-number-input/style.css';
import './SignUp.scss';

let stripePromise: Promise<Stripe | null>;

if (process.env.REACT_APP_STRIPE_API_KEY) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
} else {
    console.log('Please set up a stripe api key');
}

const fieldvalidation = false;

const mapStateToProps = ({ user: { signUpState, signUpErrorMessage, loginErrorMessage } }: RootState) => ({
    signUpState,
    signUpErrorMessage,
    loginErrorMessage,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
    login: loginAction(dispatch),
    signUp: signUpAction(dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type InputChangeEvent = React.ChangeEvent<HTMLInputElement>;

// interfaces
interface Props {
    history: History;
}

interface ISteps {
    user: string[];
    caregiver: string[];
}

interface IStepsObject {
    step1: any;
    step2: any;
    step3: any;
    step4: any;
    step5: any;
}

export interface ISignUpCallbackParams {
    addedCard?: {
        stripePaymentMethod: PaymentMethod;
        cardHolderName: string;
    };
    addressList: Omit<HomeAddressFields, 'id'>[];
    email: string;
    emergencyContactEmail?: string;
    emergencyContactFirstName?: string;
    emergencyContactLastName?: string;
    emergencyContactPhoneNumber?: string;
    firstName: string;
    gorceryList?: [];
    lastName: string;
    password: string;
    phonenumber: string;
    pin?: string;
    prescriptionInfo?: IPrescriptionInfo;
    rxInsurance?: IInsuranceProviderParams;
    userInfoAge?: string;
    userInfoAllergies?: string;
    userInfoDOB?: string;
    userInfoGender?: string;
    userInfoHeight?: string;
    userInfoMedicalConditions?: string;
    userInfoWeight?: string;
    usingSystemFor?: 'self' | 'parent' | 'grandparent' | 'other';
}

interface IState extends ISignUpCallbackParams {
    confirmEmail?: string;
    confirmPassword?: string;
    HomeUser: boolean; // TODO: make this a selection depending on whether there is a PIN or not
    errorMessage?: string;
    showAddYourselfAsEmergencyContact: boolean;
    step: number;
    signUpType: 'user' | 'caregiver';
    formIsValid: boolean;
    termsAndConditions: boolean;
    errorMessages: {
        step1: {
            firstName?: string;
            lastName?: string;
            phonenumber?: string;
            email?: string;
            confirmEmail?: string;
            password?: string;
            confirmPassword?: string;
        };
        step2: {
            emergencyContactFirstName?: string;
            emergencyContactLastName?: string;
            emergencyContactPhoneNumber?: string;
            emergencyContactEmail?: string;
        };
        step3: HomeAddressParams[];

        step4: {};
        step5: {
            rxSubscriber?: string;
            rxMemberID?: string;
            rxGroupNo?: string;
            rxBin?: string;
            rxGroup?: string;
            rxPCN?: string;
            rxRelationshipToSubscriber?: string;
        };
        step6: {};
        step7: {
            userInfoMedicalConditions?: string;
            userInfoAllergies?: string;
            termsAndConditions?: string;
            userInfoDOB?: string;
        };
    };
}

const SignUp: React.FC<Props & PropsFromRedux> = (props) => {
    const { userApi } = useContext(ApisContext);
    const { signUpErrorMessage, loginErrorMessage } = props;

    const location = useLocation();

    const steps: ISteps = {
        user: [
            'mainInfo', // First Name, Last name, email, password, Using it for:
            'emergencyContact', // First Name,Last Name,Phone
            'addressesInfo', // Home Address,State,Zip code,Nickname
            // 'groceriesInfo',                // Choose from list
            'paymentInfo', // Name, cardno, Exp, cvv, zipcode
            'insuranceInfo', // Subscriber, Member ID, Group no, Rx Bin, Rx group
            'prescriptionInfo', //
            'additionalInfo', //
            // 'congratulation', //
            // 'pin'
        ],
        caregiver: ['mainInfo', 'congratulation'],
    };

    const urlParams = queryString.parse(location.search);
    let signUpType: 'user' | 'caregiver' = 'user';
    let invitationToken: any = null;
    let caregiverFirstName: any = null;
    let caregiverLastName: any = null;
    let caregiverPhoneNumber: any = null;
    let caregiverEmail: any = null;

    if (urlParams.type === 'caregiver') {
        signUpType = 'caregiver';
        invitationToken = urlParams.token;
        caregiverFirstName = urlParams.firstName;
        caregiverLastName = urlParams.lastName;
        caregiverPhoneNumber = urlParams.phoneNumber;
        caregiverEmail = urlParams.email;
    }

    const [state, setState] = useState<IState>({
        addressList: [
            {
                address: '',
            },
        ],
        email: caregiverEmail || '',
        errorMessages: {
            step1: {},
            step2: {},
            step3: [{}],
            step4: {},
            step5: {},
            step6: {},
            step7: {},
        },
        firstName: caregiverFirstName || '',
        formIsValid: false,
        gorceryList: [],
        HomeUser: true, // TODO: make this a selection depending on whether there is a PIN or not
        lastName: caregiverLastName || '',
        password: '',
        phonenumber: caregiverPhoneNumber || '',
        showAddYourselfAsEmergencyContact: false,
        signUpType,
        step: 0,
        termsAndConditions: false,
        usingSystemFor: 'self',
    });

    useEffect(
        () =>
            signUpErrorMessage
                ? setState({
                      ...state,
                      errorMessage: signUpErrorMessage,
                  })
                : undefined,
        [signUpErrorMessage]
    );

    useEffect(
        () =>
            loginErrorMessage
                ? setState({
                      ...state,
                      errorMessage: loginErrorMessage,
                  })
                : undefined,
        [loginErrorMessage]
    );

    // datepicker custom input

    const validateForm = (step: keyof IStepsObject) => {
        if (step === 'step3') {
            let valid = false;
            // loop over addresses and validate each one
            state.errorMessages?.step3?.map((item, index) => {
                if (!checkForErrorMessage(item)) {
                    valid = false;
                } else {
                    valid = true;
                }
            });

            // set the form status
            setState((prevState) => {
                return { ...prevState, formIsValid: valid };
            });
        } else {
            if (!checkForErrorMessage(state.errorMessages[step])) {
                setState((prevState) => {
                    return { ...prevState, formIsValid: false };
                });
            } else {
                setState((prevState) => {
                    return { ...prevState, formIsValid: true };
                });
            }
        }
    };

    const handleBlur = (event: any, field: string, index?: number) => {
        const inputValue = event.target.value;
        const inputField = field;
        const currentStep: keyof IStepsObject = `step${state.step + 1}` as any;

        if (
            ['address', 'addressZipCode', 'addressState', 'addressNickname'].includes(event.target?.dataset.inputname)
        ) {
            // trigger input validation
            addressValidationHelper(inputField, inputValue, state, setState, currentStep, index);
        } else {
            // trigger input validation check for checkbox and normal input
            if (event.target.checked) {
                validationHelper(inputField, event.target.checked.toString(), state, setState, currentStep);
            } else {
                validationHelper(inputField, inputValue, state, setState, currentStep);
            }
        }

        // trigger form validation
        validateForm(currentStep);
    };

    const addNewAddress = () => {
        const currentStep: keyof IStepsObject = `step${state.step + 1}` as any;

        // pushing new address object to the array
        setState((prevState) => {
            const { addressList, errorMessages } = prevState;
            const { step3 } = errorMessages;
            return {
                ...prevState,
                addressList: [...addressList, { address: '' }],
                errorMessages: { ...errorMessages, step3: [...step3, { address: '' }] },
            };
        });

        // trigger form validation
        validateForm(currentStep);
    };

    const handleInputChange = (
        event: InputChangeEvent,
        field:
            | 'address'
            | 'addressNickname'
            | 'addressState'
            | 'addressZipCode'
            | 'confirmEmail'
            | 'confirmPassword'
            | 'email'
            | 'emergencyContactEmail'
            | 'emergencyContactFirstName'
            | 'emergencyContactLastName'
            | 'firstName'
            | 'lastName'
            | 'password'
            | 'rxBin'
            | 'rxGroup'
            | 'rxGroupNo'
            | 'rxMemberID'
            | 'rxPCN'
            | 'rxRelationshipToSubscriber'
            | 'rxSubscriber'
            | 'secondAddressLine'
            | 'termsAndConditions'
            | 'userInfoAllergies'
            | 'userInfoMedicalConditions',
        index?: number
    ) => {
        event.persist();
        const {
            target: {
                value,
                type,
                checked,
                dataset: { id },
            },
        } = event;
        switch (field) {
            case 'address':
            case 'addressNickname':
            case 'addressState':
            case 'addressZipCode':
            case 'secondAddressLine': {
                if (id) {
                    const inputId = Number.parseInt(id);
                    const addressList = [...state.addressList];

                    addressList[inputId][field] = value;

                    setState({
                        ...state,
                        addressList,
                    });
                }
                break;
            }
            case 'rxSubscriber':
            case 'rxMemberID':
            case 'rxGroupNo':
            case 'rxBin':
            case 'rxGroup':
            case 'rxPCN':
            case 'rxRelationshipToSubscriber': {
                const { rxInsurance } = state;
                setState({
                    ...state,
                    rxInsurance: {
                        ...(rxInsurance || {}),
                        [field]: value,
                    },
                });
                break;
            }
            default: {
                setState({
                    ...state,
                    [field]: type == 'checkbox' ? checked : value,
                });
            }
        }
        // validating
        handleBlur(event, field, index);
    };

    // callback for getting payment method id and update state with it
    const updatePaymentId = ({ cardHolderName, stripePaymentMethod }: OnSuccessParams) => {
        setState({
            ...state,
            formIsValid: true,
            addedCard: { cardHolderName, stripePaymentMethod },
        });
    };

    const validateDate = (date: string | null | Date) => {
        const currentStep: keyof IStepsObject = `step${state.step + 1}` as any;
        const parsedDate = date && new Date(date).toISOString();

        if (parsedDate === null) {
            setState({ ...state, userInfoDOB: undefined });
        } else {
            setState({ ...state, userInfoDOB: parsedDate });
        }

        // validate birth date field
        validationHelper('userInfoDOB', parsedDate, state, setState, currentStep);

        // trigger form validation
        validateForm(currentStep);
    };

    const removeAddedCard = () =>
        setState((prevState: any) => {
            return { ...prevState, addedCard: undefined };
        });

    const { addressList, rxInsurance } = state;

    const autofillAddress = (index: number): AddressAutoFillCallback => (addressObject) => {
        const currentStep: keyof IStepsObject = `step${state.step + 1}` as any;
        const newAddressList = addressList ? [...addressList] : [];
        if (addressObject) {
            newAddressList[index] = addressObject;
        } else {
            const { addressNickname } = addressList[index];
            newAddressList[index] = { address: '', addressNickname };
        }

        setState((prevState) => {
            return {
                ...prevState,
                addressList: newAddressList,
            };
        });

        for (const [key, value] of Object.entries(newAddressList[index])) {
            // trigger input validation for each address field
            addressValidationHelper(key, value, state, setState, currentStep, index);
        }
    };

    const removeAddress = (index: number) => {
        const currentStep: keyof IStepsObject = `step${state.step + 1}` as any;
        let errorMessages = state.errorMessages;
        let addresses = state.addressList;

        if (index) {
            // removing elements from the array by their index (errorMessages and addresses)
            errorMessages?.step3?.splice(index, 1);
            addresses?.splice(index, 1);

            // updating state
            setState((prevState) => {
                return {
                    ...prevState,
                    addressList: addresses,
                    errorMessages: errorMessages,
                };
            });

            // trigger form validation
            validateForm(currentStep);
        }
    };

    const signUpCallback = async ({
        addedCard,
        addressList,
        email,
        emergencyContactEmail,
        emergencyContactFirstName,
        emergencyContactLastName,
        emergencyContactPhoneNumber,
        firstName,
        gorceryList,
        lastName,
        password,
        phonenumber,
        pin,
        prescriptionInfo,
        rxInsurance,
        userInfoAge,
        userInfoAllergies,
        userInfoDOB,
        userInfoGender,
        userInfoHeight,
        userInfoMedicalConditions,
        userInfoWeight,
        usingSystemFor,
    }: ISignUpCallbackParams) => {
        setState({ ...state, errorMessage: '' });

        let Payment: IPaymentSourceParams | undefined = undefined;
        if (addedCard) {
            const { cardHolderName, stripePaymentMethod } = addedCard;
            const { id: paymentMethodId } = stripePaymentMethod;
            Payment = {
                cardHolderName,
                stripePaymentMethod,
                paymentMethodId,
            };
        }

        const requestObject = {
            username: phonenumber,
            email,
            password,
            devicePIN: pin,
            invitationToken,
            HomeUser: true, // TODO: make this a selection depending on whether there is a PIN or not
            emergencyContact: emergencyContactPhoneNumber,
            invitedCaregiverInfo: {
                email: emergencyContactEmail,
                firstName: emergencyContactFirstName,
                lastName: emergencyContactLastName,
                phoneNumber: emergencyContactPhoneNumber,
            },
            userInfo: {
                firstName,
                lastName,
                gender: userInfoGender,
                weight: userInfoWeight,
                height: userInfoHeight,
                age: userInfoAge,

                // new
                DOB: userInfoDOB,
                medicalConditions: userInfoMedicalConditions,
                allergies: userInfoAllergies,
                usingSystemFor: usingSystemFor,
                addressList,
                gorceryList,
                Payment,
                rxInsurance,
                prescriptionInfo,
            },
        };

        // TODO: Show a loading page

        const { signUp, login, history } = props;

        if (await signUp(userApi, requestObject)) {
            if (urlParams.type !== 'caregiver') {
                const { email, password } = state;
                if (
                    await login(userApi, {
                        email,
                        password,
                    })
                ) {
                    history.push('/');
                    ReactPixel.track('CompleteRegistration', {});
                }
            }
        }
    };

    function checkPin() {
        return fetch(`baseURL/api/users/checkPinOrInvitationToken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: state.pin,
            }),
        })
            .then((res) => {
                if (res.status !== 200) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            errorMessage: 'Incorect PIN or Invitation Token',
                        };
                    });
                } else {
                    goToNextStep();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function goToEmergencyContactForm() {
        // TODO: if phone number is not required here or anywhere else
        // TODO: activate field validation fieldvalidation = true
        if (fieldvalidation) {
            goToNextStep();
        } else {
            if (!state.email || !state.password) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        errorMessage: 'Please, fill all required fields',
                    };
                });
            } else if (state.password !== state.confirmPassword) {
                return setState((prevState) => {
                    return {
                        ...prevState,
                        errorMessage: 'Confirm Password should be equal to Password',
                    };
                });
            } else if (state.email !== state.confirmEmail) {
                return setState((prevState) => {
                    return {
                        ...prevState,
                        errorMessage: 'Confirm Email should be equal to Email Address',
                    };
                });
            } else if (!inputValidation.email(state.email)) {
                return setState((prevState) => {
                    return { ...prevState, errorMessage: 'Invalid Email' };
                });
            } else {
                goToNextStep();
            }
        }
    }

    function goToNextStep() {
        // check for card completion on step 3
        const formIsValid: boolean = state.step === 2 ? (state?.addedCard ? true : false) : false;

        setState((prevState) => {
            // go one step forvard if this step is not last
            const isSelf = state.usingSystemFor === 'self';
            const jump = isSelf && prevState.step === 0 ? 2 : 1;

            const step =
                prevState.step === steps[state.signUpType].length - 1 ? prevState.step : (prevState.step += jump);
            return { ...prevState, errorMessage: '', step, formIsValid: formIsValid };
        });
    }

    const skipCurrentStep = () => {
        const { step } = state;
        switch (step) {
            case 3: {
                // Payment info
                setState({
                    ...state,
                    addedCard: undefined,
                    step: 4,
                });
                break;
            }
            case 4: {
                // Insurance info
                setState({
                    ...state,
                    rxInsurance: undefined,
                    step: 5,
                });
                break;
            }
            case 5: {
                // Insurance info
                setState({
                    ...state,
                    step: 6,
                });
                break;
            }
        }
    };

    function goToPreviousStep() {
        // check for card completion on step 3
        const formIsValid: boolean = state.step === 4 ? (state?.addedCard ? true : false) : false;

        setState(({ step: prevStep, ...rest }) => {
            const { usingSystemFor } = rest;
            let step = prevStep === 0 ? prevStep : (prevStep -= 1);
            if (usingSystemFor === 'self' && step === 1) {
                step = 0;
            }
            return { ...rest, errorMessage: '', step, formIsValid };
        });
    }

    const uploadInsuranceCard = (side: 'backOfCard' | 'frontOfCard') => async ({
        target: { files },
    }: {
        target: {
            files: FileList | null;
        };
    }) => {
        if (files) {
            const image = files[0];
            if (image) {
                const { getURL } = await userApi.uploadImage({
                    image,
                });
                const { rxInsurance } = state;
                setState({
                    ...state,
                    rxInsurance: {
                        ...rxInsurance,
                        [side]: getURL,
                    },
                });
            }
        }
    };

    // facebook pixel
    if (state.step === 0 && process.env.REACT_APP_FACEBOOKPIXEL) {
        ReactPixel.track('ViewContent', {
            content_name: 'signup',
            content_type: 'page',
        });
    }

    return (
        <Grid container className="root sign-up">
            <CssBaseline />

            {/* hero image on the left panel */}
            <PanelWithImage />

            <Grid item className="right-panel-container signup-right" xs={12} md={6}>
                <div className="right-panel">
                    {/* Show go Back button if step is more than 0 */}
                    {state.step > 0 && (
                        <Grid item xs={12}>
                            <Link className="sign-up__go-back-btn" variant="inherit" onClick={goToPreviousStep}>
                                <ArrowBackIosIcon fontSize="inherit" />
                                Back
                            </Link>
                        </Grid>
                    )}

                    {/*TODO: make the headder dynamic reading fro  an array of Titles for each step*/}
                    {/* Device pin form */}
                    {steps[state.signUpType][state.step] === 'pin' && state.signUpType === 'user' && (
                        <div>
                            <CheckPin
                                checkPin={checkPin}
                                pin={state.pin}
                                onChange={(event: InputChangeEvent) => {
                                    const value = event.target.value;
                                    setState((prevState) => {
                                        return { ...prevState, pin: value };
                                    });
                                }}
                                errorMessage={state.errorMessage}
                            />
                        </div>
                    )}
                    {/* addressesInfo form */}
                    {steps[state.signUpType][state.step] === 'addressesInfo' && state.signUpType === 'user' && (
                        <form
                            className="form"
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                goToNextStep();
                            }}
                        >
                            <Typography className="main-text" component="h1" variant="inherit" align="left">
                                Address
                                <LocationOnOutlinedIcon className="sign-up__home-icon" />
                            </Typography>
                            <Typography
                                component="p"
                                variant="inherit"
                                gutterBottom
                                align="left"
                                className="section-subtitle"
                            >
                                Let us know where to coordinate your deliveries and rides
                            </Typography>

                            <Grid container spacing={3}>
                                {state.addressList.map((item, index) => {
                                    const addressId = `address-${index}`,
                                        stateId = `state-${index}`,
                                        zipCodeId = `zipCode-${index}`,
                                        nicknameId = `nickname-${index}`,
                                        secondAddressLineId = `secondAddressLineId-${index}`;

                                    return (
                                        <Grid
                                            item
                                            container
                                            key={addressId}
                                            xs={12}
                                            alignItems="center"
                                            className="sign-up__new-address-holder"
                                        >
                                            <Grid container item xs={11} spacing={3}>
                                                <Grid item xs={12}>
                                                    <AddressAutoFill
                                                        value={
                                                            addressList.length > index ? addressList[index] : undefined
                                                        }
                                                        placeholder="Street address"
                                                        parentCallback={autofillAddress(index)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="secondAddressLine"
                                                        fullWidth
                                                        required
                                                        id={secondAddressLineId}
                                                        error={!!state.errorMessages.step3[index].secondAddressLine}
                                                        helperText={state.errorMessages.step3[index].secondAddressLine}
                                                        placeholder="Apartment, suite, unit, building, floor, etc."
                                                        InputProps={{
                                                            className: 'input',
                                                        }}
                                                        inputProps={{
                                                            'data-id': index,
                                                            'data-inputname': 'secondAddressLine',
                                                        }}
                                                        value={state.addressList[index].secondAddressLine || ''}
                                                        onChange={(event: InputChangeEvent) =>
                                                            handleInputChange(event, 'secondAddressLine', index)
                                                        }
                                                        onBlur={(event) =>
                                                            handleBlur(event, 'secondAddressLine', index)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="addressState"
                                                        fullWidth
                                                        required
                                                        id={stateId}
                                                        error={!!state.errorMessages.step3[index].addressState}
                                                        helperText={state.errorMessages.step3[index].addressState}
                                                        placeholder="State"
                                                        InputProps={{
                                                            className: 'input',
                                                        }}
                                                        inputProps={{
                                                            'data-id': index,
                                                            'data-inputname': 'addressState',
                                                        }}
                                                        value={state.addressList[index].addressState || ''}
                                                        onChange={(event: InputChangeEvent) =>
                                                            handleInputChange(event, 'addressState', index)
                                                        }
                                                        onBlur={(event) => handleBlur(event, 'addressState', index)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        placeholder="Zip code"
                                                        fullWidth
                                                        required
                                                        id={zipCodeId}
                                                        error={!!state.errorMessages.step3[index].addressZipCode}
                                                        helperText={state.errorMessages.step3[index].addressZipCode}
                                                        name="addressZipCode"
                                                        // autoComplete="zipcode"
                                                        InputProps={{
                                                            className: 'input',
                                                        }}
                                                        inputProps={{
                                                            'data-id': index,
                                                            'data-inputname': 'addressZipCode',
                                                        }}
                                                        value={state.addressList[index].addressZipCode || ''}
                                                        onChange={(event: InputChangeEvent) =>
                                                            handleInputChange(event, 'addressZipCode', index)
                                                        }
                                                        onBlur={(event) => handleBlur(event, 'addressZipCode', index)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id={nicknameId}
                                                        placeholder="Nickname"
                                                        name="addressNickname"
                                                        error={!!state.errorMessages.step3[index].addressNickname}
                                                        helperText={state.errorMessages.step3[index].addressNickname}
                                                        autoComplete="no"
                                                        InputProps={{
                                                            className: 'input',
                                                        }}
                                                        inputProps={{
                                                            'data-id': index,
                                                            'data-inputname': 'addressNickname',
                                                        }}
                                                        value={state.addressList[index].addressNickname}
                                                        onChange={(event: InputChangeEvent) =>
                                                            handleInputChange(event, 'addressNickname', index)
                                                        }
                                                        onBlur={(event) => handleBlur(event, 'addressNickname', index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {index > 0 && (
                                                <Grid xs={1}>
                                                    <Tooltip title="Remove" style={{ marginLeft: 20 }}>
                                                        <IconButton
                                                            aria-label="Remove"
                                                            onClick={() => removeAddress(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            <Typography className="error-message" component="h1" variant="subtitle1">
                                {state.errorMessage}
                            </Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <Button className="sign-up__add-more-btn" onClick={() => addNewAddress()}>
                                        Add other favorite address <AddCircleOutlineIcon style={{ marginLeft: 10 }} />
                                    </Button>
                                </Grid>
                                <Button
                                    disabled={!state.formIsValid}
                                    variant="contained"
                                    color="primary"
                                    className="submit-btn"
                                    onClick={() => {
                                        return goToNextStep();
                                    }}
                                >
                                    Next
                                </Button>
                                <Grid item></Grid>
                            </Grid>
                        </form>
                    )}

                    {/* groceriesInfo form */}
                    {/* TODO: dinamic list
                        TODO: selectable
                    */}
                    {steps[state.signUpType][state.step] === 'groceriesInfo' && state.signUpType === 'user' && (
                        <form
                            className="form"
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                goToNextStep();
                            }}
                        >
                            <Typography className="main-text" component="h1" variant="inherit" align="left">
                                Groceries
                            </Typography>
                            <Typography component="p" variant="inherit" gutterBottom className="section-subtitle">
                                Optional: Tell us what are your favorite grocery items. This will help us tailor CAN
                                Home for you.
                            </Typography>
                            <div className="sign-up__groceries-root">
                                <GridList cellHeight={180} className="sign-up__groceries-grid-list">
                                    <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                                        <ListSubheader component="div">Most used items</ListSubheader>
                                    </GridListTile>
                                    {groceriesData.map((tile) => (
                                        <GridListTile key={tile.img}>
                                            <img src={tile.img} alt={tile.title} />
                                            <GridListTileBar
                                                title={tile.title}
                                                subtitle={<span>by: {tile.author}</span>}
                                                actionIcon={
                                                    <Grid>
                                                        <IconButton
                                                            aria-label={`info about ${tile.title}`}
                                                            className="signup__groceries-grid-list__icon"
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                        <IconButton aria-label={`star ${tile.title}`}>
                                                            <StarBorderIcon />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            />
                                        </GridListTile>
                                    ))}
                                </GridList>
                            </div>
                            <Typography className="error-message" component="h1" variant="subtitle1">
                                {state.errorMessage}
                            </Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Button
                                    disabled={!state.formIsValid}
                                    variant="contained"
                                    color="primary"
                                    className="submit-btn"
                                    onClick={() => {
                                        return goToNextStep();
                                    }}
                                >
                                    Next
                                </Button>
                                <Grid item>
                                    <Link className="sign-up__go-back-btn" variant="inherit" onClick={skipCurrentStep}>
                                        Skip
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    )}

                    {/* paymentInfo form */}
                    {/*  TODO: add Stripe -- more at https://stripe.com/docs/payments/save-and-reuse */}
                    {steps[state.signUpType][state.step] === 'paymentInfo' && state.signUpType === 'user' && (
                        <Elements stripe={stripePromise}>
                            <Typography className="main-text" component="h1" variant="inherit" align="left">
                                Payment Info
                            </Typography>

                            {checkProperties(state.addedCard) ? (
                                <Typography
                                    component="p"
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="section-subtitle"
                                >
                                    Add card to fulfill your orders later. You won’t be charged for creating a CAN Home
                                    account :)
                                </Typography>
                            ) : (
                                <Typography
                                    component="p"
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="section-subtitle"
                                >
                                    Card successfully added as your payment method
                                </Typography>
                            )}

                            {checkProperties(state.addedCard) ? (
                                <Grid item spacing={3}>
                                    <StripeCardForm
                                        onSuccess={updatePaymentId}
                                        onError={(errorMessage) =>
                                            setState({
                                                ...state,
                                                errorMessage,
                                            })
                                        }
                                    />
                                    <Typography className="error-message" component="h1" variant="subtitle1">
                                        {state.errorMessage}
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid container item spacing={3} alignItems="center">
                                    {state.addedCard ? (
                                        <CreditCardUi
                                            cardHolderName={state.addedCard.cardHolderName}
                                            stripePaymentMethod={state.addedCard.stripePaymentMethod}
                                        />
                                    ) : null}
                                    <Tooltip title="Remove" style={{ marginLeft: 20 }}>
                                        <IconButton aria-label="Remove" onClick={removeAddedCard}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                {state.addedCard ? (
                                    <Button
                                        disabled={!state.formIsValid || !state.addedCard}
                                        variant="contained"
                                        color="primary"
                                        className="submit-btn"
                                        onClick={() => {
                                            return goToNextStep();
                                        }}
                                    >
                                        Next
                                    </Button>
                                ) : null}
                                {checkProperties(state.addedCard) && (
                                    <Grid item>
                                        <Link
                                            className="sign-up__go-back-btn"
                                            variant="inherit"
                                            onClick={skipCurrentStep}
                                        >
                                            Skip
                                        </Link>
                                    </Grid>
                                )}
                            </Grid>
                        </Elements>
                    )}

                    {/* insuranceInfo form */}
                    {steps[state.signUpType][state.step] === 'insuranceInfo' && state.signUpType === 'user' && (
                        <form
                            className="form"
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                goToNextStep();
                            }}
                        >
                            <Typography className="main-text" component="h1" variant="inherit" align="left">
                                Insurance info
                            </Typography>
                            <Typography
                                component="p"
                                variant="inherit"
                                gutterBottom
                                align="left"
                                className="section-subtitle"
                            >
                                Optional step if you would like to deliver medication
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid container item xs={12} justify="space-between">
                                    <Grid item xs={6}>
                                        <Button variant="contained" component="label" disableRipple={true}>
                                            Upload front of card
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={uploadInsuranceCard('frontOfCard')}
                                            />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" component="label" disableRipple={true}>
                                            Upload back of card
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={uploadInsuranceCard('backOfCard')}
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className="form input">
                                        <Select
                                            native
                                            fullWidth
                                            value={rxInsurance?.metaData?.id}
                                            onChange={({ target: { value } }) => {
                                                const metaData = INSURANCE_PROVIDERS.find(({ id }) => id === value);

                                                let { rxInsurance } = state;
                                                if (rxInsurance) {
                                                    rxInsurance = {
                                                        ...rxInsurance,
                                                        metaData,
                                                    };
                                                } else {
                                                    rxInsurance = {
                                                        metaData,
                                                    };
                                                }
                                                setState({
                                                    ...state,
                                                    rxInsurance,
                                                });
                                            }}
                                        >
                                            <option>Insurance provider</option>
                                            {INSURANCE_PROVIDERS.map(({ id, name }) => (
                                                <option key={id} value={id}>
                                                    {name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        autoFocus
                                        type="text"
                                        error={!!state.errorMessages.step5.rxSubscriber}
                                        helperText={state.errorMessages.step5.rxSubscriber}
                                        id="rxSubscriber"
                                        placeholder="Subscriber full name"
                                        name="rxSubscriber"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state?.rxInsurance?.rxSubscriber}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'rxSubscriber')}
                                        onBlur={(event) => handleBlur(event, 'rxSubscriber')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="rxMemberID"
                                        placeholder="Member ID number"
                                        name="rxMemberID"
                                        error={!!state.errorMessages.step5.rxMemberID}
                                        helperText={state.errorMessages.step5.rxMemberID}
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state?.rxInsurance?.rxMemberID}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'rxMemberID')}
                                        onBlur={(event) => handleBlur(event, 'rxMemberID')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="rxGroupNo"
                                        placeholder="Group nº"
                                        name="rxGroupNo"
                                        error={!!state.errorMessages.step5.rxGroupNo}
                                        helperText={state.errorMessages.step5.rxGroupNo}
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state?.rxInsurance?.rxGroupNo}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'rxGroupNo')}
                                        onBlur={(event) => handleBlur(event, 'rxGroupNo')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="rxBin"
                                        placeholder="Rx Bin"
                                        name="rxBin"
                                        error={!!state.errorMessages.step5.rxBin}
                                        helperText={state.errorMessages.step5.rxBin}
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state?.rxInsurance?.rxBin}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'rxBin')}
                                        onBlur={(event) => handleBlur(event, 'rxBin')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* TODO: fix/test autocomplete */}
                                    <TextField
                                        name="rxGroup"
                                        fullWidth
                                        required
                                        id="rxGroup"
                                        placeholder="rxGroup"
                                        error={!!state.errorMessages.step5.rxGroup}
                                        helperText={state.errorMessages.step5.rxGroup}
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state?.rxInsurance?.rxGroup}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'rxGroup')}
                                        onBlur={(event) => handleBlur(event, 'rxGroup')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="rxPCN"
                                        placeholder="Rx PCN"
                                        fullWidth
                                        required
                                        name="rxPCN"
                                        error={!!state.errorMessages.step5.rxPCN}
                                        helperText={state.errorMessages.step5.rxPCN}
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state?.rxInsurance?.rxPCN}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'rxPCN')}
                                        onBlur={(event) => handleBlur(event, 'rxPCN')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="rxRelationshipToSubscriber"
                                        placeholder="Relation to subscriber"
                                        name="rxRelationshipToSubscriber"
                                        error={!!state.errorMessages.step5.rxRelationshipToSubscriber}
                                        helperText={state.errorMessages.step5.rxRelationshipToSubscriber}
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state?.rxInsurance?.rxRelationshipToSubscriber}
                                        onChange={(event: InputChangeEvent) =>
                                            handleInputChange(event, 'rxRelationshipToSubscriber')
                                        }
                                        onBlur={(event) => handleBlur(event, 'rxRelationshipToSubscriber')}
                                    />
                                </Grid>
                            </Grid>
                            <Typography className="error-message" component="h1" variant="subtitle1">
                                {state.errorMessage}
                            </Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Button
                                    disabled={!state.formIsValid}
                                    variant="contained"
                                    color="primary"
                                    className="submit-btn"
                                    onClick={() => {
                                        return goToNextStep();
                                    }}
                                >
                                    Next
                                </Button>
                                <Grid item>
                                    <Link className="sign-up__go-back-btn" variant="inherit" onClick={skipCurrentStep}>
                                        Skip
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    )}

                    {steps[state.signUpType][state.step] === 'prescriptionInfo' && state.signUpType === 'user' && (
                        <PrescriptionInfoStep
                            onNextStepClicked={(prescriptionInfo) => {
                                setState({ ...state, prescriptionInfo });
                                goToNextStep();
                            }}
                            onSkipStep={skipCurrentStep}
                            value={state.prescriptionInfo}
                        />
                    )}
                    {/* Main sign up form*/}
                    {steps[state.signUpType][state.step] === 'mainInfo' && state.signUpType === 'user' && (
                        <form
                            className="form"
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                goToEmergencyContactForm();
                            }}
                        >
                            <Typography className="main-text" component="h1" variant="inherit" align="left">
                                Welcome home
                                <HomeOutlinedIcon className="sign-up__home-icon" />
                            </Typography>
                            <Typography component="p" variant="inherit" className="section-subtitle" align="left">
                                Simple steps and you will be ready in no time
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        id="firstName"
                                        placeholder="First name"
                                        error={!!state.errorMessages.step1.firstName}
                                        helperText={state.errorMessages.step1.firstName}
                                        autoFocus
                                        fullWidth
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.firstName}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'firstName')}
                                        onBlur={(event) => handleBlur(event, 'firstName')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="lastName"
                                        error={!!state.errorMessages.step1.lastName}
                                        helperText={state.errorMessages.step1.lastName}
                                        placeholder="Last name"
                                        name="lastName"
                                        autoComplete="family-name"
                                        fullWidth
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.lastName}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'lastName')}
                                        onBlur={(event) => handleBlur(event, 'lastName')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth required>
                                        <PhoneInput
                                            id="phone"
                                            value={state.phonenumber}
                                            errorMessage={state.errorMessages.step1.phonenumber}
                                            onChange={(value) => {
                                                const phonenumber = (value as unknown) as string;
                                                setState({ ...state, phonenumber });
                                            }}
                                            onBlur={(event) => handleBlur(event, 'phonenumber')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        placeholder="Email"
                                        name="email"
                                        error={!!state.errorMessages.step1.email}
                                        helperText={state.errorMessages.step1.email}
                                        autoComplete="email"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.email}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'email')}
                                        onBlur={(event) => handleBlur(event, 'email')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="emailConfirmation"
                                        placeholder="Confirm Email"
                                        name="emailConfirmation"
                                        error={!!state.errorMessages.step1.confirmEmail}
                                        helperText={state.errorMessages.step1.confirmEmail}
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.confirmEmail || ''}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'confirmEmail')}
                                        onBlur={(event) => handleBlur(event, 'confirmEmail')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        id="password"
                                        error={!!state.errorMessages.step1.password}
                                        helperText={state.errorMessages.step1.password}
                                        autoComplete="new-password"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.password}
                                        onChange={(event: InputChangeEvent) => handleInputChange(event, 'password')}
                                        onBlur={(event) => handleBlur(event, 'password')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        type="password"
                                        autoComplete="new-password"
                                        id="confirmPassword"
                                        error={!!state.errorMessages.step1.confirmPassword}
                                        helperText={state.errorMessages.step1.confirmPassword}
                                        // autoComplete="current-password"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.confirmPassword || ''}
                                        onChange={(event: InputChangeEvent) =>
                                            handleInputChange(event, 'confirmPassword')
                                        }
                                        onBlur={(event) => handleBlur(event, 'confirmPassword')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className="form input">
                                        <Select
                                            native
                                            fullWidth
                                            value={state.usingSystemFor}
                                            onChange={({ target: { value } }) =>
                                                setState({
                                                    ...state,
                                                    usingSystemFor: value as
                                                        | 'self'
                                                        | 'parent'
                                                        | 'grandparent'
                                                        | 'other',
                                                })
                                            }
                                            inputProps={{
                                                name: 'using it for',
                                            }}
                                        >
                                            <option key="self" value="self">
                                                Myself
                                            </option>
                                            <option key="parent" value="parent">
                                                Parent
                                            </option>
                                            <option key="grandparent" value="grandparent">
                                                Grandparent
                                            </option>
                                            <option key="other" value="other">
                                                Other
                                            </option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Typography className="error-message" component="h1" variant="subtitle1">
                                {state.errorMessage}
                            </Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Button
                                    disabled={!state.formIsValid}
                                    variant="contained"
                                    color="primary"
                                    className="submit-btn"
                                    id="nextButton"
                                    onClick={() => {
                                        return goToEmergencyContactForm();
                                    }}
                                >
                                    Next
                                </Button>
                                <Grid item>
                                    <RouterLink to="/login" id="goToLoginLink">
                                        {'Already have an account? Login'}
                                    </RouterLink>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                    {/* Caregiver sign-up*/}
                    {steps[state.signUpType][state.step] === 'mainInfo' && state.signUpType === 'caregiver' && (
                        <CaregiverSignUp signUp={signUpCallback} />
                    )}
                    {/* Add Emergency Contact form */}
                    {steps[state.signUpType][state.step] === 'emergencyContact' && state.signUpType === 'user' && (
                        <form
                            className="form"
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                goToNextStep();
                            }}
                        >
                            <Typography className="main-text" component="h1" variant="inherit" align="left">
                                Your loved one
                                <FavoriteBorderIcon className="sign-up__home-icon" />
                            </Typography>
                            <Typography component="p" variant="inherit" className="section-subtitle" align="left">
                                Enter your loved one’s information
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        error={!!state.errorMessages.step2.emergencyContactFirstName}
                                        helperText={state.errorMessages.step2.emergencyContactFirstName}
                                        name="emergencyContactFirstName"
                                        id="emergencyContactFirstName"
                                        placeholder="First Name"
                                        autoFocus
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.emergencyContactFirstName}
                                        onChange={(event: InputChangeEvent) =>
                                            handleInputChange(event, 'emergencyContactFirstName')
                                        }
                                        onBlur={(event) => handleBlur(event, 'emergencyContactFirstName')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        error={!!state.errorMessages.step2.emergencyContactLastName}
                                        helperText={state.errorMessages.step2.emergencyContactLastName}
                                        id="emergencyContactLastName"
                                        placeholder="Last Name"
                                        name="emergencyContactLastName"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.emergencyContactLastName}
                                        onChange={(event: InputChangeEvent) =>
                                            handleInputChange(event, 'emergencyContactLastName')
                                        }
                                        onBlur={(event) => handleBlur(event, 'emergencyContactLastName')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth required>
                                        <PhoneInput
                                            id="emergencyPhone"
                                            value={state.emergencyContactPhoneNumber}
                                            errorMessage={state.errorMessages.step2.emergencyContactPhoneNumber}
                                            onChange={(value) => {
                                                const emergencyContactPhoneNumber = (value as unknown) as string;
                                                setState({ ...state, emergencyContactPhoneNumber });
                                            }}
                                            onBlur={(event) => handleBlur(event, 'emergencyContactPhoneNumber')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="emergencyContactEmail"
                                        placeholder="Email"
                                        name="emergencyContactEmail"
                                        error={!!state.errorMessages.step2.emergencyContactEmail}
                                        helperText={state.errorMessages.step2.emergencyContactEmail}
                                        autoComplete="email"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.emergencyContactEmail}
                                        onChange={(event: InputChangeEvent) =>
                                            handleInputChange(event, 'emergencyContactEmail')
                                        }
                                        onBlur={(event) => handleBlur(event, 'emergencyContactEmail')}
                                    />
                                </Grid>
                            </Grid>
                            <Typography className="error-message" component="h1" variant="subtitle1">
                                {state.errorMessage}
                            </Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Button
                                    disabled={!state.formIsValid}
                                    variant="contained"
                                    color="primary"
                                    className="submit-btn"
                                    onClick={() => {
                                        if (
                                            fieldvalidation &&
                                            state.phonenumber === state.emergencyContactPhoneNumber
                                        ) {
                                            return setState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    showAddYourselfAsEmergencyContact: true,
                                                };
                                            });
                                        }

                                        return goToNextStep();
                                    }}
                                >
                                    Next
                                </Button>
                            </Grid>

                            <Dialog open={state.showAddYourselfAsEmergencyContact} fullWidth={false} maxWidth={false}>
                                <AddYourselfAsEmergencyContact
                                    action={() => {
                                        goToNextStep();

                                        return Promise.resolve();
                                    }}
                                    closeModal={() =>
                                        setState((prevState) => {
                                            return {
                                                ...prevState,
                                                showAddYourselfAsEmergencyContact: false,
                                            };
                                        })
                                    }
                                />
                            </Dialog>
                        </form>
                    )}
                    {/* User Info form*/}
                    {steps[state.signUpType][state.step] === 'additionalInfo' && state.signUpType === 'user' && (
                        <form
                            className="form"
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                signUpCallback(state);
                            }}
                        >
                            <Typography className="main-text" component="h1" variant="inherit" align="left">
                                Other info
                            </Typography>
                            <Typography component="p" variant="inherit" className="section-subtitle" align="left">
                                We ask this to comply with pharmacy regulations
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={state.userInfoGender}
                                        row={true}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            return setState((prevState) => {
                                                return { ...prevState, userInfoGender: value };
                                            });
                                        }}
                                    >
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    </RadioGroup>
                                </Grid>

                                {/* Alergies */}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="allergies"
                                        placeholder="Allergies"
                                        name="allergies"
                                        error={!!state.errorMessages.step7.userInfoAllergies}
                                        helperText={state.errorMessages.step7.userInfoAllergies}
                                        // autoComplete="Allergies"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.userInfoAllergies}
                                        onChange={(event: InputChangeEvent) =>
                                            handleInputChange(event, 'userInfoAllergies')
                                        }
                                        onBlur={(event) => handleBlur(event, 'userInfoAllergies')}
                                    />
                                </Grid>
                                {/* medical conditions */}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="medicalConditions"
                                        placeholder="Medical Conditions"
                                        name="medicalConditions"
                                        error={!!state.errorMessages.step7.userInfoMedicalConditions}
                                        helperText={state.errorMessages.step7.userInfoMedicalConditions}
                                        // autoComplete="medicalConditions"
                                        InputProps={{
                                            className: 'input',
                                        }}
                                        value={state.userInfoMedicalConditions}
                                        onChange={(event: InputChangeEvent) =>
                                            handleInputChange(event, 'userInfoMedicalConditions')
                                        }
                                        onBlur={(event) => handleBlur(event, 'userInfoMedicalConditions')}
                                    />
                                </Grid>
                                {/* dob */}
                                <Grid item xs={12}>
                                    <DatePicker
                                        error={!!state.errorMessages.step7.userInfoDOB}
                                        helperText={state.errorMessages.step7.userInfoDOB}
                                        onBlur={({ target: { value } }) => validateDate(value)}
                                        onChange={(date) => validateDate(date)}
                                        placeholder="Date of birth"
                                        value={state.userInfoDOB}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl
                                        required
                                        error={!!state.errorMessages.step7.termsAndConditions}
                                        component="fieldset"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.termsAndConditions}
                                                    onChange={(event: InputChangeEvent) =>
                                                        handleInputChange(event, 'termsAndConditions')
                                                    }
                                                    name="termsAndConditions"
                                                    onBlur={(event) => handleBlur(event, 'termsAndConditions')}
                                                />
                                            }
                                            label="I have read and accept the Terms of service"
                                        />
                                        <FormHelperText>{state.errorMessages.step7.termsAndConditions}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Typography className="error-message" component="h1" variant="subtitle1">
                                {state.errorMessage}
                            </Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item></Grid>
                                <Button
                                    disabled={!state.formIsValid}
                                    variant="contained"
                                    color="primary"
                                    className="submit-btn"
                                    onClick={() => signUpCallback(state)}
                                >
                                    Sign up
                                </Button>
                            </Grid>
                        </form>
                    )}
                    {/* Congratulation message */}
                    {steps[state.signUpType][state.step] === 'congratulation' && (
                        <div>
                            <FinalMessage email={state.email || ''} />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="submit-btn"
                                onClick={() => props.history.push('/login')}
                            >
                                Login to your account
                            </Button>
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default connector(SignUp);
