import React from 'react';

import Grid, { GridSize } from '@material-ui/core/Grid';

import { IExistingDoctorAppointmentOrder } from '../../lib/types';

import CommonDoctorAppointemntOrderListItem from './CommonDoctorAppointemntOrderListItem';

interface Props {
    order: IExistingDoctorAppointmentOrder;
}

const ExistingDoctorAppointemntOrderListItem: React.FC<Props> = ({ order: { cart } }) => {
    const { docName } = cart;

    return (
        <CommonDoctorAppointemntOrderListItem
            cart={cart}
            renderFirstRow={(firstColumnSize: GridSize, secondColumnSize: GridSize) => (
                <>
                    <Grid item xs={firstColumnSize}>
                        Name:
                    </Grid>
                    <Grid item xs={secondColumnSize}>
                        {docName}
                    </Grid>
                </>
            )}
        />
    );
};

export default ExistingDoctorAppointemntOrderListItem;
