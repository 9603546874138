import React, { useContext, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Grid } from '@material-ui/core';

import { IInsuranceProvider } from '../../../lib/types';
import { ApisContext } from '../../../lib/contexts';

import { RootState, DispatchType } from '../../../state';
import { getInsuranceProvidersAction } from '../../../state/actions/insuranceActions';

import InsuranceCard from '../../../components/Settings/InsuranceCard/InsuranceCard';
import SettingsCarousel from '../../../components/SettingsCarousel';
import InsuranceDialogContent, { FormState } from './InsuranceDialogContent';

const mapState = ({ user: { loggedInUser }, insurance: { insuranceProviders } }: RootState) => {
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return { userId, insuranceProviders };
    }
    return { insuranceProviders };
};
const mapDispatch = (dispatch: DispatchType) => ({
    getInsuranceProviders: getInsuranceProvidersAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const InsurancesCarousel: React.FC<PropsFromRedux> = ({ userId, insuranceProviders, getInsuranceProviders }) => {
    const { insuranceApi } = useContext(ApisContext);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formState, setFormState] = useState<FormState & { id?: string }>({});
    const [action, setAction] = useState<'add' | 'edit'>();

    useEffect(() => {
        if (userId) {
            getInsuranceProviders(insuranceApi, { userId });
        }
    }, [getInsuranceProviders, insuranceApi, userId]);

    const renderInsurances = () =>
        insuranceProviders.map((insuranceProvider, index) => (
            <Grid item xs={12} sm={3} key={index}>
                <InsuranceCard
                    insuranceProvider={insuranceProvider}
                    onEditClicked={() => {
                        setAction('edit');
                        setFormState(insuranceProvider);
                        setIsDialogOpen(true);
                    }}
                    onRemoveClicked={async () => {
                        const { id } = insuranceProvider;
                        if (userId) {
                            await insuranceApi.removeInsuranceProvider({
                                userId,
                                id,
                            });
                            await getInsuranceProviders(insuranceApi, { userId });
                        }
                    }}
                />
            </Grid>
        ));

    const { rxBin, rxGroup, rxMemberID, rxPCN, rxRelationshipToSubscriber, rxSubscriber, id, metaData } = formState;

    return (
        <SettingsCarousel
            dialogContent={<InsuranceDialogContent formState={formState} setFormState={setFormState} />}
            dialogTitle="Add your insurance info below"
            isDialogOpen={isDialogOpen}
            isSaveButtonDisabled={
                !(rxBin && rxGroup && rxMemberID && rxPCN && rxRelationshipToSubscriber && rxSubscriber && metaData)
            }
            items={renderInsurances()}
            onAddButtonPressed={() => {
                setAction('add');
                setFormState({});
                setIsDialogOpen(true);
            }}
            onCloseButtonPressed={() => setIsDialogOpen(false)}
            onSavePressed={async () => {
                if (userId && rxBin && rxGroup && rxMemberID && rxPCN && rxRelationshipToSubscriber && rxSubscriber) {
                    const insuranceProvider = {
                        ...formState,
                        metaData,
                        rxBin,
                        rxGroup,
                        rxMemberID,
                        rxPCN,
                        rxRelationshipToSubscriber,
                        rxSubscriber,
                    };
                    switch (action) {
                        case 'add': {
                            await insuranceApi.addInsuranceProvider({
                                insuranceProvider,
                                userId,
                            });
                            break;
                        }
                        case 'edit': {
                            if (id) {
                                await insuranceApi.editInsuranceProvider({
                                    insuranceProvider: {
                                        ...insuranceProvider,
                                        id,
                                    },
                                    userId,
                                });
                            }
                            break;
                        }
                    }
                    await getInsuranceProviders(insuranceApi, { userId });
                    setIsDialogOpen(false);
                }
            }}
        />
    );
};

export default connector(InsurancesCarousel);
