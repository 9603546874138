import _ from 'lodash';

function nameValidation(event: any, property: any, setState: any) {
    const value = event.target.value;
    const name = /^[A-Za-z \-']+$/;

    if (!name.test(value) && value.length) {
        return null;
    }

    return setState((prevState: any) => {
        const newState = _.clone(prevState);
        newState[property] = value;

        return newState;
    });
}

function testEmail(email: string) {
    return /^\S+@\S+\.\S+$/.test(email);
}

export const checkForErrorMessage = (obj: any) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] !== '') return false;
    }
    return true;
};

export const validationHelper = (field: string, value: string | null, state: any, setState: any, step: any) => {
    const fieldValidationErrors = state.errorMessages;
    if (typeof value === 'string') {
        switch (field) {
            case 'firstName':
            case 'emergencyContactFirstName':
                const nameValid = value.length >= 2 && value.match(/^[a-zA-Z\s]*$/);
                fieldValidationErrors[step][field] = nameValid ? '' : 'Please enter a valid name';
                break;

            case 'userInfoDOB':
                const DOBvalid = value && value.length >= 20;
                fieldValidationErrors[step][field] = DOBvalid ? '' : 'Please enter a valid date of birth';
                break;

            case 'lastName':
            case 'emergencyContactLastName':
                const lastNameValid = value.length >= 3 && value.match(/^[a-zA-Z\s]*$/);
                fieldValidationErrors[step][field] = lastNameValid ? '' : 'Please enter a valid last name';
                break;

            case 'phonenumber':
            case 'emergencyContactPhoneNumber':
                const phoneValid = value.length >= 10;
                fieldValidationErrors[step][field] = phoneValid ? '' : 'Please enter a valid phone number';
                break;

            case 'email':
            case 'emergencyContactEmail':
                const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors[step][field] = emailValid ? '' : 'Please enter a valid email';
                break;

            case 'confirmEmail':
                const confirmEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && value === state.email;
                fieldValidationErrors[step].confirmEmail = confirmEmailValid ? '' : 'Email must match';
                break;

            case 'password':
                const passwordValid = value.length >= 8;
                fieldValidationErrors[step].password = passwordValid ? '' : 'Please enter 8 or more characters';
                break;

            case 'confirmPassword':
                const confirmPasswordValue = value.length >= 6 && value === state.password;
                fieldValidationErrors[step].confirmPassword = confirmPasswordValue ? '' : 'Password must match';
                break;

            case 'termsAndConditions':
                const validTerms = value == 'true';
                fieldValidationErrors[step][field] = validTerms ? '' : 'You must accept the terms of service';
                break;

            case 'userInfoAllergies':
                const validAllergies = value.length >= 2 && value.match(/^[a-zA-Z\s]*$/);
                fieldValidationErrors[step][field] = validAllergies ? '' : 'Please enter a valid value';
                break;

            case 'userInfoMedicalConditions':
                const validMedicalConditions = value.length >= 2 && value.match(/^[a-zA-Z\s]*$/);
                fieldValidationErrors[step][field] = validMedicalConditions ? '' : 'Please enter a valid value';
                break;

            case 'userInfoDOB':
                const validUserDOB = value.length > 0;
                fieldValidationErrors[step][field] = validUserDOB ? '' : 'Please select a valid date';
                break;

            case 'rxMemberID':
            case 'rxGroupNo':
            case 'rxBin':
            case 'rxGroup':
            case 'rxPCN':
                const validUserInsurance =
                    value.length > 3 && value.length <= 20 && value.match(/^[A-Za-z0-9'\.\-\s\,]*$/);
                fieldValidationErrors[step][field] = validUserInsurance ? '' : 'Please enter a valid value';
                break;

            case 'rxSubscriber':
            case 'rxRelationshipToSubscriber':
                const validUserInsuranceRelationship = value.length > 0 && value.match(/^[a-zA-Z\s]*$/);
                fieldValidationErrors[step][field] = validUserInsuranceRelationship ? '' : 'Please enter a valid value';
                break;

            default:
                break;
        }
    }

    setState((prevState: any) => {
        return { ...prevState, errorMessages: fieldValidationErrors };
    });
};

export const addressValidationHelper = (
    field: string,
    value: string | null | undefined | google.maps.GeocoderResult,
    state: any,
    setState: any,
    step: any,
    index = 0
) => {
    const fieldValidationErrors = state.errorMessages;
    if (typeof value === 'string') {
        switch (field) {
            case 'addressNickname':
                const nameValid = value && value.length >= 2 && value.match(/^[a-zA-Z\s]*$/);
                fieldValidationErrors[step][index][field] = nameValid ? '' : 'Please enter a valid name';
                break;

            case 'address':
                const addressValid = value && value.length >= 5 && value.match(/^[A-Za-z0-9'\.\-\s\,]*$/);
                fieldValidationErrors[step][index][field] = addressValid ? '' : 'Please enter a valid address';
                break;

            case 'addressState':
                const addressState = value && value.length >= 2 && value.match(/^[a-zA-Z\s]*$/);
                fieldValidationErrors[step][index][field] = addressState ? '' : 'Please enter a valid state';
                break;

            case 'addressZipCode':
                const validZipCode = value && value.match(/^\d{5,7}(?:[-\s]\d{4})?$/);
                fieldValidationErrors[step][index][field] = validZipCode ? '' : 'Please enter a valid zip code';
                break;

            default:
                break;
        }
    }
    setState((prevState: any) => {
        return { ...prevState, errorMessages: fieldValidationErrors };
    });
};

export default {
    name: nameValidation,
    email: testEmail,
};
