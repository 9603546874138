import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import caneImage from '../../../assets/images/cane_front_panel_call.png';

interface Props {
    email: string;
}

const FinalMessage = (props: Props): JSX.Element => {
    return (
        <form className="form" noValidate>
            <Grid container>
                <Typography component="h1" className="main-text" variant="h4">
                    Congratulations! You've set up your account.
                </Typography>
                <Typography component="h1" variant="subtitle1" className="explanation-text">
                    Now charge your CAN Go™, wait a few minutes, then confirm that your CAN Go calls your emergency
                    contact by making a phone call (press the phone button).
                </Typography>
                <Grid container justify="center">
                    <img className="cane-front-panel-image" src={caneImage} alt="Cane front panel" />
                </Grid>
            </Grid>
        </form>
    );
};

export default FinalMessage;
