import React, { useState, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import { HomeAddressParams, HomeAddressType } from '../../../lib/types';
import { DispatchType, RootState } from '../../../state';
import { addAddressAction, updateLoggedInUserDataAction } from '../../../state/actions/userActions';
import { ApisContext } from '../../../lib/contexts';
import { AddressAutoFillCallback } from '../../../lib/types';
import ServiceCard from '../../../components/Settings/ServiceCard/ServiceCard';
import AddAddressDialog from '../../../components/AddAddressDialog';
// icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HomeIcon from '../../../assets/icons/settings-icon-home.svg';
// styles
import './styles.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const {
            userId,
            userInfo: { addressList },
        } = loggedInUser;
        return {
            userId,
            addressList,
        };
    }
    return undefined;
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
    addAddress: addAddressAction(dispatch),
    updateLoggedInUserData: updateLoggedInUserDataAction(dispatch),
});

const connector = connect(mapState, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AddressesCarousel: React.FC<PropsFromRedux> = (props) => {
    const { userApi } = useContext(ApisContext);
    const { addressList, updateLoggedInUserData, userId } = props;

    const [open, setOpen] = useState(false);

    const [editAddress, setEditAddress] = useState<HomeAddressType>();

    const handleModal = () => {
        setOpen(!open);
    };

    const renderAddresses = () =>
        addressList?.map((item: HomeAddressType, index: number) => (
            <Grid item xs={12} sm={3} key={item.id}>
                <ServiceCard
                    bottomActions={
                        <Grid container justify="space-between" className="bottom-actions">
                            <Grid item>
                                <p
                                    className="bottom-actions--edit"
                                    onClick={() => {
                                        setEditAddress(item);
                                        handleModal();
                                    }}
                                >
                                    Edit
                                </p>
                            </Grid>
                            <Grid item>
                                <p
                                    className="bottom-actions--remove"
                                    onClick={async () => {
                                        if (userId) {
                                            const { id } = item;
                                            await userApi.removeAddress({
                                                id,
                                                userId,
                                            });
                                            await updateLoggedInUserData(userApi);
                                        }
                                    }}
                                >
                                    Remove
                                </p>
                            </Grid>
                        </Grid>
                    }
                    cardIcon={HomeIcon}
                    cardTitle={item.addressNickname}
                    cardContent={item.address}
                />
            </Grid>
        ));

    return (
        <Grid container spacing={2} alignItems="center">
            {renderAddresses()}

            <Grid item>
                <IconButton
                    onClick={() => {
                        setEditAddress(undefined);
                        handleModal();
                    }}
                >
                    <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
            </Grid>
            <AddAddressDialog
                onClose={handleModal}
                value={editAddress}
                onSave={async (homeAddressParams) => {
                    const { addAddress, userId } = props;
                    if (!editAddress) {
                        await addAddress(userApi, {
                            userId,
                            address: homeAddressParams,
                        });
                    } else {
                        const { id } = editAddress;
                        const { address } = homeAddressParams;
                        if (id && address) {
                            await userApi.editAddress({
                                userId,
                                address: {
                                    ...homeAddressParams,
                                    address,
                                    id,
                                },
                            });
                        }
                    }
                    await updateLoggedInUserData(userApi);
                    handleModal();
                }}
                open={open}
            />
        </Grid>
    );
};

export default connector(AddressesCarousel);
