import React, { useContext } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import Card from '../../Card/Card';

import ActiveIconButton from '../../../components/ActiveIconButton';

import { cardBrandsInfo, CardBrandInfo } from '../../../lib/utils';

import { ApisContext } from '../../../lib/contexts';
import { IPaymentSource, CardBrand } from '../../../lib/types';
import { RootState, DispatchType } from '../../../state';
import { getPaymentSourcesAction } from '../../../state/actions/paymentActions';

// styles
import './styles.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return { userId };
    }
    return {};
};

const mapDispatch = (dispatch: DispatchType) => ({
    getPaymentSources: getPaymentSourcesAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = { paymentSource: IPaymentSource };

const PaymentCard: React.FC<Props & PropsFromRedux> = ({
    getPaymentSources,
    paymentSource: {
        active,
        cardHolderName,
        id,
        paymentMethodId,
        stripePaymentMethod: { card },
    },
    userId,
}) => {
    const { paymentApi } = useContext(ApisContext);

    let formattedCardNumber = undefined;
    let cardTitle = undefined;
    let brand: string = '';
    let expDate = undefined;
    if (card) {
        const { last4, exp_month, exp_year } = card;
        ({ brand } = card);

        cardTitle = `${cardHolderName}'s Card`;
        formattedCardNumber = `**** **** **** ${last4}`;
        expDate = `${exp_month}/${exp_year}`;
    }

    const { cardLogo, cardBrand }: CardBrandInfo =
        brand in CardBrand ? cardBrandsInfo[brand as CardBrand] : { cardBrand: brand };
    return (
        <Card>
            <Grid container className="payment-card">
                <Grid item xs={10}>
                    <Box className="payment-card__name">
                        <p>{cardTitle}</p>
                        {active ? <span>Active</span> : <span>&nbsp;</span>}
                    </Box>
                    <Box className="payment-card__card-info">
                        {cardLogo ? (
                            <figure>
                                <img src={cardLogo} alt={cardBrand} />
                            </figure>
                        ) : (
                            cardBrand
                        )}
                        <span>{formattedCardNumber}</span>
                    </Box>
                    <Box className="payment-card__expire-date">
                        <p>Expires {expDate}</p>
                    </Box>
                </Grid>
                <Grid container item xs={2} direction="column" justify="space-between">
                    <Box textAlign="right">
                        <ActiveIconButton
                            active={active}
                            onClick={async () => {
                                if (userId) {
                                    await paymentApi.setPaymentSourceActive({
                                        userId,
                                        id,
                                    });
                                    getPaymentSources(paymentApi, { userId });
                                }
                            }}
                        />
                    </Box>
                    <Box
                        textAlign="right"
                        className="payment-card__remove"
                        onClick={async () => {
                            if (userId) {
                                await paymentApi.removePaymentSource({
                                    userId,
                                    paymentMethodId,
                                });
                                getPaymentSources(paymentApi, { userId });
                            }
                        }}
                    >
                        <p>Remove</p>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default connector(PaymentCard);
