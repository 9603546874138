import React from 'react';

import Grid, { GridSize } from '@material-ui/core/Grid';

import { ICommonDoctorAppointmentCart } from '../../lib/types';

interface Props {
    cart: ICommonDoctorAppointmentCart;
    renderFirstRow: (firstColumnSize: GridSize, secondColumnSize: GridSize) => React.ReactElement;
}

const firstColumnSize = 4;
const secondColumnSize = 8;

const CommonDoctorAppointemntOrderListItem: React.FC<Props> = ({
    cart: { availabilityFrom, availabilityTo, docHospital, isOnlineAppointment },
    renderFirstRow,
}) => (
    <Grid container>
        <Grid container item xs={12}>
            {renderFirstRow(firstColumnSize, secondColumnSize)}
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={firstColumnSize}>
                Availability:
            </Grid>
            <Grid item xs={secondColumnSize}>{`${availabilityFrom} - ${availabilityTo}`}</Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={firstColumnSize}>
                Hospital address:
            </Grid>
            <Grid item xs={secondColumnSize}>
                {docHospital?.address}
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={firstColumnSize}>
                Online consultation:
            </Grid>
            <Grid item xs={secondColumnSize}>
                {isOnlineAppointment ? 'Yes' : 'No'}
            </Grid>
        </Grid>
    </Grid>
);

export default CommonDoctorAppointemntOrderListItem;
