import React, { useContext, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import _ from 'lodash';
import { Box, Button, Grid } from '@material-ui/core';

import Card from '../Card/Card';
import { ShoppingCartOutlined as ShoppingCartOutlinedIcon } from '@material-ui/icons';

import MedsButtonGroup from '../MedsButtonGroup/MedsButtonGroup';
import RequestSubmittedModal from '../RequestSubmittedModal';

import { IApiAddress, OVER_THE_COUNTER_MEDICATION, PRESCRIPTION_MEDICATION } from '../../lib/types';
import { ApisContext } from '../../lib/contexts';
import { DispatchType, RootState } from '../../state';
import {
    addOverTheCouterMedicationToCartAction,
    addPrescriptionMedicationToCartAction,
    clearMedicationCartsAction,
    submitMedicationsOrderAction,
} from '../../state/actions/medicationsActions';

import OverTheCounterInput from './OverTheCounterInput';
import PrescriptionInput from './PrescriptionInput';

const mapState = ({
    medications: {
        submitOrder: { state: submitOrderState, errorMessage: submitOrderErrorMessage },
        overTheCounterMedicationCart,
        prescriptionMedicationCart,
    },
    user: { loggedInUser },
}: RootState) => ({
    overTheCounterMedicationCart,
    prescriptionMedicationCart,
    submitOrderErrorMessage,
    submitOrderState,
    userId: loggedInUser?.userId,
});

const mapDispatch = (dispatch: DispatchType) => ({
    addOverTheCouterMedicationToCart: addOverTheCouterMedicationToCartAction(dispatch),
    addPrescriptionMedicationToCart: addPrescriptionMedicationToCartAction(dispatch),
    clearMedicationCarts: clearMedicationCartsAction(dispatch),
    submitOrder: submitMedicationsOrderAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IPrescriptionMedicationItemInput {
    pharmacyAddress?: IApiAddress;
    deliveryAddress?: IApiAddress;
}

interface IOverTheCounterMedicationItemInput {
    description?: string;
    drugNameOrBrand?: string;
    deliveryAddress?: IApiAddress;
    pharmacyName?: string;
}

const MEDICATION_TYPES = ['Over the counter', 'Prescription'];

const NUMBER_OF_STEPS = 2;

const MedicationService: React.FC<PropsFromRedux> = ({
    addOverTheCouterMedicationToCart,
    addPrescriptionMedicationToCart,
    clearMedicationCarts,
    overTheCounterMedicationCart,
    prescriptionMedicationCart,
    submitOrder,
    submitOrderErrorMessage,
    userId,
}) => {
    const { medicationsApi } = useContext(ApisContext);

    const [step, setStep] = useState(0);
    const [medsType, setMedsType] = useState(MEDICATION_TYPES[0]);
    const [errorMessage, setErrorMessage] = useState<string>();

    const [prescriptionMedicationItemInput, setPrescriptionMedicationItemInput] = useState<
        IPrescriptionMedicationItemInput
    >({});

    const [overTheCounterMedicationItemInput, setOverTheCounterMedicationItemInput] = useState<
        IOverTheCounterMedicationItemInput
    >({});

    const isOverTheCounter = medsType === MEDICATION_TYPES[0];

    const cartCount = overTheCounterMedicationCart.length + prescriptionMedicationCart.length;

    useEffect(() => setErrorMessage(submitOrderErrorMessage), [submitOrderErrorMessage]);

    const nextStep = () =>
        setStep((step) => {
            const nextStep = step + 1;
            return nextStep >= NUMBER_OF_STEPS ? 0 : nextStep;
        });

    const addMedsToOrder = () => {
        if (isOverTheCounter) {
            const { deliveryAddress, description, drugNameOrBrand, pharmacyName } = overTheCounterMedicationItemInput;
            if (deliveryAddress && description && drugNameOrBrand) {
                addOverTheCouterMedicationToCart({
                    deliveryAddress,
                    description,
                    drugNameOrBrand,
                    pharmacyName,
                    type: OVER_THE_COUNTER_MEDICATION,
                });
                setOverTheCounterMedicationItemInput({});
            }
        } else {
            const { deliveryAddress, pharmacyAddress } = prescriptionMedicationItemInput;
            if (deliveryAddress && pharmacyAddress) {
                addPrescriptionMedicationToCart({
                    deliveryAddress,
                    pharmacyAddress,
                    type: PRESCRIPTION_MEDICATION,
                });
                setPrescriptionMedicationItemInput({});
            }
        }
    };

    const submitOrderCallback = async () => {
        if (!userId) {
            throw new Error('Missing  userId');
        }

        if (overTheCounterMedicationCart.length > 0 || prescriptionMedicationCart.length > 0) {
            if (
                await submitOrder(medicationsApi, {
                    order: {
                        items: [...overTheCounterMedicationCart, ...prescriptionMedicationCart],
                    },
                    userId,
                })
            ) {
                setPrescriptionMedicationItemInput({});
                setOverTheCounterMedicationItemInput({});
                clearMedicationCarts();
                setErrorMessage(undefined);
                nextStep();
            }
        }
    };

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <Box className="module">
                    <Box className="module-top">
                        <h4>Medication</h4>
                    </Box>
                    {/* based on step */}

                    {/* Show go Back button if step is more than 0 */}
                    {step === 0 && (
                        <Box className="module-fields">
                            <Box className="module-topbuttons">
                                <MedsButtonGroup
                                    value={medsType}
                                    options={MEDICATION_TYPES}
                                    onChange={(medsType) => setMedsType(medsType)}
                                />
                            </Box>
                            {medsType === MEDICATION_TYPES[0] ? (
                                <OverTheCounterInput
                                    medsDeliveryAddress={overTheCounterMedicationItemInput.deliveryAddress}
                                    medsDescription={overTheCounterMedicationItemInput.description}
                                    medsName={overTheCounterMedicationItemInput.drugNameOrBrand}
                                    pharmacyName={overTheCounterMedicationItemInput.pharmacyName}
                                    setMedsDeliveryAddress={(deliveryAddress) =>
                                        setOverTheCounterMedicationItemInput({
                                            ...overTheCounterMedicationItemInput,
                                            deliveryAddress,
                                        })
                                    }
                                    setMedsDescription={(description) =>
                                        setOverTheCounterMedicationItemInput({
                                            ...overTheCounterMedicationItemInput,
                                            description,
                                        })
                                    }
                                    setMedsName={(drugNameOrBrand) =>
                                        setOverTheCounterMedicationItemInput({
                                            ...overTheCounterMedicationItemInput,
                                            drugNameOrBrand,
                                        })
                                    }
                                    setPharmacyName={(pharmacyName) =>
                                        setOverTheCounterMedicationItemInput({
                                            ...overTheCounterMedicationItemInput,
                                            pharmacyName,
                                        })
                                    }
                                />
                            ) : (
                                <PrescriptionInput
                                    deliveryAddress={prescriptionMedicationItemInput.deliveryAddress}
                                    pharmacyAddress={prescriptionMedicationItemInput.pharmacyAddress}
                                    setPharmacyAddress={(pharmacyAddress) =>
                                        setPrescriptionMedicationItemInput({
                                            ...prescriptionMedicationItemInput,
                                            pharmacyAddress,
                                        })
                                    }
                                    setDeliveryAddress={(deliveryAddress) =>
                                        setPrescriptionMedicationItemInput({
                                            ...prescriptionMedicationItemInput,
                                            deliveryAddress,
                                        })
                                    }
                                />
                            )}
                            <Box className="module-bot">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            disableRipple
                                            disabled={cartCount === 0}
                                            className="dashboard-btn"
                                            onClick={submitOrderCallback}
                                        >
                                            {cartCount} Request
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className="module-bot-right">
                                        <Box textAlign="right">
                                            <Button
                                                disableRipple
                                                className="dashboard-btn filled"
                                                onClick={addMedsToOrder}
                                            >
                                                Add
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Card>
            <RequestSubmittedModal
                headerImage="img/medication-request-submitted-modal-bg.png"
                nextSteps={({ phoneNumber }) => [
                    'We will connect you with reliable pharmacies and delivery partners to fulfill your order. If you chose a particular pharmacy, we will reach out to that store.',
                    `We will text you on ${phoneNumber} with confirmation details including price, pickup location, and drop off location.`,
                    'You will send us a confirmation via text before creating an order.',
                    'We will send you delivery status updates via text.',
                ]}
                onCloseModal={() => nextStep()}
                showModal={step === 1}
                title="Your medication was requested!"
            />
        </Grid>
    );
};

export default connector(MedicationService);
