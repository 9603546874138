import React, { useState } from 'react';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { Box, ButtonBase, ClickAwayListener, Paper } from '@material-ui/core';

import classNames from 'classnames';

// styles
import './styles.scss';

export type TypeSelectValue = { label: string; value: string | number };

type State = boolean;
interface Props {
    onChange?: (value: TypeSelectValue) => void;
    label?: string;
    placeholder?: string;
    options?: TypeSelectValue[];
    selectedValue?: TypeSelectValue;
    wide?: boolean;
}

export const Dropdown: React.FC<Props> = ({ onChange, label, placeholder, options, selectedValue, wide }: Props) => {
    const [isActive, setActive] = useState<State>(false);

    const handleClick = () => {
        setActive(!isActive);
    };

    const handleSelect = (item: TypeSelectValue) => {
        // run parent onChange
        if (onChange) onChange(item);
        // closing select
        setActive(false);
    };

    const container_width = wide ? '100%' : undefined;

    return (
        <ClickAwayListener onClickAway={() => setActive(false)}>
            <Box className="dropdown" width={container_width}>
                {label && (
                    <Box className="dropdown__label">
                        <p>{label}</p>
                    </Box>
                )}
                <Box className="dropdown__container" width={container_width}>
                    <ButtonBase
                        className={classNames({
                            'dropdown__container': true,
                            'dropdown__button-wide': wide,
                        })}
                        onClick={handleClick}
                    >
                        <Paper
                            elevation={3}
                            className={classNames({
                                'dropdown__button': true,
                                'dropdown__button-wide': wide,
                            })}
                        >
                            <span>{selectedValue ? selectedValue.label : placeholder}</span>
                            <span className="dropdown__button__icon">
                                {isActive ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                            </span>
                        </Paper>
                    </ButtonBase>
                    {isActive && (
                        <Paper elevation={3} className="dropdown__dropdown-menu">
                            <Box>
                                <ul className="dropdown__dropdown-menu__list">
                                    {options?.map((item, index) => (
                                        <li key={index} onClick={() => handleSelect(item)}>
                                            <span>{item.label}</span>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Paper>
                    )}
                </Box>
            </Box>
        </ClickAwayListener>
    );
};
