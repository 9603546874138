import image1 from '../../assets/images/groceries/1.png';
import image2 from '../../assets/images/groceries/2.png';
import image3 from '../../assets/images/groceries/3.png';
import image4 from '../../assets/images/groceries/4.png';

const groceriesData = [
    {
        img: `${image1}`,
        title: 'Breakfast',
        author: 'jill111',
        cols: 2,
        featured: true,
    },
    {
        img: `${image2}`,
        title: 'Tasty burger',
        author: 'director90',
    },
    {
        img: `${image3}`,
        title: 'Camera',
        author: 'Danson67',
    },
    {
        img: `${image4}`,
        title: 'Morning',
        author: 'fancycrave1',
        featured: true,
    },
    {
        img: `${image1}`,
        title: 'Hats',
        author: 'Hans',
    },
    {
        img: `${image2}`,
        title: 'Honey',
        author: 'fancycravel',
    },
    {
        img: `${image3}`,
        title: 'Vegetables',
        author: 'jill111',
        cols: 2,
    },
    {
        img: `${image4}`,
        title: 'Water plant',
        author: 'BkrmadtyaKarki',
    },
    {
        img: `${image1}`,
        title: 'Mushrooms',
        author: 'PublicDomainPictures',
    },
    {
        img: `${image2}`,
        title: 'Olive oil',
        author: 'congerdesign',
    },
    {
        img: `${image3}`,
        title: 'Sea star',
        cols: 2,
        author: '821292',
    },
    {
        img: `${image4}`,
        title: 'Bike',
        author: 'danfador',
    },
];

export default groceriesData;
