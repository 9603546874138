import React from 'react';

import Grid, { GridSize } from '@material-ui/core/Grid';

import { INewDoctorAppointmentOrder } from '../../lib/types';

import CommonDoctorAppointemntOrderListItem from './CommonDoctorAppointemntOrderListItem';

interface Props {
    order: INewDoctorAppointmentOrder;
}

const NewDoctorAppointemntOrderListItem: React.FC<Props> = ({ order: { cart } }) => {
    const { speciality } = cart;
    return (
        <CommonDoctorAppointemntOrderListItem
            cart={cart}
            renderFirstRow={(firstColumnSize: GridSize, secondColumnSize: GridSize) => (
                <>
                    <Grid item xs={firstColumnSize}>
                        Speciality:
                    </Grid>
                    <Grid item xs={secondColumnSize}>
                        {speciality}
                    </Grid>
                </>
            )}
        />
    );
};

export default NewDoctorAppointemntOrderListItem;
