import { IInsuranceProviderMetaData } from '../../lib/types';

const INSURANCE_PROVIDERS: IInsuranceProviderMetaData[] = [
    { name: 'Anthem', logo: './img/insurance_provider/Anthem.png', id: '6499bd24-957c-4383-bb8a-4a2ae02d11b9' },
    {
        name: 'Anthem Inc.',
        logo: './img/insurance_provider/Anthem_Inc.png',
        id: '7a05552c-e623-4e92-aa65-cf1c7638bd3a',
    },
    {
        name: 'Arkansas Blue Cross Blue Shield',
        logo: './img/insurance_provider/ABCBS.png',
        id: '24490c9c-30b8-4337-9dd1-a662e350f2c6',
    },
    {
        name: 'Blue Cross Blue Shield',
        logo: './img/insurance_provider/BCBS.png',
        id: 'd1363289-6053-48c2-81b0-fc394734cf2f',
    },
    {
        name: 'Blue Cross Blue Shield California',
        logo: './img/insurance_provider/BCBSC.png',
        id: '318d54e9-fe2c-4d21-9cb1-87ad2b6bc75f',
    },
    {
        name: 'Blue Cross Blue Shield of Alabama',
        logo: './img/insurance_provider/BCBSA.png',
        id: '28b7958e-60c1-4cef-92e8-59b4cdbdd6c2',
    },
    {
        name: 'Blue Cross Blue Shield of Kansas',
        logo: './img/insurance_provider/BCBSK.png',
        id: 'd291a325-424a-4f8c-bccc-97daf84ca5e0',
    },
    {
        name: 'Blue Cross Blue Shield of Michigan',
        logo: './img/insurance_provider/BCBSM.png',
        id: '8e91c64a-9ede-4070-af3e-9518cde9a930',
    },
    {
        name: 'Blue Cross Blue Shield of New Jersey',
        logo: './img/insurance_provider/BCBSNJ.png',
        id: '23276f99-4ae6-4791-8599-89481a5b8e0d',
    },
    {
        name: 'Blue Cross Blue Shield of North Carolina',
        logo: './img/insurance_provider/BCBSNC.png',
        id: 'c4220a02-b80a-44a5-a4d0-988e88b3b4b3',
    },
    {
        name: 'Blue Cross Blue Shield of South Carolina',
        logo: './img/insurance_provider/BCBSSC.png',
        id: '1b6bb8d8-32bb-41cd-b9f4-220ff1a7b8d6',
    },
    {
        name: 'Blue Cross Blue Shield of Tennessee',
        logo: './img/insurance_provider/BCBST.png',
        id: '8337e448-5581-48e3-9e9f-a06b4e87b38f',
    },
    {
        name: 'Blue Cross Blue Shield of Vermont',
        logo: './img/insurance_provider/BCBSV.png',
        id: 'd30859d6-347c-4a2e-8a4c-8004fa3a05ff',
    },
    {
        name: 'Blue Cross Blue Shield of Wyoming',
        logo: './img/insurance_provider/BCBSW.png',
        id: 'f9bb7104-db49-4d33-916d-5d9ca764e4fd',
    },
    {
        name: 'Blue Cross of Idaho',
        logo: './img/insurance_provider/BCI.png',
        id: '3d8eb9f2-cd53-4fc4-a0e8-b1d8d12fbbbb',
    },
    { name: 'CVS Health', logo: './img/insurance_provider/CVSH.png', id: '1a243e74-bb18-4d0e-81bc-73333d8dc253' },
    {
        name: 'Carefirst Inc.',
        logo: './img/insurance_provider/Carefirst_Inc.png',
        id: '1530310a-cba0-41bf-afd2-3a37340b5b4a',
    },
    { name: 'Caresource', logo: './img/insurance_provider/Caresource.png', id: '6d87990a-dd16-44a2-b0e4-548cfb0d33e3' },
    {
        name: 'Centene Corp.',
        logo: './img/insurance_provider/Centene_Corp.png',
        id: 'ee762138-d165-4705-b2bc-256f1ed04e2b',
    },
    { name: 'Cigna', logo: './img/insurance_provider/Cigna.png', id: '44341608-611e-434e-ac77-a3b55860fe6c' },
    {
        name: 'Cigna Health',
        logo: './img/insurance_provider/Cigna_Health.png',
        id: '030d2074-e350-497b-b8e8-e04162f1f8c1',
    },
    {
        name: 'Common Ground Healthcare Corp.',
        logo: './img/insurance_provider/CGHC.png',
        id: '658ed28e-1594-4d56-88a9-4345d0c04fc1',
    },
    {
        name: 'Emblem Health',
        logo: './img/insurance_provider/Emblem_Health.png',
        id: '8f481757-0287-47cf-b037-a09b622a08bc',
    },
    {
        name: 'Guidewell Mutual Health',
        logo: './img/insurance_provider/GMH.png',
        id: '5b819213-8e67-4a14-8f2d-f1cf053a54a4',
    },
    {
        name: 'Hawaii Medical Service',
        logo: './img/insurance_provider/HMS.png',
        id: '7b7e288f-2a0d-4f57-b313-bd962616be53',
    },
    {
        name: 'Health Care Service Corp.',
        logo: './img/insurance_provider/HCSC.png',
        id: '4f5fd34c-ba9f-416c-b20d-cef26243e4af',
    },
    {
        name: 'HealthPartners',
        logo: './img/insurance_provider/HealthPartners.png',
        id: '869dd4d4-d60f-4565-bc01-7e724528dadd',
    },
    { name: 'Highmark', logo: './img/insurance_provider/Highmark.png', id: '0839fbda-7797-4b6e-8e5b-936e3bbe1617' },
    { name: 'Humana', logo: './img/insurance_provider/Humana.png', id: 'e466f1de-91e8-4f5e-b13d-8dd04b3c5eee' },
    { name: 'IHC Inc.', logo: './img/insurance_provider/IHCI.png', id: 'e677b2d0-e451-415a-bfde-35999bf78acd' },
    {
        name: 'Independence Blue Cross',
        logo: './img/insurance_provider/IBC.png',
        id: '9ae5476a-a9fa-4a2a-a2ce-219f29c68c2b',
    },
    {
        name: 'Kaiser Foundation',
        logo: './img/insurance_provider/Kaiser_Foundation.png',
        id: '0017066d-56be-4c36-922a-d22d1fef94be',
    },
    {
        name: 'Louisiana Medical Serv',
        logo: './img/insurance_provider/LMS.png',
        id: '7a8baa58-1fd1-4adc-bd6e-36a7a2e6b8eb',
    },
    {
        name: 'Maine Comm Health Options',
        logo: './img/insurance_provider/MCHO.png',
        id: '023931c1-7776-43a7-b2b1-0d0cde4adc14',
    },
    { name: 'Medica', logo: './img/insurance_provider/Medica.png', id: '013d3bba-40fa-4a49-a144-34ea11e11122' },
    {
        name: 'Medical Mutual of Ohio',
        logo: './img/insurance_provider/MMO.png',
        id: '92ce620f-f889-47b5-8958-01ecf60eb43f',
    },
    {
        name: 'Molina Health Care Inc.',
        logo: './img/insurance_provider/MHCI.png',
        id: 'd4444242-ca21-47e3-9126-1b8c4ad81743',
    },
    {
        name: 'Molina Healthcare',
        logo: './img/insurance_provider/Molina_Healthcare.png',
        id: '5361f53f-08b4-49da-b7d0-1cbef6a5950f',
    },
    {
        name: 'Montana Health Cooperative',
        logo: './img/insurance_provider/MHC.png',
        id: 'b5134e5f-66d2-4602-bf9a-054c864b0e9b',
    },
    {
        name: 'Mulberry Health',
        logo: './img/insurance_provider/Mulberry_Health.png',
        id: '09bcc5c5-da11-468c-b0e6-8d64fb2aa956',
    },
    {
        name: 'Neighborhood Health Plan of Rhode Island',
        logo: './img/insurance_provider/NHPRI.png',
        id: 'c2fc6fda-ea11-4a34-8461-646c90e1c6f2',
    },
    {
        name: 'Noridian Mutual Insurance Co.',
        logo: './img/insurance_provider/NMIC.png',
        id: '1728974e-a492-43b4-931a-54ca9016c64b',
    },
    {
        name: 'Premera Blue Cross',
        logo: './img/insurance_provider/PBC.png',
        id: '868e8d7e-3326-44bb-8671-6e69edc3981c',
    },
    {
        name: 'Providence Health',
        logo: './img/insurance_provider/Providence_Health.png',
        id: 'f6eea331-dfa4-48cb-9366-d8ded7feefd1',
    },
    { name: 'Tufts', logo: './img/insurance_provider/Tufts.png', id: '4a1ee9ef-9f32-4dba-ab9c-eeae5ee00169' },
    {
        name: 'UnitedHealth',
        logo: './img/insurance_provider/UnitedHealth.png',
        id: '8f2b2fc3-b158-4655-9354-e1cb2e2c27b3',
    },
    {
        name: 'UnitedHealth Group',
        logo: './img/insurance_provider/UHG.png',
        id: 'cfe013e7-6cdf-4f99-a9d2-7ddf25c1fbe6',
    },
    {
        name: 'WellCare Health Plans (Centene)',
        logo: './img/insurance_provider/WCHPC.png',
        id: '80219814-3b02-40e4-89fb-711de33e12f4',
    },
    {
        name: 'Wellmark Inc.',
        logo: './img/insurance_provider/Wellmark_Inc.png',
        id: '662f34cb-121b-45bd-8179-371943968a7e',
    },
];

export default INSURANCE_PROVIDERS;
