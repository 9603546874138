import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactPixel from 'react-facebook-pixel';

import * as serviceWorker from './serviceWorker';
import { store, persistor } from './state/store';

import { createApis } from './lib/api';
import { ApisContext } from './lib/contexts';

import App from './App';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

let apiURL: string;
if (process.env.REACT_APP_API_BASE) {
    apiURL = process.env.REACT_APP_API_BASE;
} else {
    apiURL = isLocalhost ? `${window.location.protocol}//34.221.239.179:3000` : window.location.origin;
}

const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
};

if (process.env.REACT_APP_FACEBOOKPIXEL) {
    ReactPixel.init(process.env.REACT_APP_FACEBOOKPIXEL, undefined, options);
    ReactPixel.pageView(); // For tracking page view
} else {
    console.log('Please set up a facebook pixel id');
}

const apisContext = createApis(apiURL, () => {
    const {
        user: { loggedInUser },
    } = store.getState();

    if (loggedInUser) {
        const { accessToken } = loggedInUser;
        return accessToken;
    }

    return undefined;
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ApisContext.Provider value={apisContext}>
                    <App />
                </ApisContext.Provider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
