import React, { useContext, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Grid, Button, Dialog } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import classNames from 'classnames';

import EditUser from '../EditUser/EditUser';

import { DispatchType, RootState } from '../../../state';
import { logoutAction } from '../../../state/actions/userActions';

import { ApisContext } from '../../../lib/contexts';

import boyImage from '../../../assets/images/boy-fixed.jpg';
import girlImage from '../../../assets/images/girl-fixed.jpg';

import MenuItem from './MenuItem';
import NavButton from './NavButton';

// styles
import './Nav.scss';

export type { MenuItem };

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const {
            userInfo: { imageUrl, gender, firstName, lastName, email },
        } = loggedInUser;
        const fullName = firstName && lastName ? `${firstName} ${lastName}` : undefined;
        return { imageUrl, gender, fullName, email };
    }
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
    logout: logoutAction(dispatch),
});

const connector = connect(mapState, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    activeMenuItem: MenuItem;
    onMenuItemChange: (menuItem: MenuItem) => void;
}

const Nav: React.FC<Props & PropsFromRedux> = ({
    imageUrl,
    gender,
    fullName,
    email,
    logout,
    activeMenuItem,
    onMenuItemChange,
}) => {
    const history = useHistory();
    const { userApi } = useContext(ApisContext);

    const [isEditUserOpen, setIsEditUserOpen] = useState(false);

    const userImage = imageUrl ? imageUrl : gender === 'female' ? girlImage : boyImage;

    const navClasses = classNames('site-nav');

    const logoutCallback = async () => {
        await logout(userApi);
        history.push('/');
    };

    return (
        <nav id="sidebar" className={navClasses}>
            <Box className="site-nav__logo-holder">
                <img src="img/logo.png" alt="CAN Mobilities" />
            </Box>
            <Box className="site-nav__user-info" textAlign="center">
                <Grid container justify="center">
                    <Avatar alt={fullName} src={userImage} style={{ height: 120, width: 120, marginBottom: 20 }} />
                </Grid>
                <h5>{fullName}</h5>
                <a href={'mailto:' + email}>{email + ''}</a>
            </Box>
            <Grid container className="site-nav__edit-container">
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disableRipple={true}
                    onClick={() => setIsEditUserOpen(true)}
                >
                    <EditIcon className="site-nav__edit-icon" /> Edit
                </Button>
                <Dialog open={isEditUserOpen} maxWidth={false}>
                    <EditUser closeModal={() => setIsEditUserOpen(false)} />
                </Dialog>
            </Grid>
            <Grid container className="site-nav__menu-container">
                <Grid item xs={12}>
                    <NavButton
                        thisMenuItem="dashboard"
                        activeMenuItem={activeMenuItem}
                        onButtonPressed={onMenuItemChange}
                    >
                        CAN Home
                    </NavButton>
                </Grid>
                <Grid item xs={12}>
                    <NavButton
                        thisMenuItem="settings"
                        activeMenuItem={activeMenuItem}
                        onButtonPressed={onMenuItemChange}
                    >
                        Settings
                    </NavButton>
                </Grid>
                <Grid item xs={12}>
                    <NavButton
                        thisMenuItem="orderHistory"
                        activeMenuItem={activeMenuItem}
                        onButtonPressed={onMenuItemChange}
                    >
                        Order History
                    </NavButton>
                </Grid>
            </Grid>

            <Box className="site-nav__footer">
                <Grid container justify="space-between">
                    <Grid item xs={6}>
                        <Box>
                            <a href="mailto: hi@canmobilities.com">Support</a>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box onClick={logoutCallback} textAlign="right">
                            <span>Logout</span>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </nav>
    );
};

export default connector(Nav);
