import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Card from '../../Card/Card';
import { insuranceCompanies } from '../../../lib/utils';
import { IInsuranceProvider } from '../../../lib/types';
// styles
import './styles.scss';

type Props = { insuranceProvider: IInsuranceProvider; onEditClicked: () => void; onRemoveClicked: () => void };

const InsuranceCard: React.FC<Props> = ({
    insuranceProvider: { rxMemberID, rxBin, rxSubscriber, metaData },
    onEditClicked,
    onRemoveClicked,
}) => {
    return (
        <Card>
            <Grid container className="insurance-card">
                <Grid container item xs={10} justify="space-between" spacing={3}>
                    <Grid item className="insurance-card__name" xs={12}>
                        <p>{rxSubscriber}</p>
                    </Grid>
                    {metaData && (
                        <Grid container item className="insurance-card__image-holder" xs={6} alignItems="center">
                            <figure>
                                <img src={metaData.logo} />
                            </figure>
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        className="insurance-card__insurance-info"
                        xs={metaData ? 6 : 12}
                        alignItems="center"
                    >
                        <div>
                            <p>Member ID:</p>
                            <p>{rxMemberID}</p>
                        </div>
                    </Grid>
                    <Grid item className="insurance-card__insurance-info" xs={12}>
                        <p>Rx BIN:</p>
                        <p>{rxBin}</p>
                    </Grid>
                </Grid>
                <Grid container item xs={2} direction="column" justify="space-between">
                    <Box textAlign="right" className="insurance-card__edit" onClick={onEditClicked}>
                        <p>Edit</p>
                    </Box>
                    {/* see CHVF-82
                    <Box textAlign="right" className="insurance-card__image-holder">
                        <figure>
                            <img
                                src={insuranceCompanies[insuranceCompany].companyLogo}
                                alt={insuranceCompanies[insuranceCompany].companyName}
                            />
                        </figure>
                    </Box>
                    */}
                    <Box textAlign="right" className="insurance-card__remove" onClick={onRemoveClicked}>
                        <p>Remove</p>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default InsuranceCard;
