import React from 'react';

import { Button, Box } from '@material-ui/core';

import MenuItem from './MenuItem';

import './NavButton.scss';

interface Props {
    readonly activeMenuItem: MenuItem;
    readonly onButtonPressed: (menuItem: MenuItem) => void;
    readonly thisMenuItem: MenuItem;
}

const NavButton: React.FC<Props> = ({ activeMenuItem, children, thisMenuItem, onButtonPressed }) => (
    <Box className={thisMenuItem === activeMenuItem ? 'NavButton__active' : ''}>
        <Button fullWidth className="NavButton__button" color="inherit" onClick={() => onButtonPressed(thisMenuItem)}>
            {children}
        </Button>
    </Box>
);

export default NavButton;
