import React from 'react';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';

import { IGroceryCartItem, IGroceryProduct } from '../../lib/types';
// styles
import './GroceryCartList.scss';

interface Props {
    items: IGroceryCartItem[];
    removeGroceryProductFromCart?: (product: IGroceryProduct, all: boolean) => void;
}

const GroceryCartList: React.FC<Props> = ({ items, removeGroceryProductFromCart }) => {
    return (
        <>
            {items.map(({ product, count }) => {
                const { id, image, name_long, name, unit_price, units } = product;
                return (
                    <Grid container item xs={12} spacing={1} key={id} className="grocery-cartitem" alignItems="center">
                        <Grid item xs={2}>
                            <figure className="grocery-cartitem__image-holder">
                                {image ? <img src={image} alt="CAN Mobilities" /> : null}
                            </figure>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="inherit" component="p" className="grocery-cartitem__nameText">
                                {name_long || name}
                            </Typography>
                            <Typography variant="inherit" component="p" className="grocery-cartitem__smallLightText">
                                {units}
                            </Typography>
                        </Grid>
                        <Grid container item xs={3} spacing={1} justify="center">
                            <Grid item xs={6}>
                                <Typography
                                    variant="inherit"
                                    component="p"
                                    className="grocery-cartitem__smallLightText"
                                >
                                    Qty
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="inherit" component="p" className="grocery-cartitem__countText">
                                    {count}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={3} className="grocery-cartitem__lastColumn">
                            {removeGroceryProductFromCart ? (
                                <Grid item xs={12}>
                                    <Button
                                        disableRipple
                                        onClick={() => removeGroceryProductFromCart(product, true)}
                                        classes={{ label: 'grocery-cartitem__buttonLabel' }}
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Typography variant="inherit" component="p" className="grocery-cartitem__smallDarkText">
                                    ${(unit_price * count).toFixed(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pb={2}>
                                <Divider orientation="horizontal" />
                            </Box>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

export default GroceryCartList;
