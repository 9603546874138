import { IInsuranceApi, IGetInsuranceProvidersParams } from '../../lib/api';

import { DispatchType } from '../';
import {
    INSURANCE_GET_PROVIDERS_ERROR,
    INSURANCE_GET_PROVIDERS_PENDING,
    INSURANCE_GET_PROVIDERS_SUCCESS,
} from '../constants';

export const getInsuranceProvidersAction = (dispatch: DispatchType) => async (
    insuranceApi: IInsuranceApi,
    params: IGetInsuranceProvidersParams
) => {
    dispatch({
        type: INSURANCE_GET_PROVIDERS_PENDING,
    });

    try {
        const { insuranceProviders } = await insuranceApi.getInsuranceProviders(params);
        dispatch({
            type: INSURANCE_GET_PROVIDERS_SUCCESS,
            data: {
                insuranceProviders,
            },
        });
    } catch (e) {
        dispatch({
            type: INSURANCE_GET_PROVIDERS_ERROR,
            data: {
                errorMessage: 'error',
            },
        });
    }
};
