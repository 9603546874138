import { IGroceriesSubmitOrderParams, IUserApi, IGetGroceryListParams } from '../../lib/api';
import { IGroceryProduct, IGroceryCart } from '../../lib/types';

import { DispatchType } from '../';

import {
    GROCERIES_ADD_AVAILABLE_PRODUCTS,
    GROCERIES_ADD_PRODUCT_TO_CART,
    GROCERIES_CLEAR_CART,
    GROCERIES_ORDER_SUBMIT_ERROR,
    GROCERIES_ORDER_SUBMIT_PENDING,
    GROCERIES_ORDER_SUBMIT_SUCCESS,
    GROCERIES_REMOVE_PRODUCT_FROM_CART,
    GROCERIES_REORDER_CART,
} from '../constants';
import { submitOrderHelper } from '../utils';

export const submitGroceriesOrderAction = submitOrderHelper<IGroceriesSubmitOrderParams>(
    GROCERIES_ORDER_SUBMIT_PENDING,
    GROCERIES_ORDER_SUBMIT_SUCCESS,
    GROCERIES_ORDER_SUBMIT_ERROR
);

export const addAvailableGroceryProductsAction = (dispatch: DispatchType) => async (
    userApi: IUserApi,
    params: IGetGroceryListParams
) => {
    const { groceryProducts: products } = await userApi.getGroceryList(params);
    dispatch({
        type: GROCERIES_ADD_AVAILABLE_PRODUCTS,
        data: {
            products,
        },
    });
};

export const addGroceryProductToCartAction = (dispatch: DispatchType) => (product: IGroceryProduct, count?: number) =>
    dispatch({
        type: GROCERIES_ADD_PRODUCT_TO_CART,
        data: {
            product,
            count,
        },
    });

export const removeGroceryProductFromCartAction = (dispatch: DispatchType) => (
    product: IGroceryProduct,
    all: boolean = false
) =>
    dispatch({
        type: GROCERIES_REMOVE_PRODUCT_FROM_CART,
        data: {
            all,
            product,
        },
    });

export const clearGroceryCartAction = (dispatch: DispatchType) => () =>
    dispatch({
        type: GROCERIES_CLEAR_CART,
    });

export const reorderGroceryCartAction = (dispatch: DispatchType) => (groceryCart: IGroceryCart) =>
    dispatch({
        type: GROCERIES_REORDER_CART,
        data: {
            groceryCart,
        },
    });
