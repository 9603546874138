import { IRideOrder } from '../types';

import { IApiBase } from './api_base';

export interface IRidesSubmitOrderParams {
    readonly order: Readonly<IRideOrder>;
    readonly userId: string;
}

export interface IRidesApi {
    submitOrder(params: IRidesSubmitOrderParams): Promise<void>;
}

export class RidesApi implements IRidesApi {
    protected apiBase: IApiBase;

    constructor(apiBase: IApiBase) {
        this.apiBase = apiBase;
    }

    async submitOrder({ userId, order }: IRidesSubmitOrderParams): Promise<void> {
        await this.apiBase.post('/api/request', {
            data: order,
            dateRequested: new Date(),
            provider: 'Uber',
            requestFrom: 'dashboard',
            serviceName: 'UberRides',
            status: 'init',
            userId,
        });
    }
}
