import React, { useContext, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import _ from 'lodash';
import { Grid, Button, Dialog } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditIcon from '@material-ui/icons/Edit';
import EditUser from '../EditUser/EditUser';
import logo from '../../../assets/images/logo.png';
import boyImage from '../../../assets/images/boy.png';
import girlImage from '../../../assets/images/girl.png';

import { ApisContext } from '../../../lib/contexts';
import { DispatchType, RootState } from '../../../state';
import { logoutAction } from '../../../state/actions/userActions';

import './UserInfo.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        return { ...loggedInUser.userInfo };
    }
    throw new Error('loggedInUser is undefined');
};

const mapDispatch = (dispatch: DispatchType) => ({
    logout: logoutAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const UserInfo: React.FC<PropsFromRedux> = ({
    age,
    email,
    firstName,
    gender,
    height,
    imageUrl,
    lastName,
    logout,
    phoneNumber,
    weight,
}) => {
    const { userApi } = useContext(ApisContext);
    const [state, setState] = useState({
        openEditUser: false,
        showFullInfo: window.innerWidth > 1280 ? true : false,
        showFullInfoText: 'Show Full Info',
    });

    let userImage = imageUrl;

    if (!userImage) {
        userImage = gender === 'female' ? girlImage : boyImage;
    }

    function closeModal() {
        setState((prevState) => {
            return { ...prevState, openEditUser: false };
        });
    }

    function toggleShowFullInfo() {
        const showFullInfoText = state.showFullInfo ? 'Show full info' : 'Hide full info';

        setState((prevState) => {
            return {
                ...prevState,
                showFullInfo: !state.showFullInfo,
                showFullInfoText,
            };
        });
    }

    const additionalUserInfo = [
        ['Phone Number', phoneNumber],
        ['First Name', firstName],
        ['Last Name', lastName],
        ['Gender', gender],
        ['Age', age],
        ['Weight', weight],
        ['Height', height],
    ];

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className="user-info-container"
            xs={12}
            sm={12}
            md={12}
            lg={3}
        >
            <Grid item className="user-info-container__logo-container">
                <img src={logo} alt="CAN logo" className="user-info-container__logo" />
            </Grid>
            <Grid item className="user-info-container__user-image-container">
                <div
                    style={{
                        backgroundImage: `url(${userImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: '50%',
                    }}
                    className="user-info-container__user-image"
                ></div>
            </Grid>
            <Grid
                container
                item
                direction="column"
                justify="flex-start"
                alignItems="center"
                className="user-info-container__main-user-info"
            >
                <Grid item className="user-info-container__user-phonenumber">
                    {`${firstName} ${lastName}`}
                </Grid>
                <Grid item className="user-info-container__user-email gray-text">
                    {email}
                </Grid>
                <Grid item className="user-info-container__full-info-btn" onClick={toggleShowFullInfo}>
                    {state.showFullInfoText}
                </Grid>
            </Grid>
            {state.showFullInfo && (
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    <Grid
                        container
                        item
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className="user-info-container__additional-user-info"
                    >
                        {_.map(additionalUserInfo, (item) => {
                            return (
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    className="user-info-container__additional-info-item"
                                    xs={12}
                                    sm={5}
                                    md={5}
                                    lg={12}
                                >
                                    <Grid item className="user-info-container__additional-info-key gray-text">
                                        {item[0]}
                                    </Grid>
                                    <Grid item className="user-info-container__additional-info-value">
                                        {item[1]}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item className="user-info-container__user-edit-container">
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                setState((prevState) => {
                                    return { ...prevState, openEditUser: true };
                                })
                            }
                        >
                            <EditIcon className="user-info-container__edit-icon" /> Edit
                        </Button>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        className="user-info-container__user-info-footer"
                    >
                        <Grid item className="user-info-container__support-btn-container">
                            <a
                                href="mailto: hi@canmobilities.com"
                                className="user-info-container__support-btn gray-text"
                            >
                                <ContactSupportIcon className="user-info-container__text-icons" />
                                Support
                            </a>
                        </Grid>
                        <Grid item className="user-info-container__logout-btn" onClick={() => logout(userApi)}>
                            <ExitToAppIcon className="user-info-container__text-icons" />
                            Logout
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Dialog open={state.openEditUser} maxWidth={false}>
                <EditUser closeModal={closeModal}></EditUser>
            </Dialog>
        </Grid>
    );
};

export default connector(UserInfo);
