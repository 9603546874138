import React from 'react';
import Grid from '@material-ui/core/Grid';
import './PanelWithImage.scss';
// import logo from '../../assets/images/logo.png';

const PanelWithImage = (): JSX.Element => {
    return (
        <Grid item xs={false} sm={4} md={6} className="image-panel__hero-image" />
        // <Grid
        //     item
        //     className={classes.leftPanelContainer}
        //     md={6}
        // >
        //     <Grid
        //         container
        //         direction="column"
        //         className={classes.leftPanel}
        //         // maxWidth="xs"
        //         justify="center"
        //         alignItems="center"
        //     >
        //         {/*
        //         <div>
        //             <img src={logo} alt="CAN logo" />
        //         </div>
        //         */}
        //         {/*
        //         <Grid item className={classes.leftPanelText}>
        //             Smart, connected, safe
        //         </Grid>
        //         */}
        //         <Grid item className={classes.deviceImage} />
        //     </Grid>
        // </Grid>
    );
};

export default PanelWithImage;
