import { ICaregiverVisitOrder } from '../types';

import { IApiBase } from './api_base';

export interface ICaregiverSubmitOrderParams {
    readonly order: Readonly<ICaregiverVisitOrder>;
    readonly userId: string;
}

export interface ICaregiverApi {
    submitOrder(params: ICaregiverSubmitOrderParams): Promise<void>;
}

export class CaregiverApi implements ICaregiverApi {
    protected apiBase: IApiBase;

    constructor(apiBase: IApiBase) {
        this.apiBase = apiBase;
    }

    async submitOrder({ userId, order }: ICaregiverSubmitOrderParams): Promise<void> {
        await this.apiBase.post('/api/request', {
            data: order,
            dateRequested: new Date(),
            provider: 'generic',
            requestFrom: 'dashboard',
            serviceName: 'CaregiverVisits',
            status: 'init',
            userId,
        });
    }
}
