import * as React from 'react';

import {
    ICaregiverApi,
    IDoctorAppointmentsApi,
    IGroceriesApi,
    IInsuranceApi,
    IMedicationsApi,
    IPaymentApi,
    IRequestsApi,
    IRidesApi,
    IUserApi,
} from '../api';

export interface Apis {
    caregiverApi: ICaregiverApi;
    doctorAppointmentsApi: IDoctorAppointmentsApi;
    groceriesApi: IGroceriesApi;
    medicationsApi: IMedicationsApi;
    paymentApi: IPaymentApi;
    requestsApi: IRequestsApi;
    ridesApi: IRidesApi;
    userApi: IUserApi;
    insuranceApi: IInsuranceApi;
}

const notInitialized = () => {
    throw new Error('Api not initialized');
};

const defaultApis = {
    groceriesApi: {
        submitOrder: notInitialized,
    },
    userApi: {
        addAddress: notInitialized,
        changeEmail: notInitialized,
        changePassword: notInitialized,
        changePhoneNumber: notInitialized,
        editAddress: notInitialized,
        getGroceryList: notInitialized,
        getSignedImageURL: notInitialized,
        getUploadURL: notInitialized,
        getUserInfo: notInitialized,
        login: notInitialized,
        logout: notInitialized,
        removeAddress: notInitialized,
        reset: notInitialized,
        setIsFirstVisit: notInitialized,
        signUp: notInitialized,
        updateSelectedServices: notInitialized,
        updateUserInfo: notInitialized,
        uploadImage: notInitialized,
        uploadUserImage: notInitialized,
    },
    ridesApi: {
        submitOrder: notInitialized,
    },
    medicationsApi: {
        submitOrder: notInitialized,
    },
    doctorAppointmentsApi: {
        submitOrder: notInitialized,
    },
    caregiverApi: {
        submitOrder: notInitialized,
    },
    requestsApi: {
        getAllRequests: notInitialized,
    },
    paymentApi: {
        addPaymentSource: notInitialized,
        getPaymentSources: notInitialized,
        removePaymentSource: notInitialized,
        setPaymentSourceActive: notInitialized,
    },
    insuranceApi: {
        addInsuranceProvider: notInitialized,
        getInsuranceProviders: notInitialized,
        removeInsuranceProvider: notInitialized,
        editInsuranceProvider: notInitialized,
    },
};

export const ApisContext = React.createContext<Apis>(defaultApis);
