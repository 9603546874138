import { IGroceryOrder } from '../types';

import { IApiBase } from './api_base';

export interface IGroceriesSubmitOrderParams {
    readonly order: IGroceryOrder;
    readonly userId: string;
}

export interface IGroceriesApi {
    submitOrder(params: IGroceriesSubmitOrderParams): Promise<void>;
}

export class GroceriesApi implements IGroceriesApi {
    protected apiBase: IApiBase;

    constructor(apiBase: IApiBase) {
        this.apiBase = apiBase;
    }

    async submitOrder({ order, userId }: IGroceriesSubmitOrderParams): Promise<void> {
        await this.apiBase.post('/api/request', {
            data: order,
            dateRequested: new Date(),
            provider: 'generic',
            requestFrom: 'dashboard',
            serviceName: 'Groceries',
            status: 'init',
            userId,
        });
    }
}
