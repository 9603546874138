import { Button, FormControl, Grid, Input, TextField } from '@material-ui/core';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';

import React, { useMemo, useState } from 'react';

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    'color': '#424770',
                    'letterSpacing': '0.025em',
                    'border': 'groove !important',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        }),
        []
    );

    return options;
};

export interface OnSuccessParams {
    stripePaymentMethod: PaymentMethod;
    cardHolderName: string;
}

interface Props {
    onError: (errorMessage: string) => void;
    onSuccess: (params: OnSuccessParams) => void;
}

const StripeCardForm: React.FC<Props> = ({ onError, onSuccess }) => {
    const [state, setState] = useState({ name: '' });
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement) as any,
        });

        console.log('[PaymentMethod]', payload);

        const { error, paymentMethod: stripePaymentMethod } = payload;
        if (error) {
            const { message } = error;
            onError(message || 'Stripe error');
        } else {
            // sending payment method data to signup component
            if (stripePaymentMethod) {
                onSuccess({ stripePaymentMethod, cardHolderName: state.name });
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} className="form-group">
                    <TextField
                        className="form-control input-lg stripe-payment-fields"
                        required
                        fullWidth
                        id="card_holder_name"
                        placeholder="Card holder name"
                        name="card_holder_name"
                        value={state.name}
                        onChange={(event) => {
                            const value = event.target.value;
                            return setState((prevState: any) => {
                                return { ...prevState, name: value };
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className="form-group" fullWidth required>
                        <Input
                            id="stripeCardNumberWrapper"
                            className="form-control input-lg stripe-payment-fields"
                            inputComponent={CardNumberElement as any}
                            name="stripeCardNumberWrapper"
                            inputProps={{
                                options: options,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className="form-group" fullWidth required>
                        <Input
                            id="stripeCardExpiryWrapper"
                            className="form-control input-lg stripe-payment-fields"
                            inputComponent={CardExpiryElement as any}
                            name="stripeCardExpiryWrapper"
                            inputProps={{
                                options: options,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={12} justify="flex-start">
                    <FormControl className="form-group" fullWidth required>
                        <Input
                            id="stripeCardCvcElementWrapper"
                            className="form-control input-lg stripe-payment-fields"
                            inputComponent={CardCvcElement as any}
                            name="stripeCardCvcElementWrapper"
                            inputProps={{
                                options: options,
                            }}
                        />
                    </FormControl>
                    <Button variant="contained" type="submit" disabled={!stripe} style={{ marginTop: 20 }}>
                        Add Credit Card
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default StripeCardForm;
