import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Box, Grid } from '@material-ui/core';

import { RootState } from '../../state';

import AddressSearchBar from '../AddressSearchBar/AddressSearchBar';
import { HomeAddressType, IApiAddress } from '../../lib/types';

const mapState = ({ user: { loggedInUser } }: RootState) => ({
    addressList: loggedInUser?.userInfo.addressList,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    readonly medsDeliveryAddress: IApiAddress | undefined;
    readonly medsDescription: string | undefined;
    readonly medsName: string | undefined;
    readonly pharmacyName: string | undefined;
    readonly setMedsDeliveryAddress: (medsDeliveryAddress: IApiAddress | undefined) => void;
    readonly setMedsDescription: (medsDescription: string) => void;
    readonly setMedsName: (medsName: string) => void;
    readonly setPharmacyName: (value: string | undefined) => void;
}

const OverTheCounterInput: React.FC<Props & PropsFromRedux> = ({
    addressList,
    medsDeliveryAddress,
    medsDescription,
    medsName,
    pharmacyName,
    setMedsDeliveryAddress,
    setMedsDescription,
    setMedsName,
    setPharmacyName,
}) => (
    <Box>
        <Box className="form-group">
            <input
                required
                className="form-control dashboard-input"
                placeholder="Drug name or brand"
                type="text"
                value={medsName}
                onChange={({ target: { value } }) => setMedsName(value)}
            />
        </Box>
        <Box className="form-group">
            <input
                required
                className="form-control dashboard-input"
                placeholder="Description"
                type="text"
                value={medsDescription}
                onChange={({ target: { value } }) => setMedsDescription(value)}
            />
        </Box>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
            <Grid item xs={6}>
                <AddressSearchBar
                    parentCallback={setMedsDeliveryAddress}
                    placeholder="Delivery address"
                    value={medsDeliveryAddress}
                    savedAddresses={addressList}
                ></AddressSearchBar>
            </Grid>
            <Grid item xs={6}>
                <Box className="form-group">
                    <input
                        required
                        className="form-control dashboard-input"
                        placeholder="Pharmacy name"
                        type="text"
                        value={pharmacyName}
                        onChange={({ target: { value } }) => setPharmacyName(value)}
                    />
                </Box>
            </Grid>
        </Grid>
    </Box>
);

export default connector(OverTheCounterInput);
