import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, TextField, Link } from '@material-ui/core';

import AddressAutoFill from '../../components/AddressAutoFill/AddressAutoFill';
import DatePicker from '../../components/DatePicker';

import { AddressAutoFillObjectType, IPrescriptionInfo } from '../../lib/types';

interface Props {
    onNextStepClicked: (prescriptionInfo: IPrescriptionInfo) => void;
    onSkipStep: () => void;
    value: IPrescriptionInfo | undefined;
}

const PrescriptionInfoStep: React.FC<Props> = ({ onNextStepClicked, value, onSkipStep }) => {
    const [addressObject, setAddressObject] = useState<Partial<AddressAutoFillObjectType>>();
    const [dateOfBirth, setDateOfBirth] = useState<string>();
    const [pharmacyName, setPharmacyName] = useState<string>();
    const [whoIsThisFor, setWhoIsThisFor] = useState<string>();

    useEffect(() => {
        const { addressObject, dateOfBirth, pharmacyName, whoIsThisFor } = value || {};
        setAddressObject(addressObject);
        setDateOfBirth(dateOfBirth);
        setPharmacyName(pharmacyName);
        setWhoIsThisFor(whoIsThisFor);
    }, [value]);

    const { address, addressState, addressZipCode, placesObject } = addressObject || {};

    let prescriptionInfo: IPrescriptionInfo | undefined = undefined;

    if (pharmacyName && whoIsThisFor && dateOfBirth && address && placesObject) {
        prescriptionInfo = {
            pharmacyName,
            whoIsThisFor,
            dateOfBirth,
            addressObject: {
                ...addressObject,
                placesObject,
                address,
            },
        };
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography className="main-text" component="h1" variant="inherit" align="left">
                    Prescription info
                </Typography>
                <Typography component="p" variant="inherit" className="section-subtitle" align="left">
                    Let's locate your pharmacy address
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    placeholder="Pharmacy name"
                    InputProps={{
                        className: 'input',
                    }}
                    value={pharmacyName}
                    onChange={({ target: { value } }) => setPharmacyName(value)}
                />
            </Grid>
            <Grid item xs={12}>
                <AddressAutoFill
                    placeholder="Pharmacy address"
                    parentCallback={setAddressObject}
                    value={
                        address
                            ? {
                                  ...addressObject,
                                  address,
                              }
                            : undefined
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    placeholder="State"
                    InputProps={{
                        className: 'input',
                    }}
                    value={addressState}
                    onChange={({ target: { value: addressState } }) =>
                        setAddressObject({
                            ...(addressObject || {}),
                            addressState,
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    placeholder="Zip code"
                    fullWidth
                    InputProps={{
                        className: 'input',
                    }}
                    value={addressZipCode}
                    onChange={({ target: { value: addressZipCode } }) =>
                        setAddressObject({
                            ...(addressObject || {}),
                            addressZipCode,
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    placeholder="Who is this for"
                    fullWidth
                    InputProps={{
                        className: 'input',
                    }}
                    value={whoIsThisFor}
                    onChange={({ target: { value } }) => setWhoIsThisFor(value)}
                />
            </Grid>
            <Grid item xs={12}>
                <DatePicker
                    placeholder="Date of birth"
                    onChange={(date) => {
                        if (date) {
                            setDateOfBirth(date.toISOString());
                        } else {
                            setDateOfBirth(undefined);
                        }
                    }}
                    value={dateOfBirth}
                />
            </Grid>
            <Grid container item xs={12} alignItems="center" justify="space-between">
                <Grid>
                    <Button
                        disabled={!prescriptionInfo}
                        variant="contained"
                        color="primary"
                        className="submit-btn"
                        onClick={() => {
                            if (prescriptionInfo) {
                                onNextStepClicked(prescriptionInfo);
                            }
                        }}
                    >
                        Next
                    </Button>
                </Grid>
                <Grid item>
                    <Link className="sign-up__go-back-btn" variant="inherit" onClick={onSkipStep}>
                        Skip
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PrescriptionInfoStep;
