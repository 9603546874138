import React, { useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import './SaveChanges.scss';

interface Props {
    action: any;
    closeModal: () => void;
    closeParentModal: () => void;
}

const SaveChanges = (props: Props) => {
    const { action, closeModal, closeParentModal } = props;
    const [state, setState] = useState({
        errorMessage: '',
    });

    function save() {
        return action().then(() => {
            closeModal();
            closeParentModal();
        });
    }

    function exitWithoutSaving() {
        closeModal();
        closeParentModal();
    }

    return (
        <Box className="save-changes">
            <Grid container item direction="row" justify="space-between" alignItems="center">
                <Grid item className="save-changes__title">
                    Save Changes
                </Grid>
                <Grid item className="save-changes__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="column"
                justify="flex-start"
                alignItems="center"
                className="save-changes__main-container"
            >
                <Grid item>
                    <Grid item className="save-changes__main-text">
                        Do you want to save your changes?
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                direction="column"
                justify="flex-start"
                alignItems="center"
                className="save-changes__error-message"
            >
                {state.errorMessage}
            </Grid>
            <Grid container item direction="row" justify="flex-end" alignItems="center" className="footer">
                <Grid item>
                    <Button fullWidth variant="outlined" onClick={exitWithoutSaving}>
                        Cancel <span className="save-changes__cancel-additional-text"> without saving</span>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className="save-changes__save-button"
                        onClick={save}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SaveChanges;
