import React from 'react';

import Button from '@material-ui/core/Button';

import './BackButton.scss';

interface Props {
    onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick }) => (
    <Button onClick={onClick} className="back-button">
        Back
    </Button>
);
export default BackButton;
