import { DoctorAppointmentsState, DoctorAppointmentsActionType } from '../types';
import {
    DOCTOR_APPOINTMENTS_CLEAR_EXISTING_CART,
    DOCTOR_APPOINTMENTS_CLEAR_NEW_CART,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_FROM,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_TO,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_HOSPITAL,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_NAME,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_IS_ONLINE_APPOINTMENT,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_FROM,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_TO,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_DOC_HOSPITAL,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_IS_ONLINE_APPOINTMENT,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_SPECIALITY,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS,
    DOCTOR_APPOINTMENTS_REORDER_EXISTING_CART,
    DOCTOR_APPOINTMENTS_REORDER_NEW_CART,
    DOCTOR_APPOINTMENTS_SET_IS_EXISTING_DOCTOR_APPOINTMENT,
} from '../constants';

const INITIAL_CART = {
    isOnlineAppointment: false,
};

const initialState: DoctorAppointmentsState = {
    existingDoctorAppointmentCart: {
        ...INITIAL_CART,
    },
    newDoctorAppointmentCart: {
        ...INITIAL_CART,
    },
    submitOrder: {},
    isExistingDoctorAppointment: true,
};

export default (state = initialState, action: DoctorAppointmentsActionType): DoctorAppointmentsState => {
    const { type } = action;
    type ActionConverter<A> = Extract<DoctorAppointmentsActionType, { type: A }>;

    switch (type) {
        case DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'pending',
                    errorMessage: undefined,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'success',
                    errorMessage: undefined,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR: {
            const {
                data: { errorMessage },
            } = action as ActionConverter<typeof type>;
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'error',
                    errorMessage,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_FROM: {
            const {
                data: { availabilityFrom },
            } = action as ActionConverter<typeof type>;
            const { existingDoctorAppointmentCart } = state;
            return {
                ...state,
                existingDoctorAppointmentCart: {
                    ...existingDoctorAppointmentCart,
                    availabilityFrom,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_TO: {
            const {
                data: { availabilityTo },
            } = action as ActionConverter<typeof type>;
            const { existingDoctorAppointmentCart } = state;
            return {
                ...state,
                existingDoctorAppointmentCart: {
                    ...existingDoctorAppointmentCart,
                    availabilityTo,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_HOSPITAL: {
            const {
                data: { docHospital },
            } = action as ActionConverter<typeof type>;
            const { existingDoctorAppointmentCart } = state;
            return {
                ...state,
                existingDoctorAppointmentCart: {
                    ...existingDoctorAppointmentCart,
                    docHospital,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_NAME: {
            const {
                data: { docName },
            } = action as ActionConverter<typeof type>;
            const { existingDoctorAppointmentCart } = state;
            return {
                ...state,
                existingDoctorAppointmentCart: {
                    ...existingDoctorAppointmentCart,
                    docName,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_EXISTING_CART_SET_IS_ONLINE_APPOINTMENT: {
            const {
                data: { isOnlineAppointment },
            } = action as ActionConverter<typeof type>;
            const { existingDoctorAppointmentCart } = state;
            return {
                ...state,
                existingDoctorAppointmentCart: {
                    ...existingDoctorAppointmentCart,
                    isOnlineAppointment,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_FROM: {
            const {
                data: { availabilityFrom },
            } = action as ActionConverter<typeof type>;
            const { newDoctorAppointmentCart } = state;
            return {
                ...state,
                newDoctorAppointmentCart: {
                    ...newDoctorAppointmentCart,
                    availabilityFrom,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_TO: {
            const {
                data: { availabilityTo },
            } = action as ActionConverter<typeof type>;
            const { newDoctorAppointmentCart } = state;
            return {
                ...state,
                newDoctorAppointmentCart: {
                    ...newDoctorAppointmentCart,
                    availabilityTo,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_NEW_CART_SET_DOC_HOSPITAL: {
            const {
                data: { docHospital },
            } = action as ActionConverter<typeof type>;
            const { newDoctorAppointmentCart } = state;
            return {
                ...state,
                newDoctorAppointmentCart: {
                    ...newDoctorAppointmentCart,
                    docHospital,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_NEW_CART_SET_SPECIALITY: {
            const {
                data: { speciality },
            } = action as ActionConverter<typeof type>;
            const { newDoctorAppointmentCart } = state;
            return {
                ...state,
                newDoctorAppointmentCart: {
                    ...newDoctorAppointmentCart,
                    speciality,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_NEW_CART_SET_IS_ONLINE_APPOINTMENT: {
            const {
                data: { isOnlineAppointment },
            } = action as ActionConverter<typeof type>;
            const { newDoctorAppointmentCart } = state;
            return {
                ...state,
                newDoctorAppointmentCart: {
                    ...newDoctorAppointmentCart,
                    isOnlineAppointment,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_SET_IS_EXISTING_DOCTOR_APPOINTMENT: {
            const {
                data: { isExistingDoctorAppointment },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                isExistingDoctorAppointment,
            };
        }
        case DOCTOR_APPOINTMENTS_CLEAR_NEW_CART: {
            return {
                ...state,
                newDoctorAppointmentCart: {
                    ...INITIAL_CART,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_CLEAR_EXISTING_CART: {
            return {
                ...state,
                existingDoctorAppointmentCart: {
                    ...INITIAL_CART,
                },
            };
        }
        case DOCTOR_APPOINTMENTS_REORDER_EXISTING_CART: {
            const {
                data: { existingDoctorAppointmentCart },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                existingDoctorAppointmentCart,
            };
        }
        case DOCTOR_APPOINTMENTS_REORDER_NEW_CART: {
            const {
                data: { newDoctorAppointmentCart },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                newDoctorAppointmentCart,
            };
        }

        default:
            return state;
    }
};
