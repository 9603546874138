import React, { useState, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Typography } from '@material-ui/core';

import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import { DispatchType } from '../../state';

import { addGroceryProductToCartAction } from '../../state/actions/groceriesActions';

import { GridStringInputItem, GridNumberInputItem, GridIntegerInputItem } from '../GridInputItem';

import './CustomGroceryItemDialog.scss';

const mapDispatch = (dispatch: DispatchType) => ({
    addGroceryProductToCart: addGroceryProductToCartAction(dispatch),
});

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    isDialogOpen: boolean;
    onCloseButtonPressed: () => void;
}

const CustomGroceryItemDialog: React.FC<Props & PropsFromRedux> = ({
    isDialogOpen,
    onCloseButtonPressed,
    addGroceryProductToCart,
}) => {
    const [itemName, setItemName] = useState<string>();
    const [pricePerUnit, setPricePerUnit] = useState<number>();
    const [count, setCount] = useState<number>();

    const total = count && pricePerUnit ? count * pricePerUnit : 0;

    return (
        <Dialog open={isDialogOpen}>
            <DialogTitle>Add grocery item</DialogTitle>
            <DialogContent>
                <Grid container>
                    <GridStringInputItem placeholder="Item name" setValue={setItemName} value={itemName} xs={12} />
                    <GridNumberInputItem
                        placeholder="Price per unit"
                        setValue={setPricePerUnit}
                        value={pricePerUnit}
                        xs={6}
                    />
                    <GridIntegerInputItem placeholder="Quantity" setValue={setCount} value={count} xs={6} />
                    <Grid container item xs={12} justify="flex-end">
                        <Typography component="p" className="CustomGroceryItemDialog__total">
                            ${total.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseButtonPressed} color="secondary">
                    Cancel
                </Button>
                <Button
                    disabled={!(itemName && pricePerUnit && count)}
                    onClick={() => {
                        if (itemName && pricePerUnit && count) {
                            addGroceryProductToCart(
                                {
                                    category: 'custom',
                                    customAddedItem: true,
                                    id: uuidv4(),
                                    name: itemName,
                                    unit_price: pricePerUnit,
                                },
                                count
                            );
                            onCloseButtonPressed();
                            setItemName(undefined);
                            setPricePerUnit(undefined);
                            setCount(undefined);
                        }
                    }}
                    color="primary"
                    autoFocus
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connector(CustomGroceryItemDialog);
