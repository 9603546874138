import React, { useContext, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import moment from 'moment';

import TimePicker from '../TimePicker';

import AddressSearchBar from '../AddressSearchBar/AddressSearchBar';
import MedsButtonGroup from '../MedsButtonGroup/MedsButtonGroup';
import RequestSubmittedModal from '../RequestSubmittedModal';
import Switch from '../Switch';

import { ApisContext } from '../../lib/contexts';
import {
    IApiAddress,
    ICommonDoctorAppointmentCart,
    IDoctorAppointmentOrder,
    EXISTING_DOCTOR_APPOINTMENT_REQUEST,
    NEW_DOCTOR_APPOINTMENT_REQUEST,
} from '../../lib/types';
import { DispatchType, RootState } from '../../state';
import {
    clearDoctorAppointmentExistingCartAction,
    clearDoctorAppointmentNewCartAction,
    setDoctorAppointmentIsExistingDoctorAppointmentAction,
    setExistingDoctorAppointmentCartAvailabilityFromAction,
    setExistingDoctorAppointmentCartAvailabilityToAction,
    setExistingDoctorAppointmentCartDocHospitalAction,
    setExistingDoctorAppointmentCartDocNameAction,
    setExistingDoctorAppointmentCartIsOnlineAppointmentAction,
    setNewDoctorAppointmentCartAvailabilityFromAction,
    setNewDoctorAppointmentCartAvailabilityToAction,
    setNewDoctorAppointmentCartDocHospitalAction,
    setNewDoctorAppointmentCartIsOnlineAppointmentAction,
    setNewDoctorAppointmentCartSpecialityAction,
    submitDoctorAppointmentsOrderAction,
} from '../../state/actions/doctorAppointmentsActions';
import Card from '../Card/Card';

import MdSpecialitiesDropdown from './MdSpecialitiesDropdown';

const mapState = ({
    doctorAppointments: {
        existingDoctorAppointmentCart,
        isExistingDoctorAppointment,
        newDoctorAppointmentCart,
        submitOrder: { state: submitOrderState, errorMessage: submitOrderErrorMessage },
    },
    user: { loggedInUser },
}: RootState) => ({
    existingDoctorAppointmentCart,
    isExistingDoctorAppointment,
    newDoctorAppointmentCart,
    submitOrderErrorMessage,
    submitOrderState,
    userId: loggedInUser?.userId,
});

const mapDispatch = (dispatch: DispatchType) => ({
    clearDoctorAppointmentExistingCart: clearDoctorAppointmentExistingCartAction(dispatch),
    clearDoctorAppointmentNewCart: clearDoctorAppointmentNewCartAction(dispatch),
    setExistingDoctorAppointmentCartAvailabilityFrom: setExistingDoctorAppointmentCartAvailabilityFromAction(dispatch),
    setExistingDoctorAppointmentCartAvailabilityTo: setExistingDoctorAppointmentCartAvailabilityToAction(dispatch),
    setExistingDoctorAppointmentCartDocHospital: setExistingDoctorAppointmentCartDocHospitalAction(dispatch),
    setExistingDoctorAppointmentCartDocName: setExistingDoctorAppointmentCartDocNameAction(dispatch),
    setExistingDoctorAppointmentCartIsOnlineAppointment: setExistingDoctorAppointmentCartIsOnlineAppointmentAction(
        dispatch
    ),
    setNewDoctorAppointmentCartAvailabilityFrom: setNewDoctorAppointmentCartAvailabilityFromAction(dispatch),
    setNewDoctorAppointmentCartAvailabilityTo: setNewDoctorAppointmentCartAvailabilityToAction(dispatch),
    setNewDoctorAppointmentCartDocHospital: setNewDoctorAppointmentCartDocHospitalAction(dispatch),
    setNewDoctorAppointmentCartIsOnlineAppointment: setNewDoctorAppointmentCartIsOnlineAppointmentAction(dispatch),
    setNewDoctorAppointmentCartSpeciality: setNewDoctorAppointmentCartSpecialityAction(dispatch),
    submitOrder: submitDoctorAppointmentsOrderAction(dispatch),
    setDoctorAppointmentIsExistingDoctorAppointment: setDoctorAppointmentIsExistingDoctorAppointmentAction(dispatch),
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapState, mapDispatch);

interface IState {
    errorMessage?: string;
    step: number;
    validData: boolean;
}

const DOCTOR_TYPES = ['Existing doctor', 'New doctor'];

const DoctorAppointmentsService: React.FC<PropsFromRedux> = ({
    clearDoctorAppointmentExistingCart,
    clearDoctorAppointmentNewCart,
    existingDoctorAppointmentCart,
    isExistingDoctorAppointment,
    newDoctorAppointmentCart,
    setDoctorAppointmentIsExistingDoctorAppointment,
    setExistingDoctorAppointmentCartAvailabilityFrom,
    setExistingDoctorAppointmentCartAvailabilityTo,
    setExistingDoctorAppointmentCartDocHospital,
    setExistingDoctorAppointmentCartDocName,
    setExistingDoctorAppointmentCartIsOnlineAppointment,
    setNewDoctorAppointmentCartAvailabilityFrom,
    setNewDoctorAppointmentCartAvailabilityTo,
    setNewDoctorAppointmentCartDocHospital,
    setNewDoctorAppointmentCartIsOnlineAppointment,
    setNewDoctorAppointmentCartSpeciality,
    submitOrder,
    submitOrderErrorMessage,
    userId,
}) => {
    const { doctorAppointmentsApi } = useContext(ApisContext);
    const steps: string[] = ['1', '2'];

    const [state, setState] = useState<IState>({
        step: 0,
        validData: false,
    });

    useEffect(
        () =>
            setState((prevState) => ({
                ...prevState,
                errorMessage: submitOrderErrorMessage,
            })),
        [submitOrderErrorMessage]
    );

    const lastStep = state.step === steps.length - 1;

    function nextStep() {
        setState((prevState) => {
            // const step = lastStep ? prevState.step : prevState.step +=1;    // stay at end step
            const step = lastStep ? 0 : prevState.step; // restart
            return { ...prevState, errorMessage: undefined, step };
        });
    }

    let availabilityFrom: string | undefined;
    let availabilityTo: string | undefined;
    let docHospital: IApiAddress | undefined;
    let isOnlineAppointment: boolean;
    let docName: string | undefined;
    let speciality: string | undefined;
    let setDocHospital: (value: IApiAddress | undefined) => void;
    let setAvailabilityFrom: (value: string | undefined) => void;
    let setAvailabilityTo: (value: string | undefined) => void;
    let setIsOnlineAppointment: (value: boolean) => void;
    if (isExistingDoctorAppointment) {
        ({
            availabilityFrom,
            availabilityTo,
            docHospital,
            isOnlineAppointment,
            docName,
        } = existingDoctorAppointmentCart);
        setDocHospital = setExistingDoctorAppointmentCartDocHospital;
        setAvailabilityFrom = setExistingDoctorAppointmentCartAvailabilityFrom;
        setAvailabilityTo = setExistingDoctorAppointmentCartAvailabilityTo;
        setIsOnlineAppointment = setExistingDoctorAppointmentCartIsOnlineAppointment;
    } else {
        ({ availabilityFrom, availabilityTo, docHospital, isOnlineAppointment, speciality } = newDoctorAppointmentCart);
        setDocHospital = setNewDoctorAppointmentCartDocHospital;
        setAvailabilityFrom = setNewDoctorAppointmentCartAvailabilityFrom;
        setAvailabilityTo = setNewDoctorAppointmentCartAvailabilityTo;
        setIsOnlineAppointment = setNewDoctorAppointmentCartIsOnlineAppointment;
    }

    const { step: currentStep } = state;

    const validateAvailability = (
        availabilityFrom: string | undefined,
        availabilityTo: string | undefined,
        updateState: boolean = false
    ) => {
        let errorMessage: string | undefined = undefined;
        if (availabilityFrom && availabilityTo && currentStep === 0) {
            if (moment(availabilityFrom, 'LT') > moment(availabilityTo, 'LT')) {
                errorMessage = 'Availability from has to be before availability to';
            }
        }
        if (updateState) {
            setState({
                ...state,
                errorMessage,
            });
        }
        return !errorMessage;
    };

    const submitOrderCallback = async () => {
        if (!userId) {
            throw new Error('Missing userId');
        }

        const orderCommon: ICommonDoctorAppointmentCart = {
            availabilityFrom,
            availabilityTo,
            docHospital,
            isOnlineAppointment,
        };

        let order: IDoctorAppointmentOrder;
        if (isExistingDoctorAppointment) {
            if (!docName) {
                setState({ ...state, errorMessage: 'Doctor name is required' });
                return;
            }
            order = {
                type: EXISTING_DOCTOR_APPOINTMENT_REQUEST,
                cart: {
                    ...orderCommon,
                    docName,
                },
            };
        } else {
            if (!speciality) {
                setState({ ...state, errorMessage: 'Speciality is required' });
                return;
            }
            order = {
                type: NEW_DOCTOR_APPOINTMENT_REQUEST,
                cart: {
                    ...orderCommon,
                    speciality,
                },
            };
        }

        setState({ ...state, errorMessage: undefined });

        if (
            await submitOrder(doctorAppointmentsApi, {
                order,
                userId,
            })
        ) {
            if (isExistingDoctorAppointment) {
                clearDoctorAppointmentExistingCart();
            } else {
                clearDoctorAppointmentNewCart();
            }
            const step = currentStep + 1;
            setState({
                ...state,
                errorMessage: undefined,
                step,
            });
        }
    };

    const isSubmitButtonDisabled = () => {
        if (!validateAvailability(availabilityFrom, availabilityTo)) {
            return true;
        }
        if (isExistingDoctorAppointment) {
            return !(docName && availabilityFrom && availabilityTo);
        } else {
            return !(speciality && availabilityFrom && availabilityTo);
        }
    };

    const { errorMessage } = state;

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <Box className="module">
                    <Box className="module-top">
                        <h4>Doctor appointments</h4>
                    </Box>

                    {/* based on step */}
                    {state.step === 0 && (
                        <>
                            <Box className="module-fields">
                                <MedsButtonGroup
                                    value={isExistingDoctorAppointment ? DOCTOR_TYPES[0] : DOCTOR_TYPES[1]}
                                    options={DOCTOR_TYPES}
                                    onChange={(docApptType) =>
                                        setDoctorAppointmentIsExistingDoctorAppointment(docApptType === DOCTOR_TYPES[0])
                                    }
                                />
                                <Box className="form-group">
                                    {isExistingDoctorAppointment ? (
                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            placeholder="Write doctor's name in proper spelling"
                                            value={docName}
                                            onChange={({ target: { value } }) =>
                                                setExistingDoctorAppointmentCartDocName(value)
                                            }
                                        />
                                    ) : (
                                        <MdSpecialitiesDropdown
                                            speciality={speciality}
                                            setSpeciality={(speciality) =>
                                                speciality
                                                    ? setNewDoctorAppointmentCartSpeciality(speciality)
                                                    : undefined
                                            }
                                        />
                                    )}
                                </Box>
                                <Box>
                                    <Box className="form-group">
                                        <AddressSearchBar
                                            parentCallback={setDocHospital}
                                            placeholder="Optional step: type hospital address"
                                            value={docHospital}
                                        />
                                    </Box>
                                    <Grid container className="form-group btn-group w-100">
                                        <Grid item container xs={4} alignContent="center">
                                            <Typography>Availability</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TimePicker
                                                placeholder="From"
                                                value={availabilityFrom ? moment(availabilityFrom, 'LT') : undefined}
                                                onChange={(value) => {
                                                    const valueAsString = value?.format('LT');
                                                    setAvailabilityFrom(valueAsString);
                                                    validateAvailability(valueAsString, availabilityTo, true);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TimePicker
                                                placeholder="To"
                                                value={availabilityTo ? moment(availabilityTo, 'LT') : undefined}
                                                onChange={(value) => {
                                                    const valueAsString = value?.format('LT');
                                                    setAvailabilityTo(valueAsString);
                                                    validateAvailability(availabilityFrom, valueAsString, true);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box className="form-group">
                                        <Switch
                                            checked={isOnlineAppointment}
                                            label="Online consultation"
                                            onChange={() => setIsOnlineAppointment(!isOnlineAppointment)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="module-bot">
                                <Grid container justify="space-between">
                                    <Grid item xs={6} sm={4}>
                                        <Button
                                            disabled={isSubmitButtonDisabled()}
                                            disableRipple
                                            className="dashboard-btn"
                                            onClick={submitOrderCallback}
                                        >
                                            Book
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className="module-bot-right">
                                        <p hidden={!!state.errorMessage}>
                                            We will contact the hospital to schedule an appointment with the doctor /
                                            speciality you specified
                                        </p>
                                        <Typography
                                            hidden={!state.errorMessage}
                                            color={'error'}
                                            component="h1"
                                            variant="subtitle1"
                                        >
                                            {state.errorMessage}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    <RequestSubmittedModal
                        headerImage="img/doctor-appointment-request-submitted-modal-bg.png"
                        nextSteps={({ phoneNumber }) => [
                            'We will search for reliable hospitals to connect you with a top-rated doctor. If you chose a particular hospital or doctor, we will reach out to them.',
                            `We will text you on ${phoneNumber} with available appointments with details including appointment date and location for your confirmation.`,
                            'You will send us a confirmation via text before booking your appointment.',
                        ]}
                        onCloseModal={() => nextStep()}
                        showModal={state.step === 1}
                        title="Your appointment booking was requested!"
                    />
                </Box>
            </Card>
        </Grid>
    );
};

export default connector(DoctorAppointmentsService);
