import { PaymentMethod } from '@stripe/stripe-js';

export interface IApiAddress {
    readonly address?: string;
    readonly latitude?: number;
    readonly longitude?: number;
}

export interface IGroceryProduct {
    available?: number;
    category: string;
    customAddedItem?: boolean;
    id: string;
    image?: string;
    name_long?: string;
    name: string;
    unit_price: number;
    units?: string;
}

export interface IGroceryCartItem {
    product: IGroceryProduct;
    count: number;
}

export type IGroceryCart = Partial<Record<string, IGroceryCartItem>>;

export interface IGroceryOrder {
    items: IGroceryCartItem[];
    itemCount: number;
    sumAmount: string;
}

export const PRESCRIPTION_MEDICATION = 'PRESCRIPTION_MEDICATION';

export interface IPrescriptionMedicationItem {
    type: typeof PRESCRIPTION_MEDICATION;
    pharmacyAddress: IApiAddress;
    deliveryAddress: IApiAddress;
}

export type IPrescriptionMedicationCart = IPrescriptionMedicationItem[];

export const OVER_THE_COUNTER_MEDICATION = 'OVER_THE_COUNTER_MEDICATION';

export type IMedicationOrderItem = IPrescriptionMedicationItem | IOverTheCounterMedicationItem;

export type IMedicationOrder = {
    items: IMedicationOrderItem[];
};

export interface IOverTheCounterMedicationItem {
    type: typeof OVER_THE_COUNTER_MEDICATION;
    drugNameOrBrand: string;
    description: string;
    deliveryAddress: IApiAddress;
    pharmacyName?: string;
}

export type IOverTheCounterMedicationCart = IOverTheCounterMedicationItem[];

export interface ICaregiverVisitCart {
    deliveryAddress?: IApiAddress;
    endDate?: string; // Date.toISOString
    instructions?: string;
    startDate?: string; // Date.toISOString
}

export interface ICaregiverVisitOrder {
    caregiverVisitCart: Required<Omit<ICaregiverVisitCart, 'instructions'>> & Pick<ICaregiverVisitCart, 'instructions'>;
}

export interface ICommonDoctorAppointmentCart {
    availabilityFrom?: string;
    availabilityTo?: string;
    docHospital?: IApiAddress;
    isOnlineAppointment: boolean;
}

export interface IExistingDoctorAppointmentCart extends ICommonDoctorAppointmentCart {
    docName?: string;
}

export const EXISTING_DOCTOR_APPOINTMENT_REQUEST = 'EXISTING_DOCTOR_APPOINTMENT_REQUEST';

export interface IExistingDoctorAppointmentOrder {
    type: typeof EXISTING_DOCTOR_APPOINTMENT_REQUEST;
    cart: IExistingDoctorAppointmentCart;
}

export interface INewDoctorAppointmentCart extends ICommonDoctorAppointmentCart {
    speciality?: string;
}

export const NEW_DOCTOR_APPOINTMENT_REQUEST = 'NEW_DOCTOR_APPOINTMENT_REQUEST';

export interface INewDoctorAppointmentOrder {
    type: typeof NEW_DOCTOR_APPOINTMENT_REQUEST;
    cart: INewDoctorAppointmentCart;
}

export type OrderTypeString = 'groceries' | 'ride' | 'medication' | 'doctor' | 'caregiver';

export type OrderStatusString = 'created' | 'fulfilled';

export interface IOrderHistoryItemCommon {
    orderDate: Date;
    orderStatus: OrderStatusString;
    orderBy: string;
}

export const GROCERY_ORDER_TYPE = 'groceries';

export interface IGroceryOrderHistoryItem extends IOrderHistoryItemCommon {
    orderType: typeof GROCERY_ORDER_TYPE;
    order: IGroceryOrder;
}

export const RIDE_ORDER_TYPE = 'ride';

export interface IRideOrderHistoryItem extends IOrderHistoryItemCommon {
    orderType: typeof RIDE_ORDER_TYPE;
    order: IRideOrder;
}

export const MEDICATION_ORDER_TYPE = 'medication';

export interface IMedicationOrderHistoryItem extends IOrderHistoryItemCommon {
    orderType: typeof MEDICATION_ORDER_TYPE;
    order: IMedicationOrder;
}

export const DOCTOR_ORDER_TYPE = 'doctor';

export type IDoctorAppointmentOrder = IExistingDoctorAppointmentOrder | INewDoctorAppointmentOrder;

export interface IDoctorOrderHistoryItem extends IOrderHistoryItemCommon {
    orderType: typeof DOCTOR_ORDER_TYPE;
    order: IDoctorAppointmentOrder;
}

export const CAREGIVER_ORDER_TYPE = 'caregiver';

export interface ICaregiverOrderHistoryItem extends IOrderHistoryItemCommon {
    orderType: typeof CAREGIVER_ORDER_TYPE;
    order: ICaregiverVisitOrder;
}

export type IOrderHistoryItem =
    | ICaregiverOrderHistoryItem
    | IDoctorOrderHistoryItem
    | IGroceryOrderHistoryItem
    | IMedicationOrderHistoryItem
    | IRideOrderHistoryItem;

export enum RideType {
    ASSIST = 'Uber assist',
    UBERX = 'UberX',
    POOL = 'UberPool',
}

export interface IRideCart {
    ridesPickup?: IApiAddress;
    ridesDropoff?: IApiAddress;
    ridesService: RideType;
}

export interface IRideOrder {
    rideCart: Required<IRideCart>;
}

// Supported card brands by stripe
export enum CardBrand {
    amex = 'amex',
    cartes_bancaires = 'cartes_bancaires',
    diners_club = 'diners_club',
    discover = 'discover',
    jcb = 'jcb',
    mastercard = 'mastercard',
    visa = 'visa',
    unionpay = 'unionpay',
}

export interface IBillingAddress {
    secondAddressLine?: string;
    state: string;
    streetAddress: string;
    zipCode: string;
}

export interface IPaymentSource {
    active: boolean;
    billingAddress?: IBillingAddress;
    cardHolderName: string;
    id: string;
    paymentMethodId: string;
    stripePaymentMethod: PaymentMethod;
}

export type IPaymentSourceParams = Omit<IPaymentSource, 'id' | 'active'>;

export interface IInsuranceProviderMetaData {
    id: string;
    logo: string;
    name: string;
}

export interface IInsuranceProvider {
    backOfCard?: string;
    frontOfCard?: string;
    id: string;
    metaData?: IInsuranceProviderMetaData;
    rxBin: string;
    rxGroup: string;
    rxGroupNo?: string; // TODO is this required?
    rxMemberID: string;
    rxPCN: string;
    rxRelationshipToSubscriber: string;
    rxSubscriber: string;
}

export type IInsuranceProviderParams = Partial<Omit<IInsuranceProvider, 'id'>>;

export type HomeAddressFields = {
    address: string;
    addressNickname?: string;
    addressState?: string;
    addressZipCode?: string;
    id: string;
    placesObject?: google.maps.GeocoderResult;
    secondAddressLine?: string;
};

export type HomeAddressType = Readonly<HomeAddressFields>;

export type HomeAddressParams = Partial<Omit<HomeAddressFields, 'id'>>;

export type AddressAutoFillObjectType = {
    address: string;
    addressZipCode?: string;
    addressState?: string;
    placesObject?: google.maps.GeocoderResult;
};

export type AddressAutoFillCallback = (addressObject: AddressAutoFillObjectType | undefined) => void;

export interface IPrescriptionInfo {
    addressObject: AddressAutoFillObjectType;
    dateOfBirth: string;
    pharmacyName: string;
    whoIsThisFor: string;
}
