import React, { useState } from 'react';
import queryString from 'query-string';
import { History } from 'history';
import { Button, TextField, Grid, Typography, CssBaseline } from '@material-ui/core';
import PanelWithImage from '../PanelWithImage/PanelWithImage';
import logo from '../../../assets/images/logo.png';

declare global {
    interface Window {
        location: Location;
    }
}

interface Props {
    history: History;
}

function ForgotPassword(props: Props) {
    const urlParams = queryString.parse(window.location.search);
    const accessToken = urlParams.access_token;

    const [state, setState] = useState({
        password: '',
        passwordConfirm: '',
        errorMessage: '',
    });

    function resetPassword() {
        if (state.password !== state.passwordConfirm) {
            setState((prevState) => {
                return {
                    ...prevState,
                    errorMessage: '"New password" and "Confirm new password" should be equal',
                };
            });
        }

        return fetch(`baseURL/api/users/reset-password?access_token=${accessToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                newPassword: state.password,
            }),
        })
            .then((res) => {
                if (res.status === 404) {
                    setState((prevState) => {
                        return { ...prevState, errorMessage: 'Email not found' };
                    });
                } else if (res.status === 401) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            errorMessage: 'Token to reset password has expired',
                        };
                    });
                } else {
                    props.history.push('/login');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <Grid container className="root">
            <CssBaseline />
            <PanelWithImage />
            <Grid item className="right-panel-container" xs={12} md={6}>
                <div className="right-panel">
                    <div className="header">
                        <div>
                            <img className="logo" src={logo} alt="CAN logo" />
                        </div>
                        <Typography component="h1" variant="h5">
                            Reset password
                        </Typography>
                    </div>
                    <form
                        className="form"
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="password"
                                    label="New password"
                                    name="password"
                                    InputProps={{
                                        className: 'input',
                                    }}
                                    value={state.password}
                                    onChange={(event) => {
                                        const password = event.target.value;
                                        return setState((prevState) => {
                                            return { ...prevState, password };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="passwordConfirm"
                                    label="Confirm new password"
                                    name="passwordConfirm"
                                    InputProps={{
                                        className: 'input',
                                    }}
                                    value={state.passwordConfirm}
                                    onChange={(event) => {
                                        const passwordConfirm = event.target.value;
                                        return setState((prevState) => {
                                            return { ...prevState, passwordConfirm };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item className="error-message-container">
                                <Typography className="error-message" component="h1" variant="subtitle1">
                                    {state.errorMessage}
                                </Typography>
                            </Grid>
                            <Grid item className="gray-text">
                                Password must be at least 6 characters long and contain at least 1 uppercase letter
                            </Grid>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="submit-btn"
                                onClick={() => resetPassword()}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default ForgotPassword;
