import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import classNames from 'classnames';
import OrderingForButton from '../../components/OrderingForButton';
import Nav, { MenuItem } from './Nav';

// styles
import './MainLayout.scss';

interface Props {
    readonly activeMenuItem: MenuItem;
    readonly onMenuItemChange: (menuItem: MenuItem) => void;
    readonly pageTitle: string;
    readonly pageText?: string;
    readonly sidebarActive: boolean;
    readonly toggleSidebar: () => void;
}

const MainLayout: React.FC<Props> = ({
    activeMenuItem,
    children,
    onMenuItemChange,
    pageTitle,
    pageText,
    sidebarActive,
    toggleSidebar,
}) => {
    const draweClasses = classNames('main-layout__drawer', {
        active: sidebarActive,
    });

    return (
        <Box>
            <Box className="main-layout">
                <Box className={draweClasses}>
                    <Nav activeMenuItem={activeMenuItem} onMenuItemChange={onMenuItemChange} />
                </Box>
                <Box
                    id="content"
                    className="main-layout__content"
                    style={{
                        width: '100%',
                    }}
                >
                    <Box className="content-coll-bar">
                        <ul>
                            <span className="clickable-sidebar-left sidebarCollapse" />
                            <li
                                data-toggle="collapse"
                                data-target="#Sub1"
                                className="clickable-sidebar-left sidebarCollapse"
                            >
                                <MenuIcon className="zmdi zmdi-menu arrow_show" onClick={toggleSidebar}></MenuIcon>
                            </li>
                        </ul>
                    </Box>
                    <Box mb={4}>
                        <Grid container>
                            <Grid item xs={9}>
                                <h2>{pageTitle}</h2>
                                <p>{pageText}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <OrderingForButton />
                            </Grid>
                        </Grid>
                    </Box>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default MainLayout;
