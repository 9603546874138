import React, { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import _ from 'lodash';
import ReactPixel from 'react-facebook-pixel';
import { Grid } from '@material-ui/core';

import { RootState, DispatchType } from '../../state';

import CaregiverVisitsService from '../../components/CaregiverVisitsService/CaregiverVisitsService';
import DoctorAppointmentsService from '../../components/DoctorAppointmentsService/DoctorAppointmentsService';
import GroceriesService from '../../components/GroceriesService/GroceriesService';
import MedicationService from '../../components/MedicationService/MedicationService';
import RidesService from '../../components/RidesService/RidesService';

// styles
import './dashboard.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const {
            userInfo: { hideServices },
        } = loggedInUser;
        return { hideServices };
    }
    return {};
};

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    user: any;
    refresh: () => void;
}

declare global {
    interface Window {
        Intercom: any;
        intercomSettings: any;
    }
}

const Dashboard: React.FC<Props & PropsFromRedux> = ({ refresh, user, hideServices }) => {
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = useState({
        openAddDevice: false,
        myDevices: null,
        caregierDevices: null,
        sidebarActive: true,
    });

    const myDevices: any[] = state.myDevices || [];
    const caregierDevices: any[] = state.caregierDevices || [];

    let counter = 0;

    _.forEach(user.userTo, (device) => {
        counter += 1;
        let { deviceName } = device;

        if (!deviceName) {
            deviceName = `My Device ${counter}`;
        }

        let mainCaregiver = null;
        const caregiversOfDevice = _.filter(user.caregivers, (caregiver) => {
            if (caregiver.user === user.emergencyContact) {
                mainCaregiver = caregiver;
            }

            return caregiver.device === device.deviceId;
        });

        myDevices.push({
            id: device.deviceId,
            caregivers: caregiversOfDevice,
            mainCaregiver,
            name: deviceName,
            image: device.imageUrl,
        });
    });

    counter = 0;

    _.forEach(user.caregiverTo, (device) => {
        counter += 1;
        let { deviceName } = device;

        if (!deviceName) {
            deviceName = `Device ${counter}`;
        }

        caregierDevices.push({
            id: device.device,
            user: device.user,
            name: deviceName,
            image: device.imageUrl,
        });
    });

    if (user && user.userInfo && user.userInfo.firstName && process.env.REACT_APP_FACEBOOKPIXEL) {
        ReactPixel.track('ViewContent', {
            content_name: 'dashboard',
            content_type: 'page',
        });
    }

    const { hideGroceries, hideMedications, hideRides, hideCaregiverVisits, hideDoctorAppointments } =
        hideServices || {};

    return (
        <Grid container spacing={2} className="dashboard-container">
            {!hideGroceries && <GroceriesService />}
            {!hideRides && <RidesService refresh={refresh}></RidesService>}
            {!hideMedications && <MedicationService />}
            {!hideDoctorAppointments && <DoctorAppointmentsService />}
            {!hideCaregiverVisits && <CaregiverVisitsService refresh={refresh} />}
        </Grid>
    );
};

export default connector(Dashboard);
