import React from 'react';
import { Box } from '@material-ui/core';

// styles
import './styles.scss';

type Props = { children?: React.ReactNode };

const Card = ({ children }: Props): JSX.Element => {
    return (
        <Box className="card">
            <Box className="card__content">{children}</Box>
        </Box>
    );
};

export default Card;
