import React from 'react';

import Grid from '@material-ui/core/Grid';

import moment from 'moment';

import { ICaregiverVisitOrder } from '../../lib/types';

interface Props {
    order: ICaregiverVisitOrder;
}

const first_column_width = 2;
const second_column_width = 10;
const spacing = 2;

const CaregiverVisitCartList: React.FC<Props> = ({
    order: {
        caregiverVisitCart: { deliveryAddress, startDate, endDate, instructions },
    },
}) => (
    <Grid container spacing={spacing}>
        <Grid container item spacing={spacing}>
            <Grid item xs={first_column_width}>
                Date:
            </Grid>
            <Grid item xs={second_column_width}>
                {moment(startDate).format('L')}
            </Grid>
        </Grid>
        <Grid container item spacing={spacing}>
            <Grid item xs={first_column_width}>
                Time:
            </Grid>
            <Grid item xs={second_column_width}>{`${moment(startDate).format('LT')} - ${moment(endDate).format(
                'LT'
            )}`}</Grid>
        </Grid>
        <Grid container item spacing={spacing}>
            <Grid item xs={first_column_width}>
                Address:
            </Grid>
            <Grid item xs={second_column_width}>
                {deliveryAddress.address}
            </Grid>
        </Grid>
        {instructions ? (
            <Grid container item spacing={spacing}>
                <Grid item xs={first_column_width}>
                    Instructions:
                </Grid>
                <Grid item xs={second_column_width}>
                    {instructions}
                </Grid>
            </Grid>
        ) : null}
    </Grid>
);

export default CaregiverVisitCartList;
