import React from 'react';
import { Box } from '@material-ui/core';
import AddressesCarousel from './AddressesCarousel/AddressesCarousel';
import ServicesCarousel from './ServicesCarousel/ServicesCarousel';
import PaymentsCarousel from './PaymentCarousel/PaymentCarousel';
import InsurancesCarousel from './InsurancesCarousel/InsurancesCarousel';
// styles
import './styles.scss';

const Settings: React.FC = () => {
    return (
        <Box className="settings-container">
            <Box className="service-box">
                <h3 className="service-box__title">Services</h3>
                <p className="service-box__text">Add or remove services from your dashboard</p>

                <ServicesCarousel />
            </Box>

            <Box className="service-box">
                <h3 className="service-box__title">Addresses</h3>
                <p className="service-box__text">Add or remove delivery addresses</p>

                <AddressesCarousel />
            </Box>

            <Box className="service-box">
                <h3 className="service-box__title">Payment</h3>
                <p className="service-box__text">Update payment information</p>

                <PaymentsCarousel />
            </Box>

            <Box className="service-box">
                <h3 className="service-box__title">Insurance</h3>
                <p className="service-box__text">View and change insurance information</p>

                <InsurancesCarousel />
            </Box>
        </Box>
    );
};

export default Settings;
