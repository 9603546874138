import { RideType } from '../../lib/types';

import { RidesState, RidesActionType } from '../types';
import {
    RIDES_CLEAR_CART,
    RIDES_ORDER_SUBMIT_ERROR,
    RIDES_ORDER_SUBMIT_PENDING,
    RIDES_ORDER_SUBMIT_SUCCESS,
    RIDES_REORDER_CART,
    RIDES_SET_CART_DROPOFF,
    RIDES_SET_CART_PICKUP,
    RIDES_SET_CART_SERVICE,
} from '../constants';

const INITIAL_RIDE_CART = {
    ridesService: RideType.UBERX,
};

const initialState: RidesState = {
    submitOrder: {},
    rideCart: {
        ...INITIAL_RIDE_CART,
    },
};

export default (state = initialState, action: RidesActionType): RidesState => {
    const { type } = action;
    type ActionConverter<A> = Extract<RidesActionType, { type: A }>;

    switch (type) {
        case RIDES_ORDER_SUBMIT_PENDING: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'pending',
                    errorMessage: undefined,
                },
            };
        }
        case RIDES_ORDER_SUBMIT_SUCCESS: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'success',
                    errorMessage: undefined,
                },
            };
        }
        case RIDES_ORDER_SUBMIT_ERROR: {
            const {
                data: { errorMessage },
            } = action as ActionConverter<typeof type>;
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'error',
                    errorMessage,
                },
            };
        }
        case RIDES_SET_CART_PICKUP: {
            const {
                data: { ridesPickup },
            } = action as ActionConverter<typeof type>;
            const { rideCart } = state;
            return {
                ...state,
                rideCart: {
                    ...rideCart,
                    ridesPickup,
                },
            };
        }
        case RIDES_SET_CART_DROPOFF: {
            const {
                data: { ridesDropoff },
            } = action as ActionConverter<typeof type>;
            const { rideCart } = state;
            return {
                ...state,
                rideCart: {
                    ...rideCart,
                    ridesDropoff,
                },
            };
        }
        case RIDES_SET_CART_SERVICE: {
            const {
                data: { ridesService },
            } = action as ActionConverter<typeof type>;
            const { rideCart } = state;
            return {
                ...state,
                rideCart: {
                    ...rideCart,
                    ridesService,
                },
            };
        }
        case RIDES_CLEAR_CART: {
            return {
                ...state,
                rideCart: {
                    ...INITIAL_RIDE_CART,
                },
            };
        }
        case RIDES_REORDER_CART: {
            const {
                data: { rideCart },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                rideCart,
            };
        }

        default:
            return state;
    }
};
