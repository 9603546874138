import React from 'react';
import { Box, Grid } from '@material-ui/core';

// styles
import './footer.scss';

const Footer = (): JSX.Element => {
    return (
        <Box className="can-footer">
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Box className="can-footer__logo">
                        <a href="/">
                            <span>CAN</span>HOME
                        </a>
                    </Box>
                </Grid>
                {/* <Grid container item xs={12} sm={6} className="can-footer__social-links">
                    <Grid item className="can-footer__social-links__item">
                        <a href="/" target="_blank">
                            <img src="img/Twitter_Social_Icon_Rounded_Square_White.svg" width="30" alt="Twitter Logo" />
                        </a>
                    </Grid>
                    <Grid item className="can-footer__social-links__item">
                        <a href="/">
                            <img src="img/Facebook-Logo.svg" width="30" alt="Facebook Logo" />
                        </a>
                    </Grid>
                    <Grid item className="can-footer__social-links__item">
                        <a href="/" target="_blank">
                            <img src="img/Insta.svg" width="30" alt="Instagram Logo" />
                        </a>
                    </Grid>
                </Grid> */}
            </Grid>
            <Box className="can-footer__bottom">
                <Box>
                    <p>Copyright © 2020 CAN Home</p>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
