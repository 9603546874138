import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
// icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

interface Props {
    dialogContent: React.ReactNode;
    dialogTitle: string;
    isDialogOpen: boolean;
    isSaveButtonDisabled: boolean;
    items: React.ReactNode[];
    onAddButtonPressed: () => void;
    onCloseButtonPressed: () => void;
    onSavePressed: () => void;
}

const SettingsCarousel: React.FC<Props> = ({
    dialogContent,
    dialogTitle,
    isDialogOpen,
    isSaveButtonDisabled,
    items,
    onAddButtonPressed,
    onCloseButtonPressed,
    onSavePressed,
}) => {
    return (
        <Grid container spacing={2} alignItems="center">
            {items}
            <Grid item>
                <IconButton onClick={onAddButtonPressed}>
                    <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
            </Grid>

            <Dialog
                open={isDialogOpen}
                onClose={onCloseButtonPressed}
                aria-labelledby="add-insurance"
                aria-describedby="add-insurance-modal"
                PaperProps={{
                    style: { padding: 20 },
                }}
            >
                <DialogTitle>
                    <Box textAlign="center">{dialogTitle}</Box>
                </DialogTitle>
                <DialogContent>
                    <Box>{dialogContent}</Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onCloseButtonPressed} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onSavePressed}
                        color="primary"
                        disabled={isSaveButtonDisabled}
                        autoFocus
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default SettingsCarousel;
