import { IDoctorAppointmentsSubmitOrderParams } from '../../lib/api';
import { IApiAddress, IExistingDoctorAppointmentCart, INewDoctorAppointmentCart } from '../../lib/types';

import { DispatchType } from '../';
import {
    DOCTOR_APPOINTMENTS_CLEAR_EXISTING_CART,
    DOCTOR_APPOINTMENTS_CLEAR_NEW_CART,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_FROM,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_TO,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_HOSPITAL,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_NAME,
    DOCTOR_APPOINTMENTS_EXISTING_CART_SET_IS_ONLINE_APPOINTMENT,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_FROM,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_TO,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_DOC_HOSPITAL,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_IS_ONLINE_APPOINTMENT,
    DOCTOR_APPOINTMENTS_NEW_CART_SET_SPECIALITY,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS,
    DOCTOR_APPOINTMENTS_REORDER_EXISTING_CART,
    DOCTOR_APPOINTMENTS_REORDER_NEW_CART,
    DOCTOR_APPOINTMENTS_SET_IS_EXISTING_DOCTOR_APPOINTMENT,
} from '../constants';
import { submitOrderHelper } from '../utils';

export const submitDoctorAppointmentsOrderAction = submitOrderHelper<IDoctorAppointmentsSubmitOrderParams>(
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR
);

export const setExistingDoctorAppointmentCartAvailabilityFromAction = (dispatch: DispatchType) => (
    availabilityFrom: string | undefined
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_FROM,
        data: {
            availabilityFrom,
        },
    });

export const setExistingDoctorAppointmentCartAvailabilityToAction = (dispatch: DispatchType) => (
    availabilityTo: string | undefined
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_TO,
        data: {
            availabilityTo,
        },
    });

export const setExistingDoctorAppointmentCartDocHospitalAction = (dispatch: DispatchType) => (
    docHospital: IApiAddress | undefined
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_HOSPITAL,
        data: {
            docHospital,
        },
    });

export const setExistingDoctorAppointmentCartDocNameAction = (dispatch: DispatchType) => (docName: string) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_NAME,
        data: {
            docName,
        },
    });

export const setExistingDoctorAppointmentCartIsOnlineAppointmentAction = (dispatch: DispatchType) => (
    isOnlineAppointment: boolean
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_EXISTING_CART_SET_IS_ONLINE_APPOINTMENT,
        data: {
            isOnlineAppointment,
        },
    });

export const setNewDoctorAppointmentCartAvailabilityFromAction = (dispatch: DispatchType) => (
    availabilityFrom: string | undefined
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_FROM,
        data: {
            availabilityFrom,
        },
    });

export const setNewDoctorAppointmentCartAvailabilityToAction = (dispatch: DispatchType) => (
    availabilityTo: string | undefined
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_TO,
        data: {
            availabilityTo,
        },
    });

export const setNewDoctorAppointmentCartDocHospitalAction = (dispatch: DispatchType) => (
    docHospital: IApiAddress | undefined
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_NEW_CART_SET_DOC_HOSPITAL,
        data: {
            docHospital,
        },
    });

export const setNewDoctorAppointmentCartIsOnlineAppointmentAction = (dispatch: DispatchType) => (
    isOnlineAppointment: boolean
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_NEW_CART_SET_IS_ONLINE_APPOINTMENT,
        data: {
            isOnlineAppointment,
        },
    });

export const setNewDoctorAppointmentCartSpecialityAction = (dispatch: DispatchType) => (speciality: string) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_NEW_CART_SET_SPECIALITY,
        data: {
            speciality,
        },
    });

export const setDoctorAppointmentIsExistingDoctorAppointmentAction = (dispatch: DispatchType) => (
    isExistingDoctorAppointment: boolean
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_SET_IS_EXISTING_DOCTOR_APPOINTMENT,
        data: {
            isExistingDoctorAppointment,
        },
    });

export const clearDoctorAppointmentExistingCartAction = (dispatch: DispatchType) => () =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_CLEAR_EXISTING_CART,
    });

export const clearDoctorAppointmentNewCartAction = (dispatch: DispatchType) => () =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_CLEAR_NEW_CART,
    });

export const reorderExistingDoctorAppointmentCartAction = (dispatch: DispatchType) => (
    existingDoctorAppointmentCart: IExistingDoctorAppointmentCart
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_REORDER_EXISTING_CART,
        data: {
            existingDoctorAppointmentCart,
        },
    });

export const reorderNewDoctorAppointmentCartAction = (dispatch: DispatchType) => (
    newDoctorAppointmentCart: INewDoctorAppointmentCart
) =>
    dispatch({
        type: DOCTOR_APPOINTMENTS_REORDER_NEW_CART,
        data: {
            newDoctorAppointmentCart,
        },
    });
