import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MUISwitch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
        },
        switchBase: {
            'padding': 1,
            'backgroundColor': '#BDBDBD',
            '&$checked': {
                'transform': 'translateX(16px)',
                'color': theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#4051B6',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: '#BDBDBD',
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
        values: {
            color: '#61697D',
        },
        label: {
            color: '#61697D',
            fontSize: 12,
            marginLeft: theme.spacing(1),
        },
        labelRoot: {
            marginLeft: 0,
        },
    });

interface Props {
    readonly checked: boolean;
    readonly label: string;
    readonly onChange: () => void;
}

const Switch: React.FC<Props & WithStyles<typeof styles>> = ({ checked, onChange, label, classes }) => {
    return (
        <FormControlLabel
            classes={{
                root: classes.labelRoot,
                label: classes.label,
            }}
            control={
                <Typography component="div" classes={{ root: classes.values }}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                            <MUISwitch
                                checked={checked}
                                onChange={onChange}
                                focusVisibleClassName={classes.focusVisible}
                                disableRipple
                                classes={{
                                    checked: classes.checked,
                                    root: classes.root,
                                    switchBase: classes.switchBase,
                                    thumb: classes.thumb,
                                    track: classes.track,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Typography>
            }
            label={label}
        />
    );
};

export default withStyles(styles)(Switch);
