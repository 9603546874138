import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IGroceryCartItem } from '../../lib/types';

import { DispatchType, RootState } from '../../state';
import {
    addAvailableGroceryProductsAction,
    addGroceryProductToCartAction,
    removeGroceryProductFromCartAction,
} from '../../state/actions/groceriesActions';

import GroceryCartList from '../GroceryCartList';

const mapState = ({ groceries: { availableProducts, groceryCart } }: RootState) => {
    const orderedGroceryCart = Object.values(groceryCart)
        .filter((item) => !!item)
        .sort((left, right) => {
            if (left && right) {
                const {
                    product: { name: leftName },
                } = left;
                const {
                    product: { name: rightName },
                } = right;

                if (leftName < rightName) {
                    return -1;
                }
                if (leftName > rightName) {
                    return 1;
                }
            }
            return 0;
        }) as IGroceryCartItem[];
    return {
        availableProducts,
        orderedGroceryCart,
    };
};

const mapDispatch = (dispatch: DispatchType) => ({
    addAvailableGroceryProducts: addAvailableGroceryProductsAction(dispatch),
    addGroceryProductToCart: addGroceryProductToCartAction(dispatch),
    removeGroceryProductFromCart: removeGroceryProductFromCartAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CurrentCartList: React.FC<PropsFromRedux> = ({ orderedGroceryCart, removeGroceryProductFromCart }) => (
    <GroceryCartList items={orderedGroceryCart} removeGroceryProductFromCart={removeGroceryProductFromCart} />
);

export default connector(CurrentCartList);
