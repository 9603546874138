import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ApisContext } from '../../../lib/contexts';

import { DispatchType, RootState } from '../../../state';
import { changePasswordAction } from '../../../state/actions/userActions';

const mapStateToProps = ({ user: { passwordChangeErrorMessage } }: RootState) => ({ passwordChangeErrorMessage });

const mapDispatchToProps = (dispatch: DispatchType) => ({
    changePassword: changePasswordAction(dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    closeModal: () => void;
}

const ChangePassword: React.FC<Props & PropsFromRedux> = ({
    changePassword,
    closeModal,
    passwordChangeErrorMessage,
}) => {
    const { userApi } = useContext(ApisContext);

    const [state, setState] = useState<{
        oldPassword?: string;
        newPassword?: string;
        confirmNewPassword?: string;
        errorMessage?: string;
    }>({});

    useEffect(
        () =>
            setState({
                ...state,
                errorMessage: passwordChangeErrorMessage,
            }),
        [passwordChangeErrorMessage]
    );

    const change = async () => {
        const { oldPassword, newPassword, confirmNewPassword } = state;

        if (!oldPassword || !newPassword || !confirmNewPassword) {
            return setState({
                ...state,
                errorMessage: 'Old Password, New password and Confirm New Password are requred fields',
            });
        }

        if (newPassword !== confirmNewPassword) {
            return setState({
                ...state,
                errorMessage: 'Confirm New Password should be equal to New password',
            });
        }

        if (
            await changePassword(userApi, {
                oldPassword,
                newPassword,
            })
        ) {
            closeModal();
        }
    };

    return (
        <div className="edit-user__change-password-paper">
            <Grid container item direction="row" justify="space-between" alignItems="center">
                <Grid item className="edit-user__title">
                    Change your password
                </Grid>
                <Grid item className="edit-user__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="column"
                justify="flex-start"
                alignItems="center"
                className="edit-user__change-password-main-container"
            >
                <Grid item className="edit-user__user-info-part">
                    <div>
                        <div className="edit-user__input-text">Old Password</div>
                        <Link className="edit-user__forgot-password-link" href="/#/forgot-password" variant="body2">
                            Forgot your password?
                        </Link>
                    </div>
                    <input
                        type="password"
                        className="edit-user__user-info-part-input"
                        onChange={(event) => {
                            const value = event.target.value;
                            setState((prevState) => {
                                return { ...prevState, oldPassword: value };
                            });
                        }}
                    />
                </Grid>
                <Grid item className="edit-user__user-info-part">
                    <div>
                        <div className="edit-user__input-text">New Password</div>
                    </div>
                    <input
                        type="password"
                        className="edit-user__user-info-part-input"
                        onChange={(event) => {
                            const value = event.target.value;
                            setState((prevState) => {
                                return { ...prevState, newPassword: value };
                            });
                        }}
                    />
                </Grid>
                <Grid item className="edit-user__user-info-part">
                    <div>
                        <div className="edit-user__input-text">Confirm New Password</div>
                    </div>
                    <input
                        type="password"
                        className="edit-user__user-info-part-input"
                        onChange={(event) => {
                            const value = event.target.value;
                            setState((prevState) => {
                                return { ...prevState, confirmNewPassword: value };
                            });
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item className="edit-user__change-password-error-message">
                {state.errorMessage}
            </Grid>
            <Grid item className="edit-user__info-message gray-text">
                Password must be at least 6 characters long and contain at least 1 uppercase letter
            </Grid>
            <Grid container item direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                    <Button fullWidth variant="outlined" onClick={closeModal}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className="edit-user__save-button"
                        onClick={change}
                    >
                        Change
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default connector(ChangePassword);
