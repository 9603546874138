import React from 'react';

import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Grid } from '@material-ui/core';

import BackButton from './BackButton';
interface Props {
    onBackPressed: () => void;
    onOrderAgainPressed: () => void;
    open: boolean;
    total: string | undefined;
    title: string;
}

const OrderInfoModal: React.FC<Props> = ({ title, open, onBackPressed, total, children, onOrderAgainPressed }) => (
    <Dialog open={open} onClose={onBackPressed} fullWidth>
        <DialogTitle>
            <Grid container justify="space-between">
                <Grid item xs={6}>
                    {title}
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                    <BackButton onClick={onBackPressed} />
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <Grid container item alignContent="space-between" justify="space-between" xs={12}>
                <Grid item xs={6}>
                    <Button className="dashboard-btn" onClick={onOrderAgainPressed}>
                        Order Again
                    </Button>
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                    Total: ${total || 'n/a'}
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default OrderInfoModal;
