import React, { Fragment } from 'react';

import Divider from '@material-ui/core/Divider';

import { IMedicationOrderItem, PRESCRIPTION_MEDICATION, OVER_THE_COUNTER_MEDICATION } from '../../lib/types';

import OverTheCounterMedicationListItem from './OverTheCounterMedicationListItem';
import PrescriptionMedicationListItem from './PrescriptionMedicationListItem';

interface Props {
    items: IMedicationOrderItem[];
}

type ItemConverter<A> = Extract<IMedicationOrderItem, { type: A }>;

const MedicationCartList: React.FC<Props> = ({ items }) => (
    <>
        {items.map((item, id) => {
            const { type } = item;
            switch (type) {
                case PRESCRIPTION_MEDICATION:
                    return (
                        <Fragment key={id}>
                            <PrescriptionMedicationListItem item={item as ItemConverter<typeof type>} />
                            <Divider />
                        </Fragment>
                    );
                case OVER_THE_COUNTER_MEDICATION:
                    return (
                        <Fragment key={id}>
                            <OverTheCounterMedicationListItem item={item as ItemConverter<typeof type>} />
                            <Divider />
                        </Fragment>
                    );
            }
            return null;
        })}
    </>
);

export default MedicationCartList;
