import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// styles
import './styles.scss';

interface Props {
    options: string[];
    onChange: (value: string) => void;
    value: string;
}

const MedsButtonGroup: React.FC<Props> = ({ value, onChange, options }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useEffect(() => {
        setSelectedIndex(options.indexOf(value));
    }, [value]);

    const handleChange = (value: number) => {
        setSelectedIndex(value);
        onChange(options[value]);
    };

    return (
        <ToggleButtonGroup
            exclusive
            onChange={({}, value) => handleChange(value)}
            aria-label="text alignment"
            className="meds-button-group"
        >
            {options.map((value, index) => (
                <ToggleButton
                    key={index}
                    value={index}
                    className={`meds-button-group__button ${
                        selectedIndex === index ? 'meds-button-group__button--active' : ''
                    }`}
                >
                    <div>{value}</div>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};
// }

export default MedsButtonGroup;
