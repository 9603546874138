import { IGroceryCart } from '../../lib/types';

export const calculateCartSummary = (cart: IGroceryCart) => {
    let cartItemCount = 0;
    let cartSumAmount = 0;

    Object.values(cart).forEach((item) => {
        if (item) {
            const {
                count,
                product: { unit_price },
            } = item;

            cartItemCount += count;
            cartSumAmount += count * unit_price;
        }
    });
    if (cartItemCount > 0) {
        // delivery
        cartSumAmount += 5;
    }
    return {
        cartItemCount,
        cartSumAmount: cartSumAmount.toFixed(2),
    };
};
