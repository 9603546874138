import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Button, Paper, Typography, Grid } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { RootState } from '../../state';

import './styles.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const { invitedCaregiverInfo } = loggedInUser;
        if (invitedCaregiverInfo) {
            const { firstName, lastName } = invitedCaregiverInfo;
            return {
                fullName: `${firstName} ${lastName}`,
            };
        }
    }
    return {};
};

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

const OrderingForButton: React.FC<PropsFromRedux> = ({ fullName }) => {
    if (!fullName) {
        return null;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography color="textSecondary">Ordering for</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button disableRipple fullWidth>
                    <Paper className="OrderingForButton__button-content">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2} className="OrderingForButton__button-image">
                                <img src="./img/testimonial_pic02.jpg" className="OrderingForButton__profile-image" />
                            </Grid>
                            <Grid item xs={8} className="OrderingForButton__button-name">
                                <Typography color="textPrimary">{fullName}</Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={2}
                                alignItems="center"
                                justify="flex-end"
                                className="OrderingForButton__button-end"
                            >
                                <ExpandMoreIcon />
                            </Grid>
                        </Grid>
                    </Paper>
                </Button>
            </Grid>
        </Grid>
    );
};

export default connector(OrderingForButton);
