import { MedicationsState, MedicationsActionType } from '../types';
import {
    MEDICATIONS_ADD_OVER_THE_COUNTER_MEDICATION_TO_CART,
    MEDICATIONS_ADD_PRESCRIPTION_MEDICATION_TO_CART,
    MEDICATIONS_CLEAR_CARTS,
    MEDICATIONS_ORDER_SUBMIT_ERROR,
    MEDICATIONS_ORDER_SUBMIT_PENDING,
    MEDICATIONS_ORDER_SUBMIT_SUCCESS,
    MEDICATIONS_REORDER_CART,
} from '../constants';

const initialState: MedicationsState = {
    overTheCounterMedicationCart: [],
    prescriptionMedicationCart: [],
    submitOrder: {},
};

export default (state = initialState, action: MedicationsActionType): MedicationsState => {
    const { type } = action;
    type ActionConverter<A> = Extract<MedicationsActionType, { type: A }>;

    switch (type) {
        case MEDICATIONS_ORDER_SUBMIT_PENDING: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'pending',
                    errorMessage: undefined,
                },
            };
        }
        case MEDICATIONS_ORDER_SUBMIT_SUCCESS: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'success',
                    errorMessage: undefined,
                },
            };
        }
        case MEDICATIONS_ORDER_SUBMIT_ERROR: {
            const {
                data: { errorMessage },
            } = action as ActionConverter<typeof type>;
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'error',
                    errorMessage,
                },
            };
        }

        case MEDICATIONS_ADD_PRESCRIPTION_MEDICATION_TO_CART: {
            const {
                data: { item },
            } = action as ActionConverter<typeof type>;
            const { prescriptionMedicationCart } = state;
            return {
                ...state,
                prescriptionMedicationCart: [...prescriptionMedicationCart, item],
            };
        }

        case MEDICATIONS_ADD_OVER_THE_COUNTER_MEDICATION_TO_CART: {
            const {
                data: { item },
            } = action as ActionConverter<typeof type>;
            const { overTheCounterMedicationCart } = state;
            return {
                ...state,
                overTheCounterMedicationCart: [...overTheCounterMedicationCart, item],
            };
        }

        case MEDICATIONS_CLEAR_CARTS: {
            return {
                ...state,
                prescriptionMedicationCart: [],
                overTheCounterMedicationCart: [],
            };
        }

        case MEDICATIONS_REORDER_CART: {
            const {
                data: { overTheCounterMedicationCart, prescriptionMedicationCart },
            } = action as ActionConverter<typeof type>;

            return {
                ...state,
                overTheCounterMedicationCart,
                prescriptionMedicationCart,
            };
        }

        default:
            return state;
    }
};
