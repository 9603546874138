import React, { useState, useContext, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import _ from 'lodash';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import { ShoppingCartOutlined as ShoppingCartOutlinedIcon } from '@material-ui/icons';

import AvailableProductsList from './AvailableProductsList';
import Card from '../Card/Card';
import CurrentCartList from './CurrentCartList';
import RequestSubmittedModal from '../RequestSubmittedModal';
import CustomGroceryItemDialog from '../CustomGroceryItemDialog';

import { ApisContext } from '../../lib/contexts';
import { IGroceryProduct, IGroceryCartItem } from '../../lib/types';
import { DispatchType, RootState } from '../../state';
import {
    addAvailableGroceryProductsAction,
    submitGroceriesOrderAction,
    clearGroceryCartAction,
} from '../../state/actions/groceriesActions';

import { calculateCartSummary } from './utils';
// styles
import './GroceriesService.scss';

const mapState = ({
    groceries: {
        availableProducts,
        groceryCart,
        submitOrder: { state: submitOrderState, errorMessage: submitOrderErrorMessage },
    },
    user: { loggedInUser },
}: RootState) => ({
    ...calculateCartSummary(groceryCart),
    availableProducts,
    groceryCart,
    phoneNumber: loggedInUser?.userInfo.phoneNumber,
    submitOrderErrorMessage,
    submitOrderState,
    userId: loggedInUser?.userId,
});

const mapDispatch = (dispatch: DispatchType) => ({
    addAvailableGroceryProducts: addAvailableGroceryProductsAction(dispatch),
    clearGroceryCart: clearGroceryCartAction(dispatch),
    submitOrder: submitGroceriesOrderAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const NUMBER_OF_STEPS = 3;

const GroceriesService: React.FC<PropsFromRedux> = ({
    addAvailableGroceryProducts,
    availableProducts,
    cartItemCount,
    cartSumAmount,
    clearGroceryCart,
    groceryCart,
    phoneNumber,
    submitOrder,
    submitOrderErrorMessage,
    userId,
}) => {
    const { groceriesApi, userApi } = useContext(ApisContext);

    const [step, setStep] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isCustomGroceryItemDialogOpen, setIsCustomGroceryItemDialogOpen] = useState(false);

    useEffect(() => {
        if (userId) {
            addAvailableGroceryProducts(userApi, { userId });
        }
    }, [userId]);

    useEffect(() => setErrorMessage(submitOrderErrorMessage), [submitOrderErrorMessage]);

    const nextStep = () =>
        setStep((step) => {
            const next = step + 1;
            return next === NUMBER_OF_STEPS ? 0 : next;
        });

    const goBack = () =>
        setStep((step) => {
            const next = step - 1;
            return next < 0 ? 0 : next;
        });

    const submitOrderCallback = async () => {
        if (!userId) {
            throw new Error('Missing userId');
        }

        const items = Object.values(groceryCart).filter((item) => !!item) as IGroceryCartItem[];

        if (items.length === 0) {
            setErrorMessage('Please enter required data');
        } else {
            setErrorMessage(undefined);
            if (
                await submitOrder(groceriesApi, {
                    order: {
                        items,
                        itemCount: cartItemCount,
                        sumAmount: cartSumAmount,
                    },
                    userId,
                })
            ) {
                setErrorMessage(undefined);
                clearGroceryCart();
                nextStep();
            }
        }
    };

    return (
        <Grid item xs={12} md={6} lg={4} className="groceries-service">
            <Card>
                <Box className="module">
                    {step === 1 && (
                        <>
                            <Grid container className="module-top">
                                <Grid item xs={7}>
                                    <h4>Order groceries</h4>
                                </Grid>
                                <Grid item xs={5} className="module-bot-right">
                                    {/* TODO: bring Settings back when the page is ready */}
                                    {/* <a href="#">Settings</a> */}
                                </Grid>
                            </Grid>

                            <Box className="module-fields">
                                <Grid container className="groceries-service__checkout-container">
                                    <CurrentCartList />
                                    <Grid item xs={12}>
                                        <Button className="btn-link module-list-row-adjust" onClick={() => goBack()}>
                                            Adjust groceries list
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className="module-bot">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            disableRipple
                                            disabled={cartItemCount <= 0}
                                            className="dashboard-btn"
                                            onClick={submitOrderCallback}
                                        >
                                            <ShoppingCartOutlinedIcon className="dashboard-btn__icon" />
                                            {cartItemCount} Confirm
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className="module-bot-right">
                                        <p className="module-bot-right-price">${cartSumAmount}</p>
                                        {/* <a href="#">View details</a> */}
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* <!-- END module bot --> */}
                        </>
                    )}
                    {step === 0 && (
                        <>
                            <CustomGroceryItemDialog
                                isDialogOpen={isCustomGroceryItemDialogOpen}
                                onCloseButtonPressed={() => setIsCustomGroceryItemDialogOpen(false)}
                            />
                            <Grid container className="module-top">
                                <Grid item xs={6}>
                                    <h4>Groceries</h4>
                                </Grid>
                            </Grid>
                            <Box className="module-fields">
                                <Box className="products-list">
                                    <AvailableProductsList />
                                    <Box className="productmodule productmodule-add grocery-product">
                                        <Button disableRipple onClick={() => setIsCustomGroceryItemDialogOpen(true)}>
                                            <span className="productmodule-price" />
                                            <Box textAlign="center">
                                                <img src="img/addmoreitems.png" className="img-responsive" alt="" />
                                            </Box>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="module-bot">
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Button
                                            disableRipple
                                            disabled={cartItemCount <= 0}
                                            className="dashboard-btn"
                                            onClick={nextStep}
                                        >
                                            <ShoppingCartOutlinedIcon className="dashboard-btn__icon" />
                                            {cartItemCount} Checkout
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className="module-bot-right">
                                        <Box textAlign="right">
                                            <p className="module-bot-right-price">${cartSumAmount}</p>
                                            <p className="module-bot-right-taxestxt">
                                                {cartItemCount > 0 ? 'Includes $5 tax & $5 delivery' : ''}
                                            </p>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <Grid container className="module-top">
                                <Grid item xs={6}>
                                    <h4>Order groceries</h4>
                                </Grid>
                            </Grid>
                            <Box className="module-fields" textAlign="center">
                                <Typography variant="inherit" component="p" className="confirmation-text">
                                    We're looking at your booking request and will text you on {phoneNumber} with
                                    details about your booking for your confirmation.
                                </Typography>
                                <Button className="dashboard-btn" onClick={nextStep}>
                                    OK
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Card>
            <RequestSubmittedModal
                headerImage="img/groceries-request-submitted-modal-bg.png"
                nextSteps={({ phoneNumber }) => [
                    'We will connect with trusted grocery delivery partners, such as Instacart, to fulfill your order.',
                    `We will text you on ${phoneNumber} with confirmation details including price, items, and delivery time.`,
                    'You will send us a confirmation via text before creating an order.',
                    'We will send you delivery status updates via text',
                ]}
                onCloseModal={nextStep}
                showModal={step === 2}
                title="Your groceries were requested!"
            />
        </Grid>
    );
};

export default connector(GroceriesService);
