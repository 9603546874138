import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Box, Input } from '@material-ui/core';
import { geocodeByPlaceId } from 'react-places-autocomplete';

import { AddressAutoFillCallback, AddressAutoFillObjectType } from '../../lib/types';

import { classnames } from '../helpers';
import './styles.scss';

interface Props {
    baseInput?: boolean;
    parentCallback: AddressAutoFillCallback;
    placeholder: string;
    value: AddressAutoFillObjectType | undefined;
}

interface State {
    address: string;
    errorMessage?: string;
}

const AddressAutoFill: React.FC<Props> = ({ baseInput, parentCallback, placeholder, value }) => {
    const [state, setState] = useState<State>({ address: '' });

    useEffect(() => {
        if (value) {
            const { address } = value;
            setState({
                address,
            });
        } else {
            setState({
                address: '',
            });
        }
    }, [value]);

    const handleChange = (address: string) =>
        setState({
            address,
            errorMessage: undefined,
        });

    const handleSelect = (address: string, selectedPlaceId: any) => {
        setState({ address });

        geocodeByPlaceId(selectedPlaceId)
            .then(([result]) => {
                if (result) {
                    const { address_components: addressComponents } = result;

                    let addressState: string | undefined = undefined;
                    let addressZipCode: string | undefined = undefined;
                    addressComponents.map((item) => {
                        if (item.types.includes('administrative_area_level_1')) addressState = item.long_name;
                        if (item.types.includes('postal_code')) addressZipCode = item.long_name;
                    });

                    parentCallback({
                        address,
                        addressState,
                        addressZipCode,
                        placesObject: result,
                    });
                }
            })
            .catch((error) => console.error(error));
    };

    const handleCloseClick = () => {
        setState({
            address: '',
        });
        parentCallback(undefined);
    };

    const handleError = (status: any, clearSuggestions: any) => {
        if (status === 'ZERO_RESULTS') {
            setState({ ...state, errorMessage: undefined });
            clearSuggestions();
            return;
        }

        setState({ ...state, errorMessage: status });
        clearSuggestions();
    };

    const { address, errorMessage } = state;

    return (
        <Box className="PlacesAutocompleteComponent">
            <PlacesAutocomplete
                onChange={handleChange}
                value={address}
                onSelect={handleSelect}
                onError={handleError}
                shouldFetchSuggestions={!!(address && address.length > 2)}
                searchOptions={{
                    componentRestrictions: {
                        country: ['us'],
                    },
                    types: ['address'],
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                    return (
                        <Box className="AddressAutoFill__search-bar-container">
                            <Box className="AddressAutoFill__search-input-container">
                                {baseInput ? (
                                    <input
                                        {...getInputProps({
                                            placeholder: placeholder || 'Search address ...',
                                            className: 'AddressAutoFill__search-input dashboard-input',
                                            autoComplete: 'no',
                                        })}
                                    />
                                ) : (
                                    <Input
                                        {...getInputProps({
                                            placeholder: placeholder || 'Search address ...',
                                            className: 'AddressAutoFill__search-input form-control',
                                            autoComplete: 'no',
                                        })}
                                    />
                                )}
                                {address && (
                                    <button className="AddressAutoFill__clear-button" onClick={handleCloseClick}>
                                        x
                                    </button>
                                )}
                            </Box>
                            {suggestions.length > 0 && (
                                <Box className="AddressAutoFill__autocomplete-container">
                                    <span className="AddressAutoFill__saved-addresses__title">Search Results</span>
                                    {suggestions.map((suggestion, index: number) => {
                                        const className = classnames('AddressAutoFill__suggestion-item', {
                                            'AddressAutoFill__suggestion-item--active': suggestion.active,
                                        });

                                        return (
                                            <Box {...getSuggestionItemProps(suggestion, { className })} key={index}>
                                                <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
                                                <small>{suggestion.formattedSuggestion.secondaryText}</small>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                        </Box>
                    );
                }}
            </PlacesAutocomplete>
            {errorMessage && <div className="AddressAutoFill__error-message">{errorMessage}</div>}
        </Box>
    );
};

export default AddressAutoFill;
