import React from 'react';

import Grid from '@material-ui/core/Grid';

import { IOverTheCounterMedicationItem } from '../../lib/types';

interface Props {
    item: IOverTheCounterMedicationItem;
}

const OverTheCounterMedicationListItem: React.FC<Props> = ({
    item: {
        deliveryAddress: { address: deliveryAddress },
        description,
        drugNameOrBrand,
        pharmacyName,
    },
}) => (
    <Grid container>
        <Grid item xs={4}>
            Over the counter medication
        </Grid>
        <Grid item xs={8}>
            <Grid container item xs={12}>
                Drug name: {drugNameOrBrand}
            </Grid>
            <Grid item xs={12}>
                Description: {description}
            </Grid>
            <Grid item xs={12}>
                Delivery address: {deliveryAddress}
            </Grid>
            {pharmacyName ? (
                <Grid item xs={12}>
                    Pharmacy name: {pharmacyName}
                </Grid>
            ) : null}
        </Grid>
    </Grid>
);

export default OverTheCounterMedicationListItem;
