import { IApiBase } from './api_base';

import { IOrderHistoryItem, OrderTypeString, OrderStatusString, IGroceryCartItem } from '../types';

export interface IRequest {}

export interface IGetAllRequestsParams {
    readonly userId: string;
}

export interface IRequestsApi {
    getAllRequests(params: IGetAllRequestsParams): Promise<IOrderHistoryItem[]>;
}

const serviceNameToOrderType = (serviceName: any): OrderTypeString => {
    if (typeof serviceName === 'string') {
        switch (serviceName) {
            case 'Groceries':
                return 'groceries';
            case 'Meds':
                return 'medication';
            case 'CaregiverVisits':
                return 'caregiver';
            case 'doctorAppointment':
                return 'doctor';
            case 'UberRides':
                return 'ride';
        }
    }
    throw new Error(`Unhandled serviceName ${serviceName}`);
};

const statusToOrderStatus = (status: any): OrderStatusString => {
    if (typeof status === 'string') {
        switch (status) {
            case 'fulfilled':
                return 'fulfilled';
            case 'created':
                return 'created';
        }
    }
    throw new Error(`Unhandled status ${status}`);
};

const parseOrder = ({ created, serviceName, status, data }: any): IOrderHistoryItem => {
    const orderType = serviceNameToOrderType(serviceName);
    const commonPart = {
        orderBy: '',
        orderDate: new Date(Date.parse(created)),
        orderStatus: statusToOrderStatus(status),
    };
    return {
        ...commonPart,
        orderType,
        order: data,
    };
};

export class RequestsApi implements IRequestsApi {
    protected apiBase: IApiBase;

    constructor(apiBase: IApiBase) {
        this.apiBase = apiBase;
    }

    async getAllRequests({ userId }: IGetAllRequestsParams): Promise<IOrderHistoryItem[]> {
        const { data } = await this.apiBase.get(`/api/users/${userId}/requests`);
        if (Array.isArray(data)) {
            return data
                .map((order: any) => parseOrder(order))
                .sort(({ orderDate: a }, { orderDate: b }) => b.valueOf() - a.valueOf());
        }
        return [];
    }
}
