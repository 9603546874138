import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import OrderCard from './OrderCard/OrderCard';

import { DispatchType, RootState } from '../../state';

import { ApisContext } from '../../lib/contexts';

import { Dropdown, TypeSelectValue } from '../../components/Dropdown/Dropdown';

// styles
import './styles.scss';
import { IOrderHistoryItem } from '../../lib/types';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return { userId };
    }
};

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    goToDashboard: () => void;
}

const OrderHistory: React.FC<Props & PropsFromRedux> = ({ userId, goToDashboard }) => {
    const { requestsApi } = useContext(ApisContext);

    const [orderFilter, setFilter] = useState<string | number>('');
    const [selectedValue, setSelectedValue] = useState<TypeSelectValue>();
    const [orders, setOrders] = useState<IOrderHistoryItem[]>([]);

    useEffect(() => {
        const fetch = async () =>
            setOrders(
                await requestsApi.getAllRequests({
                    userId,
                })
            );

        fetch();
    }, [userId, requestsApi]);

    const dropDownOptions = [
        {
            label: 'My mother',
            value: 'mother',
        },
        {
            label: 'My father',
            value: 'father',
        },
        {
            label: 'Me',
            value: 'me',
        },
    ];

    // handle select change
    const handleFilterChange = (selectedItem: TypeSelectValue) => {
        setFilter(selectedItem.value);
        setSelectedValue(selectedItem);
    };

    // map orders state
    const renderOrders = () =>
        orders.map((item: IOrderHistoryItem, index) => (
            <OrderCard orderData={item} goToDashboard={goToDashboard} key={index} />
        ));

    // render filtered orders when there is an active filter
    const filteredOrders = (filter: string | number) => {
        const filteredOrders = orders.filter((item) => item.orderBy === filter);

        return filteredOrders.map((item: IOrderHistoryItem, index) => (
            <OrderCard orderData={item} goToDashboard={goToDashboard} key={index} />
        ));
    };

    return (
        <Box className="order-history">
            <Box>
                <Grid container alignItems="flex-end" justify="space-between" className="order-history__order-filters">
                    <Grid item xs={6}>
                        <Box>
                            <Dropdown
                                placeholder="Past orders"
                                onChange={(selectedItem) => handleFilterChange(selectedItem)}
                                options={dropDownOptions}
                                selectedValue={selectedValue}
                            />
                        </Box>
                    </Grid>
                    <Grid container item justify="flex-end" xs={6}>
                        <Box>
                            <Dropdown label="Ordering for" placeholder="Select user" />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="order-history__orders-container">
                    {orderFilter ? filteredOrders(orderFilter) : renderOrders()}
                </Grid>
            </Box>
        </Box>
    );
};

export default connector(OrderHistory);
