import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import PlacesAutocomplete from 'react-places-autocomplete';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { IApiAddress, HomeAddressType } from '../../lib/types';
import { ApisContext } from '../../lib/contexts';
import { DispatchType, RootState } from '../../state';
import { addAddressAction, updateLoggedInUserDataAction } from '../../state/actions/userActions';
import { classnames } from '../helpers';
import AddAddressDialog from '../AddAddressDialog';
import { AddCircleOutline as AddCircleOutlineIcon, LocationOn as LocationOnIcon } from '@material-ui/icons';

import HouseIcon from '../../assets/icons/house-icon.svg';

import './styles.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return {
            userId,
        };
    }
    return undefined;
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
    addAddress: addAddressAction(dispatch),
    updateLoggedInUserData: updateLoggedInUserDataAction(dispatch),
});

const connector = connect(mapState, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ParentCallback = (address: IApiAddress | undefined) => void;

interface Props {
    parentCallback: ParentCallback;
    placeholder: string;
    savedAddresses?: HomeAddressType[];
    value: IApiAddress | undefined;
}

interface State {
    errorMessage?: string;
    isGeocoding: boolean;
    isFocused: boolean;
    isAddAddressDialogOpen: boolean;
}

class AddressSearchBar extends Component<Props & PropsFromRedux, State> {
    constructor(props: Props & PropsFromRedux) {
        super(props);

        this.state = {
            isAddAddressDialogOpen: false,
            isFocused: false,
            isGeocoding: false,
        };
    }

    showSavedAddresses = (state: boolean) => {
        this.setState({ isFocused: state });
    };

    parseAndGeoCode = (address: string) => {
        geocodeByAddress(address)
            .then((res) => getLatLng(res[0]))
            .then(({ lat: latitude, lng: longitude }) => {
                this.setState({
                    isGeocoding: false,
                });
                this.props.parentCallback({
                    address,
                    latitude,
                    longitude,
                });
            })
            .catch((error) => {
                this.setState({ isGeocoding: false });
                console.log('error', error); // eslint-disable-line no-console
            });
    };

    handleChange = (address: string) => {
        this.setState({
            errorMessage: undefined,
        });

        this.props.parentCallback({
            address,
        });
    };

    handleSelect = (address: string) => {
        this.setState({ isGeocoding: true });

        this.props.parentCallback({
            address,
        });
        this.parseAndGeoCode(address);
        // hide saved addresses
        this.showSavedAddresses(false);
    };

    handleCloseClick = () => {
        // hide saved addresses
        this.showSavedAddresses(false);

        this.setState({
            isGeocoding: false,
            isFocused: false,
        });
        this.props.parentCallback(undefined);
    };

    handleError = (status: any, clearSuggestions: any) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        if (status === 'ZERO_RESULTS') {
            this.setState({ errorMessage: undefined }, () => {
                clearSuggestions();
            });
            return;
        }

        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };

    closeAddAddressDialog = () => {
        this.setState({
            ...this.state,
            isAddAddressDialogOpen: false,
        });
    };

    render() {
        const { errorMessage, isGeocoding, isFocused } = this.state;
        const { value, savedAddresses, addAddress, updateLoggedInUserData, userId } = this.props;

        const address = value?.address;

        return (
            <div className="PlacesAutocompleteComponent">
                <PlacesAutocomplete
                    onChange={this.handleChange}
                    value={address || ''}
                    onSelect={this.handleSelect}
                    onError={this.handleError}
                    shouldFetchSuggestions={!!(address && address.length > 2)}
                    searchOptions={{
                        componentRestrictions: {
                            country: ['us'],
                        },
                        types: ['address'],
                    }}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                        return (
                            <ClickAwayListener onClickAway={() => this.setState({ isFocused: false })}>
                                <div className="AddressSearchBar__search-bar-container">
                                    <div className="AddressSearchBar__search-input-container">
                                        <div className="form-group">
                                            <input
                                                {...getInputProps({
                                                    placeholder: this.props.placeholder || 'Search address ...',
                                                    className:
                                                        'AddressSearchBar__search-input form-control dashboard-input',
                                                    onFocus: () => this.showSavedAddresses(true),
                                                })}
                                            />
                                            {address && (
                                                <button
                                                    className="AddressSearchBar__clear-button"
                                                    onClick={this.handleCloseClick}
                                                >
                                                    x
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="AddressSearchBar__results-holder">
                                        {savedAddresses?.length && isFocused && (
                                            <div className="AddressSearchBar__saved-addresses">
                                                <ApisContext.Consumer>
                                                    {({ userApi }) => (
                                                        <AddAddressDialog
                                                            onClose={this.closeAddAddressDialog}
                                                            onSave={async (address) => {
                                                                if (userId) {
                                                                    await addAddress(userApi, {
                                                                        userId,
                                                                        address,
                                                                    });
                                                                    await updateLoggedInUserData(userApi);
                                                                    this.closeAddAddressDialog();
                                                                }
                                                            }}
                                                            open={this.state.isAddAddressDialogOpen}
                                                        />
                                                    )}
                                                </ApisContext.Consumer>
                                                <ul className="AddressSearchBar__saved-addresses__address-list">
                                                    {savedAddresses.map((address, index) => {
                                                        const fullAddress = `${address.address}, ${address.addressState}, ${address.addressZipCode}`;
                                                        return (
                                                            <li
                                                                onClick={() => this.handleSelect(fullAddress)}
                                                                key={index}
                                                            >
                                                                <figure>
                                                                    <img src={HouseIcon} alt="CAN Mobilities" />
                                                                </figure>
                                                                <p>
                                                                    <span>{address.addressNickname}</span>
                                                                    {fullAddress}
                                                                </p>
                                                            </li>
                                                        );
                                                    })}
                                                    <li
                                                        onClick={() =>
                                                            this.setState({
                                                                ...this.state,
                                                                isAddAddressDialogOpen: true,
                                                            })
                                                        }
                                                    >
                                                        <div className="AddressSearchBar__add-new-address">
                                                            <AddCircleOutlineIcon />
                                                            <p>Add new address</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                        {suggestions.length > 0 && (
                                            <div className="AddressSearchBar__autocomplete-container">
                                                <span className="AddressSearchBar__saved-addresses__title">
                                                    Search Results
                                                </span>
                                                {suggestions.map((suggestion, index: number) => {
                                                    const className = classnames('AddressSearchBar__suggestion-item', {
                                                        'AddressSearchBar__suggestion-item--active': suggestion.active,
                                                    });

                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, { className })}
                                                            key={index}
                                                        >
                                                            <figure className="icon">
                                                                <LocationOnIcon />
                                                            </figure>
                                                            <p>
                                                                <span>{suggestion.formattedSuggestion.mainText}</span>
                                                                {suggestion.formattedSuggestion.secondaryText}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ClickAwayListener>
                        );
                    }}
                </PlacesAutocomplete>
                {errorMessage && <div className="AddressSearchBar__error-message">{errorMessage}</div>}

                {((value?.latitude && value?.longitude) || isGeocoding) && (
                    <div>
                        {/* <h3 className="AddressSearchBar__geocode-result-header">Geocode result</h3> */}
                        {isGeocoding ? (
                            <div>
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw AddressSearchBar__spinner" />
                            </div>
                        ) : (
                            <div>
                                {/* //   <div className="AddressSearchBar__geocode-result-item--lat">
                                //     <label>Latitude:</label>
                                //     <span>{latitude}</span>
                                //   </div>
                                //   <div className="AddressSearchBar__geocode-result-item--lng">
                                //     <label>Longitude:</label>
                                //     <span>{longitude}</span>
                                //   </div> */}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default connector(AddressSearchBar);
