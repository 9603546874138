import React from 'react';

import { Dropdown } from '../../components/Dropdown/Dropdown';

import MD_SPECIALITIES from './md_specialities';

interface Props {
    speciality?: string;
    setSpeciality: (speciality: string | undefined) => void;
}

const MdSpecialitiesDropdown: React.FC<Props> = ({ speciality, setSpeciality }) => {
    return (
        <Dropdown
            selectedValue={
                speciality
                    ? {
                          label: speciality,
                          value: speciality,
                      }
                    : undefined
            }
            onChange={({ value }) => (typeof value === 'string' ? setSpeciality(value) : undefined)}
            placeholder="Speciality"
            options={MD_SPECIALITIES.map(({ label }) => ({
                label,
                value: label,
            }))}
            wide
        />
    );
};

export default MdSpecialitiesDropdown;
