import { InsuranceState, InsuranceActionType } from '../types';

import {
    INSURANCE_GET_PROVIDERS_ERROR,
    INSURANCE_GET_PROVIDERS_PENDING,
    INSURANCE_GET_PROVIDERS_SUCCESS,
} from '../constants';

const initialState: InsuranceState = {
    insuranceProviders: [],
    getInsuranceProviders: {
        state: 'success',
    },
};

export default (state = initialState, action: InsuranceActionType): InsuranceState => {
    const { type } = action;
    type ActionConverter<A> = Extract<InsuranceActionType, { type: A }>;
    switch (type) {
        case INSURANCE_GET_PROVIDERS_PENDING: {
            return {
                ...state,
                getInsuranceProviders: {
                    state: 'peding',
                },
            };
        }
        case INSURANCE_GET_PROVIDERS_SUCCESS: {
            const {
                data: { insuranceProviders },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                insuranceProviders,
                getInsuranceProviders: {
                    state: 'success',
                },
            };
        }
        case INSURANCE_GET_PROVIDERS_ERROR: {
            const {
                data: { errorMessage },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                insuranceProviders: [],
                getInsuranceProviders: {
                    state: 'error',
                    errorMessage,
                },
            };
        }
        default:
            return state;
    }
};
