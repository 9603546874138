import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { IApiAddress } from '../../lib/types';
import { RootState } from '../../state';

import AddressSearchBar from '../AddressSearchBar/AddressSearchBar';

const mapState = ({ user: { loggedInUser } }: RootState) => ({
    savedAddresses: loggedInUser?.userInfo.addressList,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    readonly deliveryAddress: IApiAddress | undefined;
    readonly pharmacyAddress: IApiAddress | undefined;
    readonly setDeliveryAddress: (deliveryAddress: IApiAddress | undefined) => void;
    readonly setPharmacyAddress: (pharmacyAddress: IApiAddress | undefined) => void;
}

const PrescriptionInput: React.FC<Props & PropsFromRedux> = ({
    deliveryAddress,
    pharmacyAddress,
    savedAddresses,
    setDeliveryAddress,
    setPharmacyAddress,
}) => (
    <Box>
        <Box className="form-group">
            <AddressSearchBar
                parentCallback={setPharmacyAddress}
                placeholder="Pharmacy address that has prescription"
                value={pharmacyAddress}
            />
        </Box>
        <Box className="form-group">
            <AddressSearchBar
                parentCallback={setDeliveryAddress}
                placeholder="Delivery address"
                value={deliveryAddress}
            />
        </Box>
        <Box className="form-group">
            <Typography align="left" component="p" className="info-text">
                We’ll text you before purchasing to confirm your prescription with you, the pharmacy, and insurance.{' '}
            </Typography>
        </Box>
    </Box>
);

export default connector(PrescriptionInput);
