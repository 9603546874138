import {
    ADD_ADDRESS_ERROR,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGIN,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP,
    UPDATE_HIDE_SERVICES,
    UPDATE_LOGGEDIN_USER_DATA_SUCCESS,
} from '../constants';
import { UserState, UserActionTypes } from '../types';

const initialState: UserState = {
    isFetching: false,
};

export default (state = initialState, action: UserActionTypes): UserState => {
    type ActionConverter<A> = Extract<UserActionTypes, { type: A }>;

    const { type } = action;
    switch (type) {
        /* –––––––––– Login */
        case LOGIN:
            return {
                ...state,
                isFetching: true,
                loggedInUser: undefined,
                loginErrorMessage: undefined,
            };

        case LOGIN_ERROR: {
            const { loginErrorMessage } = action as ActionConverter<typeof type>;

            return { ...state, loginErrorMessage, isFetching: false };
        }

        case LOGIN_SUCCESS: {
            const { data } = action as ActionConverter<typeof type>;

            return {
                ...state,
                isFetching: false,
                loggedInUser: data,
            };
        }

        /* –––––––––– Signup */
        case SIGNUP:
            return {
                ...state,
                isFetching: true,
                signUpErrorMessage: undefined,
                signUpState: 'pending',
            };

        case SIGNUP_ERROR: {
            const { signUpErrorMessage } = action as ActionConverter<typeof type>;

            return {
                ...state,
                signUpErrorMessage,
                isFetching: false,
                signUpState: 'error',
            };
        }

        case SIGNUP_SUCCESS: {
            return {
                ...state,
                signUpErrorMessage: undefined,
                isFetching: false,
                signUpState: 'success',
            };
        }

        case CHANGE_PASSWORD: {
            return {
                ...state,
                passwordChangeState: 'pending',
                passwordChangeErrorMessage: undefined,
            };
        }

        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                passwordChangeState: 'success',
            };
        }

        case CHANGE_PASSWORD_ERROR: {
            const {
                data: { passwordChangeErrorMessage },
            } = action as ActionConverter<typeof type>;

            return {
                ...state,
                passwordChangeState: 'error',
                passwordChangeErrorMessage,
            };
        }

        case RESET_PASSWORD: {
            return {
                ...state,
                passwordResetState: 'pending',
                passwordResetErrorMessage: undefined,
            };
        }

        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                passwordResetState: 'success',
            };
        }

        case RESET_PASSWORD_ERROR: {
            const {
                data: { passwordResetErrorMessage },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                passwordResetState: 'error',
                passwordResetErrorMessage,
            };
        }

        case UPDATE_LOGGEDIN_USER_DATA_SUCCESS: {
            const {
                data: { userInfo },
            } = action as ActionConverter<typeof type>;
            const { loggedInUser } = state;
            if (!loggedInUser) {
                throw new Error('loggedInUser is undefined');
            }
            return {
                ...state,
                loggedInUser: {
                    ...loggedInUser,
                    userInfo,
                },
            };
        }

        /* –––––––––– Add address */
        case ADD_ADDRESS:
            return {
                ...state,
                isFetching: true,
                addAddressErrorMessage: undefined,
            };

        case ADD_ADDRESS_ERROR: {
            const { addAddressErrorMessage } = action as ActionConverter<typeof type>;

            return { ...state, addAddressErrorMessage, isFetching: false };
        }

        case ADD_ADDRESS_SUCCESS: {
            return { ...state, isFetching: false, addAddressErrorMessage: undefined };
        }

        case UPDATE_HIDE_SERVICES: {
            const { hideServices } = action as ActionConverter<typeof type>;
            const { loggedInUser } = state;
            if (loggedInUser) {
                const { userInfo } = loggedInUser;
                return {
                    ...state,
                    loggedInUser: {
                        ...loggedInUser,
                        userInfo: {
                            ...userInfo,
                            hideServices,
                        },
                    },
                };
            } else {
                return state;
            }
        }

        default:
            return state;
    }
};
