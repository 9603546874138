import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';

import { RootState } from '../../state';

import './styles.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => ({
    phoneNumber: loggedInUser?.userInfo.phoneNumber,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    readonly headerImage: string;
    readonly nextSteps: (params: { phoneNumber: string }) => string[];
    readonly onCloseModal: () => void;
    readonly showModal: boolean;
    readonly title: string;
}

const RequestSubmittedModal: React.FC<Props & PropsFromRedux> = ({
    headerImage,
    nextSteps,
    onCloseModal,
    phoneNumber,
    showModal,
    title,
}) => (
    <Dialog
        aria-describedby="request-submitted-modal-content"
        aria-labelledby="request-submitted-modal-title"
        open={showModal}
        className="RequestSubmittedModal__dialog"
    >
        <DialogTitle id="request-submitted-modal-title" className="RequestSubmittedModal__title">
            <img src={headerImage} className="RequestSubmittedModal__header-image" />
            <Typography variant="h5" className="RequestSubmittedModal__title-text">
                {title}
            </Typography>
        </DialogTitle>
        <DialogContent className="RequestSubmittedModal__content" id="request-submitted-modal-content">
            <Grid container justify="space-between">
                <Grid item xs={12} sm={8}>
                    <Typography variant="h6" className="RequestSubmittedModal__question">
                        What’s next?
                    </Typography>
                    <List component="ol" className="RequestSubmittedModal__answer">
                        {phoneNumber
                            ? nextSteps({ phoneNumber }).map((step, index) => (
                                  <ListItem key={index}>
                                      <ListItemText>
                                          {index + 1}- {step}
                                      </ListItemText>
                                  </ListItem>
                              ))
                            : null}
                    </List>
                    <Typography variant="h6" className="RequestSubmittedModal__question">
                        When will you get back to me?
                    </Typography>
                    <Typography variant="body1" className="RequestSubmittedModal__answer">
                        We will get back to you in 10 - 15 min
                    </Typography>
                    <Typography variant="h6" className="RequestSubmittedModal__question">
                        Are there service fees?
                    </Typography>
                    <Typography variant="body1" className="RequestSubmittedModal__answer">
                        The service and delivery fees are charged by 3rd party services, such as Instacart. CAN Home
                        does not add additional service fees :)
                    </Typography>
                </Grid>
                <Grid container item xs={12} sm={4} justify="center">
                    <img src="img/request-submitted-modal-decor.png" />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid
                alignItems="center"
                className="RequestSubmittedModal__button-container"
                container
                direction="column"
                justify="center"
                spacing={0}
            >
                <Button onClick={onCloseModal} autoFocus className="dashboard-btn" disableRipple>
                    okay
                </Button>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default connector(RequestSubmittedModal);
