import { DispatchType } from './';
import {
    CAREGIVER_ORDER_SUBMIT_ERROR,
    CAREGIVER_ORDER_SUBMIT_PENDING,
    CAREGIVER_ORDER_SUBMIT_SUCCESS,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING,
    DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS,
    GROCERIES_ORDER_SUBMIT_ERROR,
    GROCERIES_ORDER_SUBMIT_PENDING,
    GROCERIES_ORDER_SUBMIT_SUCCESS,
    MEDICATIONS_ORDER_SUBMIT_ERROR,
    MEDICATIONS_ORDER_SUBMIT_PENDING,
    MEDICATIONS_ORDER_SUBMIT_SUCCESS,
    RIDES_ORDER_SUBMIT_ERROR,
    RIDES_ORDER_SUBMIT_PENDING,
    RIDES_ORDER_SUBMIT_SUCCESS,
} from './constants';

interface IApiWithSubmitOrder<IParams> {
    submitOrder(params: IParams): Promise<void>;
}

export const submitOrderHelper = <IParams>(
    pending_action_type:
        | typeof CAREGIVER_ORDER_SUBMIT_PENDING
        | typeof DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING
        | typeof GROCERIES_ORDER_SUBMIT_PENDING
        | typeof MEDICATIONS_ORDER_SUBMIT_PENDING
        | typeof RIDES_ORDER_SUBMIT_PENDING,
    success_action_type:
        | typeof CAREGIVER_ORDER_SUBMIT_SUCCESS
        | typeof DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS
        | typeof GROCERIES_ORDER_SUBMIT_SUCCESS
        | typeof MEDICATIONS_ORDER_SUBMIT_SUCCESS
        | typeof RIDES_ORDER_SUBMIT_SUCCESS,
    error_action_type:
        | typeof CAREGIVER_ORDER_SUBMIT_ERROR
        | typeof DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR
        | typeof GROCERIES_ORDER_SUBMIT_ERROR
        | typeof MEDICATIONS_ORDER_SUBMIT_ERROR
        | typeof RIDES_ORDER_SUBMIT_ERROR
) => (dispatch: DispatchType) => async (api: IApiWithSubmitOrder<IParams>, params: IParams) => {
    try {
        dispatch({
            type: pending_action_type,
        });
        await api.submitOrder(params);
        dispatch({
            type: success_action_type,
        });
        return true;
    } catch (err) {
        dispatch({
            type: error_action_type,
            data: {
                errorMessage: 'Something went wrong',
            },
        });
    }
    return false;
};
