import React from 'react';
import { Typography, Grid, Button, TextField, Link } from '@material-ui/core';
import caneFrontPanelImage from '../../../assets/images/cane_front_panel.png';
import 'react-phone-number-input/style.css';

interface ComponentProps {
    pin: any;
    checkPin: any;
    onChange: any;
    buttonDisabled?: any;
    errorMessage: any;
}

const CheckPin = (props: ComponentProps): JSX.Element => {
    const privacyPolicy = 'https://www.canmobilities.com/privacy';
    const termsOfUse = 'https://www.canmobilities.com/terms';

    return (
        <form
            className="form"
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
                props.checkPin();
            }}
        >
            <Typography className="main-text" component="h1" variant="h4">
                Let’s activate your CAN Go&trade;
            </Typography>
            <Typography component="h1" variant="subtitle1" className="explanation-text">
                Press the scroll button for 5 seconds to view the PIN number. Enter the PIN number below.
            </Typography>
            <Grid container justify="center">
                <img className="cane-front-panel-image" src={caneFrontPanelImage} alt="Cane front panel" />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="devicePIN"
                        label="PIN Number"
                        type="devicePIN"
                        id="devicePIN"
                        defaultValue={props.pin}
                        onChange={props.onChange}
                        inputProps={{
                            maxLength: 6,
                        }}
                        InputProps={{
                            className: 'input input-pin',
                        }}
                    />
                </Grid>
            </Grid>
            <Typography className="error-message" component="h1" variant="subtitle1">
                {props.errorMessage}
            </Typography>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className="submit-btn"
                disabled={props.buttonDisabled}
                onClick={() => props.checkPin()}
            >
                Next Step
            </Button>
            <Grid container justify="flex-end" className="bottom-links">
                <Grid item>
                    <Link href="/#/login" variant="body2">
                        Already have an account? Sign in
                    </Link>
                </Grid>
            </Grid>
            <div className="terms-footer gray-text">
                By continuing, you agree to the{' '}
                <a href={termsOfUse} target="_blank" rel="noopener noreferrer" className="gray-text">
                    Term of Use
                </a>
                &nbsp; and{' '}
                <a href={privacyPolicy} target="_blank" rel="noopener noreferrer" className="gray-text">
                    Privacy Policy
                </a>
            </div>
        </form>
    );
};

export default CheckPin;
