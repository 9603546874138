import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { History } from 'history';

import {
    Box,
    Button,
    Checkbox,
    CssBaseline,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from '@material-ui/core/';

import { loginAction } from '../../../state/actions/userActions';
import { DispatchType, RootState } from '../../../state';
import { ApisContext } from '../../../lib/contexts';
import { persistor } from '../../../state/store';

import PanelWithImage from '../PanelWithImage/PanelWithImage';
import logo from '../../../assets/images/logo.png';

import './Login.scss';

const mapState = ({ user: { loginErrorMessage } }: RootState) => ({ loginErrorMessage });

const mapDispatchToProps = (dispatch: DispatchType) => ({
    login: loginAction(dispatch),
});

const connector = connect(mapState, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// interfaces
interface Props {
    history: History;
}

const LogIn: React.FC<Props & PropsFromRedux> = ({ login, history, loginErrorMessage }) => {
    const { userApi } = useContext(ApisContext);
    const [state, setState] = useState({
        email: '',
        password: '',
        rememberMe: true,
        errorMessage: '',
    });

    useEffect(
        () =>
            loginErrorMessage
                ? setState({
                      ...state,
                      errorMessage: loginErrorMessage,
                  })
                : undefined,
        [loginErrorMessage]
    );

    const formLogin = async () => {
        // clear error message
        setState((prevState) => {
            return { ...prevState, errorMessage: '' };
        });

        // checking inputs
        if (!state.email || !state.password) {
            return setState((prevState) => {
                return { ...prevState, errorMessage: 'Email and Password are required fields' };
            });
        }

        const { email, password } = state;

        // calling login action
        if (await login(userApi, { email, password })) {
            history.push('/');
        }
    };

    const enterFunction = (event: any) => {
        if (event.keyCode === 13) {
            formLogin();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', enterFunction, false);
        return () => {
            document.removeEventListener('keydown', enterFunction, false);
        };
    }, [enterFunction]);

    return (
        <Grid container className="root login">
            <CssBaseline />
            <PanelWithImage />
            <Grid container item alignItems="center" justify="center" className="right-panel-container" xs={12} md={6}>
                <Box className="right-panel">
                    <Box>
                        <img className="logo" src={logo} alt="CAN logo" />
                    </Box>
                    <form className="form" noValidate>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            placeholder="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            InputProps={{
                                className: 'input',
                            }}
                            onChange={({ target: { value: email } }) =>
                                setState((prevState) => ({ ...prevState, email }))
                            }
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            placeholder="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                className: 'input',
                            }}
                            onChange={({ target: { value: password } }) =>
                                setState((prevState) => ({ ...prevState, password }))
                            }
                        />
                        <Box className="login__checkbox-container">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        checked={state.rememberMe}
                                        color="primary"
                                        onChange={({ target: { checked: rememberMe } }) => {
                                            if (rememberMe) {
                                                persistor.persist();
                                            } else {
                                                persistor.pause();
                                            }
                                            setState((prevState) => ({
                                                ...prevState,
                                                rememberMe,
                                            }));
                                        }}
                                    />
                                }
                                label="Remember me"
                                className="login__remember-me"
                            />
                        </Box>
                        {state.errorMessage && (
                            <Box className="error-message-container">
                                <Typography className="error-message" component="h1" variant="subtitle1">
                                    {state.errorMessage}
                                </Typography>
                            </Box>
                        )}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="submit-btn"
                            onClick={formLogin}
                            id="loginButton"
                        >
                            Log In
                        </Button>
                        <Grid container direction="row" justify="space-between" className="bottom-links">
                            <Grid item xs className="no-wrap">
                                <Link href="/#/forgot-password" variant="body2" className="no-wrap">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/#/sign-up" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
};

export default connector(LogIn);
