import { IRidesSubmitOrderParams } from '../../lib/api';
import { IApiAddress, RideType, IRideCart } from '../../lib/types';

import { DispatchType } from '../';
import {
    RIDES_CLEAR_CART,
    RIDES_ORDER_SUBMIT_ERROR,
    RIDES_ORDER_SUBMIT_PENDING,
    RIDES_ORDER_SUBMIT_SUCCESS,
    RIDES_REORDER_CART,
    RIDES_SET_CART_DROPOFF,
    RIDES_SET_CART_PICKUP,
    RIDES_SET_CART_SERVICE,
} from '../constants';
import { submitOrderHelper } from '../utils';

export const submitRidesOrderAction = submitOrderHelper<IRidesSubmitOrderParams>(
    RIDES_ORDER_SUBMIT_PENDING,
    RIDES_ORDER_SUBMIT_SUCCESS,
    RIDES_ORDER_SUBMIT_ERROR
);

export const setRideCartDropoffAction = (dispatch: DispatchType) => (ridesDropoff: IApiAddress | undefined) =>
    dispatch({
        type: RIDES_SET_CART_DROPOFF,
        data: {
            ridesDropoff,
        },
    });

export const setRideCartPickupAction = (dispatch: DispatchType) => (ridesPickup: IApiAddress | undefined) =>
    dispatch({
        type: RIDES_SET_CART_PICKUP,
        data: {
            ridesPickup,
        },
    });

export const setRideCartRideServiceAction = (dispatch: DispatchType) => (ridesService: RideType) =>
    dispatch({
        type: RIDES_SET_CART_SERVICE,
        data: {
            ridesService,
        },
    });

export const clearRideCartAction = (dispatch: DispatchType) => () =>
    dispatch({
        type: RIDES_CLEAR_CART,
    });

export const reorderRideCartAction = (dispatch: DispatchType) => (rideCart: IRideCart) =>
    dispatch({
        type: RIDES_REORDER_CART,
        data: {
            rideCart,
        },
    });
