import { GroceriesState, GroceriesActionType } from '../types';
import {
    GROCERIES_ADD_AVAILABLE_PRODUCTS,
    GROCERIES_ADD_PRODUCT_TO_CART,
    GROCERIES_CLEAR_CART,
    GROCERIES_ORDER_SUBMIT_ERROR,
    GROCERIES_ORDER_SUBMIT_PENDING,
    GROCERIES_ORDER_SUBMIT_SUCCESS,
    GROCERIES_REMOVE_PRODUCT_FROM_CART,
    GROCERIES_REORDER_CART,
} from '../constants';

const initialState: GroceriesState = {
    availableProducts: [],
    groceryCart: {},
    submitOrder: {},
};

export default (state = initialState, action: GroceriesActionType): GroceriesState => {
    const { type } = action;
    type ActionConverter<A> = Extract<GroceriesActionType, { type: A }>;

    switch (type) {
        case GROCERIES_ORDER_SUBMIT_PENDING: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'pending',
                    errorMessage: undefined,
                },
            };
        }
        case GROCERIES_ORDER_SUBMIT_SUCCESS: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'success',
                    errorMessage: undefined,
                },
            };
        }
        case GROCERIES_ORDER_SUBMIT_ERROR: {
            const {
                data: { errorMessage },
            } = action as ActionConverter<typeof type>;
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'error',
                    errorMessage,
                },
            };
        }
        case GROCERIES_ADD_AVAILABLE_PRODUCTS: {
            const {
                data: { products },
            } = action as ActionConverter<typeof type>;
            const { availableProducts } = state;
            return {
                ...state,
                availableProducts: [...availableProducts, ...products],
            };
        }
        case GROCERIES_ADD_PRODUCT_TO_CART: {
            const {
                data: { product, count: inputCount },
            } = action as ActionConverter<typeof type>;
            const { id } = product;
            const { groceryCart } = state;
            const count = inputCount || (groceryCart[id]?.count || 0) + 1;

            return {
                ...state,
                groceryCart: {
                    ...groceryCart,
                    [id]: {
                        count,
                        product,
                    },
                },
            };
        }
        case GROCERIES_REMOVE_PRODUCT_FROM_CART: {
            const {
                data: { product, all },
            } = action as ActionConverter<typeof type>;
            const { id } = product;
            const { groceryCart } = state;
            const count = (groceryCart[id]?.count || 0) - 1;

            return {
                ...state,
                groceryCart: {
                    ...groceryCart,
                    [id]:
                        !all && count > 0
                            ? {
                                  count,
                                  product,
                              }
                            : undefined,
                },
            };
        }
        case GROCERIES_CLEAR_CART: {
            return {
                ...state,
                groceryCart: {},
            };
        }

        case GROCERIES_REORDER_CART: {
            const {
                data: { groceryCart },
            } = action as ActionConverter<typeof type>;

            return {
                ...state,
                groceryCart,
            };
        }

        default:
            return state;
    }
};
