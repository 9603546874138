import React, { useState, useEffect } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';

import AddressAutoFill from '../AddressAutoFill/AddressAutoFill';

import { HomeAddressParams, AddressAutoFillObjectType } from '../../lib/types';

interface Props {
    value?: HomeAddressParams;
    onClose: () => void;
    onSave: (address: HomeAddressParams) => void;
    open: boolean;
}

const AddAddressDialog: React.FC<Props> = ({ onClose, onSave, open, value }) => {
    const [addressParams, setAddressParams] = useState<HomeAddressParams>({});

    useEffect(() => {
        if (value) {
            setAddressParams(value);
        }
    }, [value]);

    const { address, addressState, addressZipCode, addressNickname, secondAddressLine } = addressParams;
    const addressAutoFillValue: AddressAutoFillObjectType | undefined = address
        ? {
              ...addressParams,
              address,
          }
        : undefined;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="add-address"
            aria-describedby="add-addresses-modal"
            PaperProps={{
                style: { padding: 20, overflow: 'visible' },
            }}
        >
            <DialogTitle>
                <Box textAlign="center">Add address for delivery and ride services</Box>
            </DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <AddressAutoFill
                                baseInput
                                placeholder="Street address"
                                parentCallback={(addressObject) => {
                                    if (addressObject) {
                                        setAddressParams({
                                            ...addressParams,
                                            ...addressObject,
                                        });
                                    } else {
                                        setAddressParams({
                                            addressNickname,
                                        });
                                    }
                                }}
                                value={addressAutoFillValue}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                type="text"
                                className="dashboard-input"
                                placeholder="Apartment, suite, unit, building, floor, etc."
                                name="secondAddressLine"
                                onChange={({ target: { value: secondAddressLine } }) =>
                                    setAddressParams({
                                        ...addressParams,
                                        secondAddressLine,
                                    })
                                }
                                value={secondAddressLine || ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <input
                                type="text"
                                className="dashboard-input"
                                placeholder="State"
                                name="addressState"
                                onChange={({ target: { value: addressState } }) =>
                                    setAddressParams({
                                        ...addressParams,
                                        addressState,
                                    })
                                }
                                value={addressState || ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <input
                                type="text"
                                className="dashboard-input"
                                placeholder="Zip code"
                                name="addressZipCode"
                                onChange={({ target: { value: addressZipCode } }) =>
                                    setAddressParams({
                                        ...addressParams,
                                        addressZipCode,
                                    })
                                }
                                value={addressZipCode || ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                type="text"
                                className="dashboard-input"
                                placeholder="Name (ex. mom's house)"
                                name="addressNickname"
                                onChange={({ target: { value: addressNickname } }) =>
                                    setAddressParams({
                                        ...addressParams,
                                        addressNickname,
                                    })
                                }
                                value={addressNickname || ''}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (value) {
                            setAddressParams(value);
                        }
                        onClose();
                    }}
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button onClick={() => onSave(addressParams)} color="primary" autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddAddressDialog;
