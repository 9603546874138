import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import './header.scss';

const Header = (): JSX.Element => {
    return (
        <Box className="can-header">
            <Box role="banner" className="can-header__navbar">
                <Grid container alignItems="center" justify="space-between">
                    <Grid item xs={6}>
                        <a href="./" className="can-header__navbar__logo">
                            <h4>
                                <span>CAN</span>HOME
                            </h4>
                        </a>
                    </Grid>
                    <Grid container item xs={6} justify="flex-end">
                        <Box>
                            <nav role="navigation" className="can-header__navbar__navigation">
                                <Link to="/sign-up" className="nav-btn" id="signUpLink">
                                    <span>Sign up</span>
                                    <span className="small">and get $10 OFF the first order</span>
                                </Link>
                                <a href="tel:6506006466">
                                    <span className="call_to_signup">or Call +1 (650) 600-6466</span>
                                </a>
                            </nav>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Header;
