import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';

// styles
import './styles.scss';

const Landing = (): JSX.Element => {
    return (
        <Box className="can-landing">
            <Header />
            <Box className="can-landing__hero-section">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <img
                            src="img/ezgif.com-webp-to-png.png"
                            srcSet="img/ezgif.com-webp-to-png-p-500.png 500w, img/ezgif.com-webp-to-png-p-800.png 800w, img/ezgif.com-webp-to-png-p-1080.png 1080w, img/ezgif.com-webp-to-png-p-1600.png 1600w, img/ezgif.com-webp-to-png.png 1680w"
                            sizes="(max-width: 767px) 100vw, 50vw"
                            alt="CAN Mobilities"
                        />
                    </Grid>
                    <Grid container item xs={12} md={6} alignItems="center" justify="center">
                        <h1 className="heading-2">
                            Meet Mark and Mary. They&#x27;re loving life!  Why? Because they have all the services of a
                            senior home in the comfort of their own home.
                        </h1>
                    </Grid>
                </Grid>
            </Box>
            <Box className="can-landing__support-section">
                <Box>
                    <Box className="support-top-wrapper">
                        <h2 className="support-top-heading">One-Stop-Shop for Essential Needs</h2>
                    </Box>
                    <Box className="support-wrapper">
                        <img
                            src="img/Laptop.png"
                            srcSet="img/Laptop-p-500.png 500w, img/Laptop-p-800.png 800w, img/Laptop-p-1080.png 1080w, img/Laptop-p-1600.png 1600w, img/Laptop-p-2000.png 2000w, img/Laptop.png 2465w"
                            sizes="(max-width: 3081px) 80vw, 2465px"
                            alt=""
                            className="image-3"
                        />
                    </Box>
                    <Box>
                        <p className="paragraph">
                            CAN Home is an online one-stop-shop for seniors. Family members or seniors can order
                            groceries for delivery, book doctor appointments, manage medications, arrange rides, and
                            more.
                        </p>
                        <p className="paragraph">
                            <br />
                        </p>
                        <p className="paragraph">
                            The best part? Essentials for seniors in a central dashboard, all available in just a few
                            clicks.  It&#x27;s simple, convenient and gives you peace of mind.
                        </p>
                    </Box>
                </Box>
            </Box>
            <Box id="hero" className="can-landing__services-section">
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box>
                            <h1 className="heading-3">The services of a senior home in the comfort of your own home</h1>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className="hero-image-mask">
                            <img src="img/ezgif.com-webp-to-png-1.png" alt="" className="hero-image" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="can-landing__shop-local-section">
                <Box className="shop-local-container">
                    <Box className="shop-local-wrapper">
                        <h1 className="heading-4">
                            Know someone who needs this?
                            <br />
                            Share it now.
                        </h1>
                        <Box textAlign="center">
                            <FacebookShareButton
                                className="w-inline-block"
                                url="https://canhome.co"
                                quote="All the services of a senior home, but at your own home."
                            >
                                <FacebookIcon round={false}></FacebookIcon>
                            </FacebookShareButton>

                            <TwitterShareButton
                                className="w-inline-block"
                                url="https://canhome.co"
                                title="All the services of a senior home, but at your own home."
                            >
                                <TwitterIcon round={false}></TwitterIcon>
                            </TwitterShareButton>

                            <WhatsappShareButton
                                className="w-inline-block"
                                url="https://canhome.co"
                                title="All the services of a senior home, but at your own home."
                            >
                                <WhatsappIcon round={false}></WhatsappIcon>
                            </WhatsappShareButton>

                            <EmailShareButton
                                className="w-inline-block"
                                url="https://canhome.co"
                                subject="All the services of a senior home, but at your own home."
                            >
                                <EmailIcon round={false}></EmailIcon>
                            </EmailShareButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

export default Landing;
