import { IInsuranceProvider, IInsuranceProviderParams } from '../types';

import { IApiBase } from './api_base';

export interface IGetInsuranceProvidersParams {
    userId: string;
}

export interface IGetInsuranceProvidersResult {
    insuranceProviders: IInsuranceProvider[];
}

export interface IAddInsuranceProviderParams {
    insuranceProvider: IInsuranceProviderParams;
    userId: string;
}

export interface IRemoveInsuranceProviderParams {
    id: string;
    userId: string;
}

export interface IEditInsuranceProviderParams {
    insuranceProvider: IInsuranceProvider;
    userId: string;
}

export interface IInsuranceApi {
    addInsuranceProvider(params: IAddInsuranceProviderParams): Promise<void>;
    getInsuranceProviders(params: IGetInsuranceProvidersParams): Promise<IGetInsuranceProvidersResult>;
    removeInsuranceProvider(params: IRemoveInsuranceProviderParams): Promise<void>;
    editInsuranceProvider(params: IEditInsuranceProviderParams): Promise<void>;
}

export class InsuranceApi implements IInsuranceApi {
    protected apiBase: IApiBase;

    constructor(apiBase: IApiBase) {
        this.apiBase = apiBase;
    }

    async getInsuranceProviders({ userId }: IGetInsuranceProvidersParams): Promise<IGetInsuranceProvidersResult> {
        const { data: insuranceProviders } = await this.apiBase.get(`/api/users/${userId}/getInsuranceProviders`);
        if (Array.isArray(insuranceProviders)) {
            return {
                insuranceProviders: insuranceProviders.filter(({ id }: { id: any }) => typeof id === 'string'),
            };
        }
        return {
            insuranceProviders: [],
        };
    }

    async addInsuranceProvider({ insuranceProvider, userId }: IAddInsuranceProviderParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/addInsuranceProvider`, insuranceProvider);
    }

    async removeInsuranceProvider({ id, userId }: IRemoveInsuranceProviderParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/removeInsuranceProvider`, { id });
    }

    async editInsuranceProvider({ insuranceProvider, userId }: IEditInsuranceProviderParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/editInsuranceProvider`, insuranceProvider);
    }
}
