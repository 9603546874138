import React, { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Intercom from 'react-intercom';
import Dialog from '@material-ui/core/Dialog';

import { MenuItem } from './Nav';
import Dashboard from './dashboard';
import Settings from '../Settings';
import OrderHistory from '../OrderHistory';
import Onboarding from './Onboarding/Onboarding';
import MainLayout from './MainLayout';

import { getEnvironmentVariableOrThrow } from '../../lib/utils';
import { RootState } from '../../state';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const {
            userInfo: { firstName, lastName, email, isFirstVisit },
            userId,
        } = loggedInUser;
        return {
            email,
            firstName,
            isFirstVisit,
            lastName,
            userId,
        };
    }
    return undefined;
};

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Main: React.FC<PropsFromRedux> = ({ firstName, lastName, userId, email, isFirstVisit }) => {
    const authData: any = {}; // TODO implement API class

    const [activeMenuItem, setActiveMenuItem] = useState<MenuItem>('dashboard');

    const [state, setState] = useState({
        stateUpdates: 0,
        sidebarCollapsed: false,
        sidebarActive: true,
        userInfo: null,
    });

    function refresh() {
        return fetch(`baseURL/api/users/${authData.currentUserId}`, {
            method: 'GET',
            headers: {
                authorization: authData.accessToken as any,
            },
        })
            .then((res) => res.json())
            .then((userData) => {
                if (!userData.error) {
                    setState((prevState) => {
                        return { ...prevState, stateUpdates: state.stateUpdates += 1 };
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // <!-- Collapse left -->
    function toggleSidebar() {
        const currentState = state.sidebarActive;

        setState((prevState) => {
            return { ...prevState, sidebarActive: !currentState };
        });
    }

    let activeContent: React.ReactNode = null;
    let pageTitle = '';
    let pageText = '';

    switch (activeMenuItem) {
        case 'dashboard': {
            activeContent = <Dashboard user={{} as any} refresh={refresh} />;
            pageTitle = `Welcome home, ${firstName}`;
            break;
        }
        case 'settings': {
            activeContent = <Settings />;
            pageTitle = 'Settings';
            pageText = 'Adjust account settings';
            break;
        }
        case 'orderHistory': {
            activeContent = <OrderHistory goToDashboard={() => setActiveMenuItem('dashboard')} />;
            pageTitle = 'Order History';
            break;
        }
    }

    return (
        <>
            <MainLayout
                activeMenuItem={activeMenuItem}
                onMenuItemChange={setActiveMenuItem}
                sidebarActive={state.sidebarActive}
                toggleSidebar={toggleSidebar}
                pageTitle={pageTitle}
                pageText={pageText}
            >
                {activeContent}
            </MainLayout>
            {isFirstVisit && (
                <Dialog open maxWidth={false}>
                    <Onboarding />
                </Dialog>
            )}
            {process.env.REACT_APP_INTERCOM_ID && (
                <Intercom
                    appID={getEnvironmentVariableOrThrow('REACT_APP_INTERCOM_ID')}
                    email={email}
                    name={`${firstName} ${lastName}`}
                    user_id={userId}
                />
            )}
        </>
    );
};

export default connector(Main);
