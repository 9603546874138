import { CaregiverState, CaregiverActionType } from '../types';
import {
    CAREGIVER_CLEAR_CART,
    CAREGIVER_ORDER_SUBMIT_ERROR,
    CAREGIVER_ORDER_SUBMIT_PENDING,
    CAREGIVER_ORDER_SUBMIT_SUCCESS,
    CAREGIVER_REORDER_CART,
    CAREGIVER_SET_CART_DELIVERY_ADDRESS,
    CAREGIVER_SET_CART_END_DATE,
    CAREGIVER_SET_CART_INSTRUCTIONS,
    CAREGIVER_SET_CART_START_DATE,
} from '../constants';

const INITIAL_CAREGIVER_VISIT_CART = {};

const initialState: CaregiverState = {
    submitOrder: {},
    caregiverVisitCart: {
        ...INITIAL_CAREGIVER_VISIT_CART,
    },
};

export default (state = initialState, action: CaregiverActionType): CaregiverState => {
    const { type } = action;
    type ActionConverter<A> = Extract<CaregiverActionType, { type: A }>;

    switch (type) {
        case CAREGIVER_ORDER_SUBMIT_PENDING: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'pending',
                    errorMessage: undefined,
                },
            };
        }
        case CAREGIVER_ORDER_SUBMIT_SUCCESS: {
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'success',
                    errorMessage: undefined,
                },
            };
        }
        case CAREGIVER_ORDER_SUBMIT_ERROR: {
            const {
                data: { errorMessage },
            } = action as ActionConverter<typeof type>;
            const { submitOrder } = state;
            return {
                ...state,
                submitOrder: {
                    ...submitOrder,
                    state: 'error',
                    errorMessage,
                },
            };
        }
        case CAREGIVER_SET_CART_DELIVERY_ADDRESS: {
            const {
                data: { deliveryAddress },
            } = action as ActionConverter<typeof type>;
            const { caregiverVisitCart } = state;
            return {
                ...state,
                caregiverVisitCart: {
                    ...caregiverVisitCart,
                    deliveryAddress,
                },
            };
        }
        case CAREGIVER_SET_CART_END_DATE: {
            const {
                data: { endDate },
            } = action as ActionConverter<typeof type>;
            const { caregiverVisitCart } = state;
            return {
                ...state,
                caregiverVisitCart: {
                    ...caregiverVisitCart,
                    endDate,
                },
            };
        }
        case CAREGIVER_SET_CART_INSTRUCTIONS: {
            const {
                data: { instructions },
            } = action as ActionConverter<typeof type>;
            const { caregiverVisitCart } = state;
            return {
                ...state,
                caregiverVisitCart: {
                    ...caregiverVisitCart,
                    instructions,
                },
            };
        }
        case CAREGIVER_SET_CART_START_DATE: {
            const {
                data: { startDate },
            } = action as ActionConverter<typeof type>;
            const { caregiverVisitCart } = state;
            return {
                ...state,
                caregiverVisitCart: {
                    ...caregiverVisitCart,
                    startDate,
                },
            };
        }
        case CAREGIVER_CLEAR_CART: {
            return {
                ...state,
                caregiverVisitCart: {
                    ...INITIAL_CAREGIVER_VISIT_CART,
                },
            };
        }
        case CAREGIVER_REORDER_CART: {
            const {
                data: { caregiverVisitCart },
            } = action as ActionConverter<typeof type>;
            return {
                ...state,
                caregiverVisitCart,
            };
        }

        default:
            return state;
    }
};
