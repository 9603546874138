import React, { useContext, useEffect, useState, forwardRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Box, Button, Grid, Typography } from '@material-ui/core';
// import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';

import moment from 'moment';

import AddressSearchBar from '../AddressSearchBar/AddressSearchBar';
import RequestSubmittedModal from '../RequestSubmittedModal';
import TimePicker from '../TimePicker';
import Card from '../Card/Card';

// React Date / Timepicker styles
import 'react-datepicker/dist/react-datepicker.css';

import { ApisContext } from '../../lib/contexts';
import { DispatchType, RootState } from '../../state';
import {
    clearCaregiverVisitCartAction,
    setCaregiverVisitCartDeliveryAddressAction,
    setCaregiverVisitCartEndDateAction,
    setCaregiverVisitCartInstructionsAction,
    setCaregiverVisitCartStartDateAction,
    submitCaregiverOrderAction,
} from '../../state/actions/caregiverActions';

const mapState = ({
    caregiver: {
        submitOrder: { state: submitOrderState, errorMessage: submitOrderErrorMessage },
        caregiverVisitCart: { deliveryAddress, endDate, instructions, startDate },
    },
    user: { loggedInUser },
}: RootState) => ({
    submitOrderErrorMessage,
    submitOrderState,
    userId: loggedInUser?.userId,
    addressList: loggedInUser?.userInfo.addressList,
    deliveryAddress,
    endDate,
    instructions,
    startDate,
});

const mapDispatch = (dispatch: DispatchType) => ({
    clearCaregiverVisitCart: clearCaregiverVisitCartAction(dispatch),
    setCaregiverVisitCartDeliveryAddress: setCaregiverVisitCartDeliveryAddressAction(dispatch),
    setCaregiverVisitCartEndDate: setCaregiverVisitCartEndDateAction(dispatch),
    setCaregiverVisitCartInstructions: setCaregiverVisitCartInstructionsAction(dispatch),
    setCaregiverVisitCartStartDate: setCaregiverVisitCartStartDateAction(dispatch),
    submitOrder: submitCaregiverOrderAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    refresh?: () => void;
}

interface IState {
    errorMessage?: string;
    step: number;
    validData: string;
}

const CaregiverVisitsService: React.FC<Props & PropsFromRedux> = ({
    addressList,
    clearCaregiverVisitCart,
    deliveryAddress,
    endDate,
    instructions,
    setCaregiverVisitCartDeliveryAddress,
    setCaregiverVisitCartEndDate,
    setCaregiverVisitCartInstructions,
    setCaregiverVisitCartStartDate,
    startDate,
    submitOrder,
    submitOrderErrorMessage,
    userId,
}) => {
    const { caregiverApi } = useContext(ApisContext);
    const steps: string[] = ['1', '2'];

    const [state, setState] = useState<IState>({
        step: 0,
        errorMessage: '',
        validData: '',
    });

    useEffect(
        () =>
            setState((prevState) => ({
                ...prevState,
                errorMessage: submitOrderErrorMessage,
            })),
        [submitOrderErrorMessage]
    );

    const lastStep = state.step === steps.length - 1;

    function nextStep() {
        setState((prevState) => {
            // const step = lastStep ? prevState.step : prevState.step +=1;    // stay at end step
            const step = lastStep ? 0 : (prevState.step += 1); // restart
            return { ...prevState, errorMessage: '', step };
        });
    }

    const submitOrderCallback = async () => {
        if (!userId) {
            throw new Error('Missing userId');
        }
        const { step: currentStep } = state;
        if (startDate && endDate && deliveryAddress && currentStep === 0) {
            setState({
                ...state,
                errorMessage: undefined,
            });

            if (
                await submitOrder(caregiverApi, {
                    order: {
                        caregiverVisitCart: {
                            deliveryAddress,
                            endDate,
                            instructions,
                            startDate,
                        },
                    },
                    userId,
                })
            ) {
                clearCaregiverVisitCart();
                const step = currentStep + 1;
                setState({
                    ...state,
                    errorMessage: undefined,
                    step,
                });
            }
        } else {
            setState({
                ...state,
                errorMessage: 'please enter required data',
            });
        }
    };

    const CustomDateInput = forwardRef(
        (props: any, ref: any): JSX.Element => (
            <div id="start" className="datepicker-selection">
                <input
                    type="text"
                    placeholder="Pick a date"
                    value={props.value}
                    onClick={props.onClick}
                    onChange={() => {}}
                />
                <span onClick={props.onClick}>
                    <FaCalendarAlt onClick={props.onClick} />
                </span>
            </div>
        )
    );

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <Box className="module">
                    <Box className="module-top">
                        <h4>Caregiver visits</h4>
                    </Box>

                    {/* based on step */}

                    {state.step === 1 && (
                        <>
                            <Box className="module-fields" textAlign="center">
                                <Typography variant="inherit" component="p" className="confirmation-text">
                                    Your order request is received. We are working on it. We will reach out to you with
                                    your order updates and confirmation. Meanwhile, You can chat with us if you have any
                                    questions.
                                </Typography>
                                <Button className="dashboard-btn" onClick={() => nextStep()}>
                                    OK
                                </Button>
                            </Box>
                        </>
                    )}

                    {state.step === 0 && (
                        <Box className="module-fields">
                            <Box>
                                <Box className="form-group">
                                    <DatePicker
                                        selected={startDate ? new Date(startDate) : undefined}
                                        onChange={(date: Date) => {
                                            setCaregiverVisitCartEndDate(date);
                                            setCaregiverVisitCartStartDate(date);
                                        }}
                                        minDate={new Date()}
                                        placeholderText="pick a date"
                                        customInput={<CustomDateInput />}
                                    />
                                </Box>

                                <Grid container justify="space-between">
                                    <Grid item xs={12} sm={5}>
                                        <Box>
                                            <Box id="starttime" className="form-group">
                                                <TimePicker
                                                    placeholder="Start"
                                                    value={startDate ? moment(startDate) : undefined}
                                                    onChange={(startMoment) =>
                                                        setCaregiverVisitCartStartDate(startMoment?.toDate())
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Box>
                                            <Box id="endtime" className="form-group">
                                                <TimePicker
                                                    placeholder="End"
                                                    value={endDate ? moment(endDate) : undefined}
                                                    onChange={(endMoment) =>
                                                        setCaregiverVisitCartEndDate(endMoment?.toDate())
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box>
                                    <AddressSearchBar
                                        savedAddresses={addressList}
                                        placeholder="Your location"
                                        parentCallback={(deliveryAddress) =>
                                            setCaregiverVisitCartDeliveryAddress(deliveryAddress)
                                        }
                                        value={deliveryAddress}
                                    ></AddressSearchBar>
                                </Box>

                                <Box>
                                    <textarea
                                        name=""
                                        id=""
                                        rows={3}
                                        placeholder="instructions"
                                        value={instructions}
                                        onChange={({ target: { value } }) => setCaregiverVisitCartInstructions(value)}
                                    ></textarea>
                                </Box>
                            </Box>
                            <Box className="module-bot">
                                <Grid container justify="space-between">
                                    <Grid item xs={6} sm={4}>
                                        <Button
                                            disabled={!(startDate && endDate && deliveryAddress)}
                                            disableRipple
                                            onClick={submitOrderCallback}
                                            className="dashboard-btn"
                                        >
                                            Book
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={5} className="module-bot-right">
                                        <p hidden={!!state.errorMessage}>
                                            We’ll text you before booking to confirm the details.
                                        </p>
                                        <Typography
                                            hidden={!state.errorMessage}
                                            color={'error'}
                                            component="h1"
                                            variant="subtitle1"
                                        >
                                            {state.errorMessage}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )}
                    <RequestSubmittedModal
                        headerImage="img/groceries-request-submitted-modal-bg.png"
                        nextSteps={({ phoneNumber }) => [
                            'We will search for reliable hospitals to connect you with a top-rated doctor. If you chose a particular hospital or doctor, we will reach out to them.',
                            `We will text you on ${phoneNumber} with available appointments with details including appointment date and location for your confirmation.`,
                            'You will send us a confirmation via text before booking your appointment.',
                        ]}
                        onCloseModal={nextStep}
                        showModal={state.step === 1}
                        title="Your caregiver service was requested!"
                    />
                </Box>
            </Card>
        </Grid>
    );
};

export default connector(CaregiverVisitsService);
