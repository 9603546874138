import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

interface Props {
    classes: any;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://canmove.com/">
                Can Mobilities
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const styles = (theme: Theme) =>
    createStyles({
        '@global': {
            body: {
                backgroundColor: '#eee2d7',
            },
        },
        'paper': {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        'avatar': {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        'form': {
            width: '100%', // Fix IE 11 issue.
        },
        'submit': {
            margin: theme.spacing(3, 0, 2),
        },
        'header': {
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
        },
    });

class EmailVerified extends Component<Props> {
    render() {
        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div className={classes.header}>
                        <Typography component="h1" variant="h4" align="center">
                            Email verified successfully!
                        </Typography>
                        <Typography component="h1" variant="h5" align="center">
                            You are now ready to use your account.
                        </Typography>
                    </div>
                    <Link href="/" variant="body2">
                        Go to User Application
                    </Link>
                </div>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}

export default withStyles(styles)(EmailVerified);
