import React, { useContext, useState, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import AddressSearchBar from '../AddressSearchBar/AddressSearchBar';
import RideButtonGroup from '../RideButtonGroup/RideButtonGroup';
import RequestSubmittedModal from '../RequestSubmittedModal';

import { ApisContext } from '../../lib/contexts';
import { DispatchType, RootState } from '../../state';
import {
    clearRideCartAction,
    setRideCartDropoffAction,
    setRideCartPickupAction,
    setRideCartRideServiceAction,
    submitRidesOrderAction,
} from '../../state/actions/ridesActions';
import Card from '../Card/Card';

const mapState = ({
    rides: {
        submitOrder: { state: submitOrderState, errorMessage: submitOrderErrorMessage },
        rideCart: { ridesDropoff, ridesPickup, ridesService },
    },
    user: { loggedInUser },
}: RootState) => ({
    addressList: loggedInUser?.userInfo.addressList,
    ridesDropoff,
    ridesPickup,
    ridesService,
    submitOrderErrorMessage,
    submitOrderState,
    userId: loggedInUser?.userId,
});

const mapDispatch = (dispatch: DispatchType) => ({
    clearRideCart: clearRideCartAction(dispatch),
    setRideCartDropoff: setRideCartDropoffAction(dispatch),
    setRideCartPickup: setRideCartPickupAction(dispatch),
    setRideCartRideService: setRideCartRideServiceAction(dispatch),
    submitOrder: submitRidesOrderAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    refresh?: () => void;
}

interface State {
    rideErrorMessage?: string;
    step: number;
    validData: boolean;
}

const RidesService: React.FC<Props & PropsFromRedux> = ({
    addressList,
    clearRideCart,
    ridesDropoff,
    ridesPickup,
    ridesService,
    setRideCartDropoff,
    setRideCartPickup,
    setRideCartRideService,
    submitOrder,
    submitOrderErrorMessage,
    userId,
}) => {
    const { ridesApi } = useContext(ApisContext);
    const steps = ['1', '2'];

    const [state, setState] = useState<State>({
        step: 0,
        validData: false,
    });

    useEffect(
        () =>
            setState({
                ...state,
                rideErrorMessage: submitOrderErrorMessage,
            }),
        [submitOrderErrorMessage]
    );

    const lastStep = state.step === steps.length - 1;

    function nextStep() {
        setState((prevState) => {
            const step = lastStep ? 0 : prevState.step + 1; // restart
            return { ...prevState, errorMessage: '', step };
        });
    }

    const submitOrderCallback = async () => {
        if (!userId) {
            throw new Error('Missing userId');
        }

        if (!ridesPickup || !ridesDropoff || !ridesService) {
            setState({
                ...state,
                rideErrorMessage: 'please enter required data',
                validData: false,
            });
        } else {
            setState({ ...state, rideErrorMessage: undefined });

            // TODO: show loading
            if (
                await submitOrder(ridesApi, {
                    order: {
                        rideCart: {
                            ridesDropoff,
                            ridesPickup,
                            ridesService,
                        },
                    },
                    userId,
                })
            ) {
                clearRideCart();
                nextStep();
            }
        }
    };

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card>
                <Box className="module">
                    <Box className="module-top">
                        <h4>Rides</h4>
                    </Box>

                    {/* based on step */}

                    {/* Show go Back button if step is more than 0 */}
                    {state.step === 0 && (
                        <>
                            <Box className="module-fields overflow-unset">
                                <Box className="btn-group module-fields__ride-pickup module-fields--left-addon">
                                    <AddressSearchBar
                                        parentCallback={(ridesPickup) => setRideCartPickup(ridesPickup)}
                                        placeholder="Pickup location ..."
                                        savedAddresses={addressList}
                                        value={ridesPickup}
                                    />
                                </Box>
                                <Box className="btn-group module-fields__ride-dropoff module-fields--left-addon" mb={4}>
                                    <AddressSearchBar
                                        parentCallback={(ridesDropoff) => setRideCartDropoff(ridesDropoff)}
                                        placeholder="Type your destination here"
                                        savedAddresses={addressList}
                                        value={ridesDropoff}
                                    />
                                </Box>
                                <RideButtonGroup
                                    onChange={(ridesService) => setRideCartRideService(ridesService)}
                                    value={ridesService}
                                />
                            </Box>
                            <Box className="module-bot">
                                <Grid container>
                                    <Grid item xs={6} sm={4}>
                                        <Button
                                            id={'orderRideButton'}
                                            disabled={!(ridesPickup?.address && ridesDropoff?.address && ridesService)}
                                            disableRipple
                                            className="dashboard-btn"
                                            onClick={submitOrderCallback}
                                        >
                                            Order
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={8} className="module-bot-right">
                                        <Typography
                                            color={state.validData ? 'initial' : 'error'}
                                            component="h1"
                                            variant="subtitle1"
                                        >
                                            {state.rideErrorMessage}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {state.step === 1 && (
                        <>
                            <Box className="module-fields" textAlign="center">
                                <Typography variant="inherit" component="p" className="confirmation-text">
                                    Your order request is received. We are working on it. We will reach out to you with
                                    your order updates and confirmation. Meanwhile, You can chat with us if you have any
                                    questions.
                                </Typography>
                                <Button className="dashboard-btn" onClick={() => nextStep()}>
                                    OK
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Card>
            <RequestSubmittedModal
                headerImage="img/ride-request-submitted-modal-bg.png"
                nextSteps={({ phoneNumber }) => [
                    'We will connect with trusted ride-hailing partners, such as Uber, to fulfill your order.',
                    `We will text you on ${phoneNumber} with confirmation details including price, pickup location, and drop off location.`,
                    'You will send us a confirmation via text before creating an order.',
                    'We will send you driver status updates via text.',
                ]}
                onCloseModal={() => nextStep()}
                showModal={state.step === 1}
                title="Your ride was requested!"
            />
        </Grid>
    );
};

export default connector(RidesService);
