import { ComponentClass } from 'react';
import { RouteProps } from 'react-router-dom';

import Main from './screens/Main/Main';
import Landing from './screens/Landing/Landing';
import Login from './screens/SignInFlow/Login/Login';
import SignUp from './screens/SignInFlow/SingUp/SignUp';
import ResetPassword from './screens/SignInFlow/ResetPassword/ResetPassword';
import ForgotPassword from './screens/SignInFlow/ForgotPassword/ForgotPassword';
import EmailVerified from './screens/EmailVerified';
import UserInfo from './screens/Main/UserInfo/UserInfo';
import NotFound from './screens/404';

export const preLoginRoutes: RouteProps[] = [
    {
        path: '/',
        component: Landing,
        exact: true,
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/sign-up',
        component: SignUp,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
    },
    {
        path: '/email-verified',
        component: EmailVerified,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
    },
    {
        path: '',
        component: NotFound,
    },
];

// Protected routes, only available after user has logged in.
export const postLoginRoutes: RouteProps[] = [
    {
        path: '/',
        component: Main,
        exact: true,
    },
    {
        path: '/userinfo',
        component: UserInfo,
    },
    {
        path: '',
        component: NotFound,
    },
];
