import axios, { AxiosInstance, AxiosResponse, Method } from 'axios';

import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export interface IApiBase {
    get(url: string, params?: any): Promise<AxiosResponse>;
    post(url: string, data?: any): Promise<AxiosResponse>;
}

export class ApiBase implements IApiBase {
    protected getAccesToken: () => string | undefined;
    protected axiosInstance: AxiosInstance;

    constructor(baseURL: string, getAccesToken: () => string | undefined) {
        this.getAccesToken = getAccesToken;
        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                common: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            },
        });

        this.axiosInstance.interceptors.request.use(
            (config) => {
                console.log('ApiBase request %o', config);
                return config;
            },
            (error) => {
                console.error('ApiBase request error %o', error);
                return Promise.reject(error);
            }
        );

        this.axiosInstance.interceptors.response.use(
            (response) => {
                console.log('ApiBase response %o', response);
                return response;
            },
            (error) => {
                console.error('ApiBase error response %o', error);
                return Promise.reject(error);
            }
        );
    }

    createAxiosRequestConfig(url: string, method: Method) {
        return {
            headers: {
                'authorization': this.getAccesToken(),
                'X-Request-ID': uuidv4(),
            },
            method,
            url,
        };
    }

    get(url: string, params?: any) {
        return this.axiosInstance({
            ...this.createAxiosRequestConfig(url, 'GET'),
            params,
        });
    }

    post(url: string, data?: any) {
        return this.axiosInstance({
            ...this.createAxiosRequestConfig(url, 'POST'),
            data,
        });
    }
}
