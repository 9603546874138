import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { DispatchType, RootState } from '../../state';

import { notUndefined } from '../../lib/utils';

import GroceryProduct from '../GroceryProduct/GroceryProduct';

import {
    addAvailableGroceryProductsAction,
    addGroceryProductToCartAction,
    removeGroceryProductFromCartAction,
} from '../../state/actions/groceriesActions';

const mapState = ({ groceries: { availableProducts, groceryCart } }: RootState) => ({
    availableProducts,
    groceryCart,
});

const mapDispatch = (dispatch: DispatchType) => ({
    addAvailableGroceryProducts: addAvailableGroceryProductsAction(dispatch),
    addGroceryProductToCart: addGroceryProductToCartAction(dispatch),
    removeGroceryProductFromCart: removeGroceryProductFromCartAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AvailableProductsList: React.FC<PropsFromRedux> = ({
    availableProducts,
    groceryCart,
    addGroceryProductToCart,
    removeGroceryProductFromCart,
}) => {
    const availableProductIds = availableProducts.map(({ id }) => id);

    // Products that the user has just added with the "Add Items" button
    const customProducts = Object.values(groceryCart)
        .filter(notUndefined)
        .map(({ product }) => product)
        .filter(({ id }) => !availableProductIds.includes(id));

    return (
        <>
            {[...customProducts, ...availableProducts].map((product) => (
                <GroceryProduct
                    product={product}
                    countInCart={groceryCart[product.id]?.count}
                    key={product.id}
                    addToCart={addGroceryProductToCart}
                    removeFromCart={removeGroceryProductFromCart}
                />
            ))}
        </>
    );
};

export default connector(AvailableProductsList);
