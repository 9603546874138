import React from 'react';

import Grid from '@material-ui/core/Grid';

import { IPrescriptionMedicationItem } from '../../lib/types';

interface Props {
    item: IPrescriptionMedicationItem;
}

const PrescriptionMedicationListItem: React.FC<Props> = ({
    item: {
        deliveryAddress: { address: deliveryAddress },
        pharmacyAddress: { address: pharmacyAddress },
    },
}) => (
    <Grid container>
        <Grid item xs={4}>
            Prescription medication
        </Grid>
        <Grid item xs={8}>
            <Grid container item xs={12}>
                Pharmacy address: {pharmacyAddress}
            </Grid>
            <Grid item xs={12}>
                Delivery address: {deliveryAddress}
            </Grid>
        </Grid>
    </Grid>
);

export default PrescriptionMedicationListItem;
