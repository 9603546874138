import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, Button, Grid, TextField, Typography } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';

import { ISignUpCallbackParams } from '../SingUp/SignUp';
import inputValidation from '../../../input-validation.service';
import 'react-phone-number-input/style.css';

interface State {
    phonenumber: string;
    email: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    errorMessage: string;
}

interface Props {
    signUp: (parameter: ISignUpCallbackParams) => void;
}

const CaregiverSignUp = (props: Props): JSX.Element => {
    const { signUp } = props;

    const [state, setState] = useState<State>({
        confirmEmail: '',
        confirmPassword: '',
        email: '',
        errorMessage: '',
        firstName: '',
        lastName: '',
        password: '',
        phonenumber: '',
    });

    return (
        <form
            className="form"
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
                signUp({
                    ...state,
                    addressList: [],
                });
            }}
        >
            <Typography className="main-text" component="h1" variant="h4">
                Caregiver information
            </Typography>
            <Typography component="h1" variant="subtitle1">
                Enter the general information about yourself to create your account
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        autoComplete="fname"
                        name="firstName"
                        id="firstName"
                        label="First Name"
                        autoFocus
                        InputProps={{
                            className: 'input',
                        }}
                        value={state.firstName}
                        onChange={(event) => inputValidation.name(event, 'firstName', setState)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lname"
                        InputProps={{
                            className: 'input',
                        }}
                        value={state.lastName}
                        onChange={(event) => inputValidation.name(event, 'lastName', setState)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        InputProps={{
                            className: 'input',
                        }}
                        value={state.email}
                        onChange={(event) => {
                            const value = event.target.value;
                            return setState((prevState) => {
                                return { ...prevState, email: value };
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Confirm Email"
                        name="email"
                        autoComplete="email"
                        InputProps={{
                            className: 'input',
                        }}
                        value={state.confirmEmail}
                        onChange={(event) => {
                            const value = event.target.value;
                            return setState((prevState) => {
                                return { ...prevState, confirmEmail: value };
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                        <OutlinedInput
                            id="phone"
                            inputComponent={PhoneInput as any}
                            name="phone"
                            autoComplete="phone"
                            inputProps={{
                                placeholder: '(650) 123-1234',
                                defaultCountry: 'US',
                                limitMaxLength: true,
                                addInternationalOption: false,
                                style: {
                                    display: 'inline-flex',
                                    background: 'white',
                                },
                                numberInputProps: {
                                    className: 'phone-number-input',
                                },
                                onChange: (value) => {
                                    return setState((prevState: any) => {
                                        return { ...prevState, phonenumber: value };
                                    });
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        InputProps={{
                            className: 'input',
                        }}
                        value={state.password}
                        onChange={(event) => {
                            const value = event.target.value;
                            return setState((prevState) => {
                                return { ...prevState, password: value };
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        InputProps={{
                            className: 'input',
                        }}
                        onChange={(event) => {
                            const value = event.target.value;
                            return setState((prevState) => {
                                return { ...prevState, confirmPassword: value };
                            });
                        }}
                    />
                </Grid>
            </Grid>
            <Typography className="error-message" component="h1" variant="subtitle1">
                {state.errorMessage}
            </Typography>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className="submit-btn"
                onClick={() => {
                    if (state.password !== state.confirmPassword) {
                        return setState((prevState) => {
                            return {
                                ...prevState,
                                errorMessage: 'Confirm Password should be equal to Password',
                            };
                        });
                    } else if (state.email !== state.confirmEmail) {
                        return setState((prevState) => {
                            return {
                                ...prevState,
                                errorMessage: 'Confirm Email should be equal to Email Address',
                            };
                        });
                    } else if (state.phonenumber && state.phonenumber.length < 10) {
                        return setState((prevState) => {
                            return {
                                ...prevState,
                                errorMessage: 'Please enter 10 digits phone number',
                            };
                        });
                    } else if (!inputValidation.email(state.email)) {
                        return setState((prevState) => {
                            return { ...prevState, errorMessage: 'Invalid Email' };
                        });
                    } else {
                        signUp({
                            ...state,
                            addressList: [],
                        });
                    }
                }}
            >
                Sign up
            </Button>
        </form>
    );
};

export default CaregiverSignUp;
