import React, { useContext, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import ServiceCard from '../../../components/Settings/ServiceCard/ServiceCard';

import { IHideServices } from '../../../lib/api';
import { ApisContext } from '../../../lib/contexts';
import { RootState, DispatchType } from '../../../state';
import { updateHideServicesAction } from '../../../state/actions/userActions';

// icons
import RidesIcon from '../../../assets/icons/settings-icon-car.svg';
import GroceriesIcon from '../../../assets/icons/settings-icon-groceries.svg';
import PrescriptionIcon from '../../../assets/icons/settings-icon-prescription.svg';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const mapState = ({
    user: { loggedInUser },
}: RootState): {
    hideServices?: IHideServices;
    userId?: string;
} => {
    if (loggedInUser) {
        const {
            userId,
            userInfo: { hideServices },
        } = loggedInUser;
        return { hideServices, userId };
    }
    return {};
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
    updateHideServices: updateHideServicesAction(dispatch),
});

const connector = connect(mapState, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ServiceType = {
    active: boolean;
    serviceIcon: string | React.ReactNode;
    serviceName: string;
    toggleHidden: () => void;
};

type ServiceListType = ServiceType[];

const ServicesCarousel: React.FC<PropsFromRedux> = ({ hideServices, userId, updateHideServices }) => {
    const { userApi } = useContext(ApisContext);
    const [servicesList, setServicesList] = useState<ServiceListType>([]);

    useEffect(() => {
        if (hideServices && userId) {
            const {
                hideGroceries,
                hideMedications,
                hideRides,
                hideCaregiverVisits,
                hideDoctorAppointments,
            } = hideServices;
            setServicesList([
                {
                    active: !hideRides,
                    serviceIcon: RidesIcon,
                    serviceName: 'Request a ride anytime in the day',
                    toggleHidden: () =>
                        updateHideServices(userApi, {
                            hideServices: {
                                ...hideServices,
                                hideRides: !hideRides,
                            },
                            userId,
                        }),
                },
                {
                    active: !hideGroceries,
                    serviceIcon: GroceriesIcon,
                    serviceName: 'Order groceries delivered to your door',
                    toggleHidden: () =>
                        updateHideServices(userApi, {
                            hideServices: {
                                ...hideServices,
                                hideGroceries: !hideGroceries,
                            },
                            userId,
                        }),
                },
                {
                    active: !hideMedications,
                    serviceIcon: PrescriptionIcon,
                    serviceName: 'Deliver your prescription to your door',
                    toggleHidden: () =>
                        updateHideServices(userApi, {
                            hideServices: {
                                ...hideServices,
                                hideMedications: !hideMedications,
                            },
                            userId,
                        }),
                },
                {
                    active: !hideDoctorAppointments,
                    serviceIcon: <LocalHospitalIcon fontSize="large" />,
                    serviceName: 'Doctor appointments',
                    toggleHidden: () =>
                        updateHideServices(userApi, {
                            hideServices: {
                                ...hideServices,
                                hideDoctorAppointments: !hideDoctorAppointments,
                            },
                            userId,
                        }),
                },
                {
                    active: !hideCaregiverVisits,
                    serviceIcon: <HelpOutlineIcon fontSize="large" />,
                    serviceName: 'Caregiver visits',
                    toggleHidden: () =>
                        updateHideServices(userApi, {
                            hideServices: {
                                ...hideServices,
                                hideCaregiverVisits: !hideCaregiverVisits,
                            },
                            userId,
                        }),
                },
            ]);
        }
    }, [hideServices]);

    const bottomActions = () => (
        <Grid container justify="space-between" className="bottom-actions">
            <Grid item>
                <p className="bottom-actions--edit">How it works</p>
            </Grid>
        </Grid>
    );

    const renderAddresses = () =>
        servicesList.map(({ active, serviceIcon, serviceName, toggleHidden }, index) => (
            <Grid item xs={12} sm={12} md={3} key={index}>
                <ServiceCard
                    active={active}
                    bottomActions={bottomActions}
                    cardIcon={serviceIcon}
                    cardTitle={serviceName}
                    onActiveIconClicked={toggleHidden}
                />
            </Grid>
        ));

    return (
        <Grid container spacing={2} alignItems="center">
            {renderAddresses()}
        </Grid>
    );
};

export default connector(ServicesCarousel);
