import React, { useContext, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import 'react-phone-number-input/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Button, Dialog, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-number-input';

import { ApisContext } from '../../../lib/contexts';
import { DispatchType, RootState } from '../../../state';
import { updateLoggedInUserDataAction } from '../../../state/actions/userActions';

import boyImage from '../../../assets/images/boy.png';
import girlImage from '../../../assets/images/girl.png';
import SaveChanges from '../../../components/SaveChanges/SaveChanges';
import ChangePassword from './ChangePassword';

import './EditUser.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (!loggedInUser) {
        throw new Error('User not logged in');
    }
    return { ...loggedInUser.userInfo, userId: loggedInUser.userId };
};

const mapDispatch = (dispatch: DispatchType) => ({
    updateLoggedInUserData: updateLoggedInUserDataAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    closeModal: () => void;
}

const EditUser: React.FC<Props & PropsFromRedux> = ({
    closeModal,
    dateOfBirth,
    email,
    firstName,
    gender,
    height,
    imageUrl,
    lastName,
    phoneNumber,
    updateLoggedInUserData,
    userId,
    weight,
}) => {
    const { userApi } = useContext(ApisContext);

    const [newDateOfBirth, setNewDateOfBirth] = useState<Date>();
    const [newEmail, setNewEmail] = useState<string>();
    const [newFirstName, setNewFirstName] = useState<string>();
    const [newGender, setNewGender] = useState<'female' | 'male'>();
    const [newHeight, setNewHeight] = useState<number>();
    const [newImageUrl, setNewImageUrl] = useState<string>();
    const [newLastName, setNewLastName] = useState<string>();
    const [newPhoneNumber, setNewPhoneNumber] = useState<string>();
    const [newWeight, setNewWeight] = useState<number>();

    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [showSaveChanges, setShowSaveChanges] = useState<boolean>(false);

    let userImage = imageUrl;

    if (!userImage) {
        userImage = gender === 'female' ? girlImage : boyImage;
    }

    const createNewUserInfo = () => ({
        dateOfBirth: newDateOfBirth,
        firstName: newFirstName,
        gender: newGender,
        height: newHeight,
        imageUrl: newImageUrl,
        lastName: newLastName,
        weight: newWeight,
    });

    const checkChanges = () => {
        const newUserInfo = createNewUserInfo();
        if (Object.values(newUserInfo).some((value) => value !== undefined) || newEmail || newPhoneNumber) {
            setShowSaveChanges(true);
        } else {
            closeModal();
        }
    };

    const uploadImage = async (image: File) => {
        const { getURL } = await userApi.uploadUserImage({
            image,
            userId,
        });
        setNewImageUrl(getURL);
    };

    const save = async () => {
        const promises: Promise<any>[] = [];

        const newUserInfo = createNewUserInfo();
        if (Object.values(newUserInfo).some((value) => value !== undefined)) {
            promises.push(
                userApi.updateUserInfo({
                    ...newUserInfo,
                    userId,
                })
            );
        }

        if (newEmail) {
            promises.push(
                userApi.changeEmail({
                    email: newEmail,
                    userId,
                })
            );
        }

        if (newPhoneNumber) {
            promises.push(
                userApi.changePhoneNumber({
                    phoneNumber: newPhoneNumber,
                    userId,
                })
            );
        }

        await Promise.all(promises);
        await updateLoggedInUserData(userApi);
        closeModal();
    };

    return (
        <div className="edit-user">
            <Grid container item direction="row" justify="space-between" alignItems="center">
                <Grid item className="edit-user__title">
                    Edit your profile
                </Grid>
                <Grid item className="edit-user__close-icon" onClick={checkChanges}>
                    <CloseIcon />
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className="edit-user__user-info-container"
            >
                <Grid item className="edit-user__user-image-panel">
                    <p>Profile image</p>
                    <div
                        style={{
                            backgroundImage: `url(${newImageUrl || userImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: '50%',
                        }}
                        className="edit-user__user-image"
                    ></div>

                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        component="label"
                        onClick={() => console.log('IMAGE')}
                    >
                        Upload a Photo
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={({ target: { files } }) => {
                                if (files) {
                                    uploadImage(files[0]);
                                }
                            }}
                        />
                    </Button>
                </Grid>
                <Grid item className="edit-user__user-info-panel">
                    <Grid item className="edit-user__user-info-part">
                        <div>First Name</div>
                        <input
                            defaultValue={newFirstName || firstName}
                            className="edit-user__user-info-part-input"
                            onChange={({ target: { value } }) => setNewFirstName(value)}
                        />
                    </Grid>
                    <Grid item className="edit-user__user-info-part">
                        <div>Last Name</div>
                        <input
                            defaultValue={newLastName || lastName}
                            className="edit-user__user-info-part-input"
                            onChange={({ target: { value } }) => setNewLastName(value)}
                        />
                    </Grid>
                    <Grid item className="edit-user__user-info-part">
                        <div>Phone Number</div>
                        <PhoneInput
                            placeholder="Enter phone number"
                            defaultCountry="US"
                            flagUrl="https://flag.pk/flags/4x3/{xx}.svg"
                            addInternationalOption={true}
                            limitMaxLength={true}
                            className="edit-user__phone-input-parent"
                            value={newPhoneNumber || phoneNumber || ''}
                            numberInputProps={{
                                className: 'edit-user__user-info-part-input edit-user__phone-input-child',
                            }}
                            onChange={(value) => setNewPhoneNumber(value)}
                        />
                    </Grid>
                    <Grid item className="edit-user__user-info-part">
                        <div>Email address</div>
                        <input
                            defaultValue={newEmail || email}
                            className="edit-user__user-info-part-input"
                            onChange={({ target: { value } }) => setNewEmail(value)}
                        />
                    </Grid>
                    <Button
                        variant="outlined"
                        color="primary"
                        component="label"
                        className="edit-user__change-password-button"
                        onClick={() => setShowChangePassword(true)}
                    >
                        Change password
                    </Button>
                    <Grid item className="edit-user__user-info-part">
                        <div>Gender</div>
                        <RadioGroup
                            aria-label="gender"
                            name="gender"
                            row
                            className="edit-user__user-info-part-radio"
                            value={newGender || gender}
                            onChange={({ target: { value } }) => setNewGender(value as 'female' | 'male' | undefined)}
                        >
                            <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" />
                            <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" />
                            <FormControlLabel control={<Radio color="primary" />} label="Prefer not to answer" />
                        </RadioGroup>
                    </Grid>
                    <Grid item className="edit-user__user-info-part">
                        <div>Date of birth</div>
                        <DatePicker
                            className="edit-user__user-info-part-input"
                            selected={newDateOfBirth || (dateOfBirth ? new Date(dateOfBirth) : new Date())}
                            onChange={(date) =>
                                !Array.isArray(date) ? setNewDateOfBirth(date || undefined) : undefined
                            }
                        />
                    </Grid>
                    <Grid item className="edit-user__user-info-part">
                        <div>Weight</div>
                        <input
                            defaultValue={newWeight || weight}
                            className="edit-user__user-info-part-input"
                            type="number"
                            onChange={({ target: { value } }) => {
                                const num = Number.parseFloat(value);
                                if (!Number.isNaN(num)) {
                                    setNewWeight(num);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item className="edit-user__user-info-part">
                        <div>Height</div>
                        <input
                            defaultValue={newHeight || height}
                            className="edit-user__user-info-part-input"
                            type="number"
                            onChange={({ target: { value } }) => {
                                const num = Number.parseFloat(value);
                                if (!Number.isNaN(num)) {
                                    setNewHeight(num);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                    <Button fullWidth variant="outlined" onClick={checkChanges}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className="edit-user__save-button"
                        onClick={save}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={showChangePassword} maxWidth={false}>
                <ChangePassword closeModal={() => setShowChangePassword(false)} />
            </Dialog>
            <Dialog open={showSaveChanges} maxWidth={false}>
                <SaveChanges
                    action={save}
                    closeModal={() => setShowSaveChanges(false)}
                    closeParentModal={closeModal}
                ></SaveChanges>
            </Dialog>
        </div>
    );
};

export default connector(EditUser);
