import React from 'react';

import { Input, FormHelperText } from '@material-ui/core';

import ReactPhoneNumberInput from 'react-phone-number-input';

interface Props {
    errorMessage: string | undefined;
    id: string;
    onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value: string | undefined;
}

const PhoneInput: React.FC<Props> = ({ id, value, errorMessage, onChange, onBlur }) => (
    <>
        <Input
            id={id}
            inputComponent={ReactPhoneNumberInput as any}
            name="phone"
            error={!!errorMessage}
            value={value || ''}
            inputProps={{
                defaultCountry: 'US',
                placeholder: 'Phone',
                limitMaxLength: true,
                style: {
                    display: 'inline-flex',
                },
                numberInputProps: {
                    className: 'phone-number-input',
                },
                onBlur,
                onChange,
            }}
        />
        <FormHelperText error id="standard-weight-helper-text">
            {errorMessage}
        </FormHelperText>
    </>
);

export default PhoneInput;
