import React, { useState, useContext, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import PaymentCard from '../../../components/Settings/PaymentCard/PaymentCard';

import { ApisContext } from '../../../lib/contexts';
import { RootState, DispatchType } from '../../../state';
import { getPaymentSourcesAction } from '../../../state/actions/paymentActions';

import SettingsCarousel from '../../../components/SettingsCarousel';

import PaymentDialogContent, { FormState } from './PaymentDialogContent';

const mapState = ({ user: { loggedInUser }, payment: { paymentSources } }: RootState) => {
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return { userId, paymentSources };
    }
    return { paymentSources };
};

const mapDispatch = (dispatch: DispatchType) => ({
    getPaymentSources: getPaymentSourcesAction(dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentsCarousel: React.FC<PropsFromRedux> = ({ userId, paymentSources, getPaymentSources }) => {
    const { paymentApi } = useContext(ApisContext);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [formState, setFormState] = useState<FormState>({});

    useEffect(() => {
        if (userId) {
            getPaymentSources(paymentApi, {
                userId,
            });
        }
    }, [userId, paymentApi, getPaymentSources]);

    const renderCards = () =>
        paymentSources.map((paymentSource, index) => (
            <Grid item xs={12} sm={3} key={index}>
                <PaymentCard paymentSource={paymentSource} />
            </Grid>
        ));

    const { cardHolderName, state, streetAddress, stripePaymentMethod, zipCode, secondAddressLine } = formState;

    return (
        <SettingsCarousel
            dialogContent={<PaymentDialogContent formState={formState} setFormState={setFormState} />}
            dialogTitle="Add payment information"
            isDialogOpen={isDialogOpen}
            isSaveButtonDisabled={!(state && streetAddress && zipCode && cardHolderName && stripePaymentMethod)}
            items={renderCards()}
            onAddButtonPressed={() => setIsDialogOpen(true)}
            onCloseButtonPressed={() => setIsDialogOpen(false)}
            onSavePressed={async () => {
                if (state && streetAddress && zipCode && cardHolderName && stripePaymentMethod && userId) {
                    const { id: paymentMethodId } = stripePaymentMethod;
                    await paymentApi.addPaymentSource({
                        paymentSource: {
                            billingAddress: {
                                state,
                                streetAddress,
                                zipCode,
                                secondAddressLine,
                            },
                            cardHolderName,
                            paymentMethodId,
                            stripePaymentMethod,
                        },
                        userId,
                    });
                    await getPaymentSources(paymentApi, { userId });
                    setIsDialogOpen(false);
                    setFormState({});
                }
            }}
        />
    );
};

export default connector(PaymentsCarousel);
