import React from 'react';

import {
    IDoctorAppointmentOrder,
    EXISTING_DOCTOR_APPOINTMENT_REQUEST,
    NEW_DOCTOR_APPOINTMENT_REQUEST,
} from '../../lib/types';

import ExistingDoctorAppointemntOrderListItem from './ExistingDoctorAppointemntOrderListItem';
import NewDoctorAppointemntOrderListItem from './NewDoctorAppointemntOrderListItem';

interface Props {
    order: IDoctorAppointmentOrder;
}

type OrderConverter<A> = Extract<IDoctorAppointmentOrder, { type: A }>;

const DoctorAppointmentCartList: React.FC<Props> = ({ order }) => {
    const { type } = order;
    switch (type) {
        case EXISTING_DOCTOR_APPOINTMENT_REQUEST: {
            return <ExistingDoctorAppointemntOrderListItem order={order as OrderConverter<typeof type>} />;
        }
        case NEW_DOCTOR_APPOINTMENT_REQUEST: {
            return <NewDoctorAppointemntOrderListItem order={order as OrderConverter<typeof type>} />;
        }
    }
    return null;
};

export default DoctorAppointmentCartList;
