import '../src/scss/styles.scss';

import React, { useContext, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { HashRouter as Router, Route, Switch, RouteProps } from 'react-router-dom';

import { postLoginRoutes, preLoginRoutes } from './Router';

import { DispatchType, RootState } from './state';
import { updateLoggedInUserDataAction } from './state/actions/userActions';
import { ApisContext } from './lib/contexts';

const mapState = ({ user: { loggedInUser } }: RootState) => ({ loggedInUser });

const mapDispatchToProps = (dispatch: DispatchType) => ({
    updateLoggedInUserData: updateLoggedInUserDataAction(dispatch),
});

const connector = connect(mapState, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const App: React.FC<PropsFromRedux> = ({ loggedInUser, updateLoggedInUserData }) => {
    const { userApi } = useContext(ApisContext);
    const routes = loggedInUser ? postLoginRoutes : preLoginRoutes;
    useEffect(() => {
        if (loggedInUser) {
            updateLoggedInUserData(userApi);
        }
    }, [updateLoggedInUserData, userApi]);
    return (
        <div>
            <Router>
                <div>
                    <Switch>
                        {routes.map((route: RouteProps, i: number) => (
                            <Route key={i} {...route} />
                        ))}
                    </Switch>
                </div>
            </Router>
        </div>
    );
};

export default connector(App);
