import { HomeAddressType, HomeAddressParams, IGroceryProduct } from '../types';

import { IApiBase } from './api_base';

import axios from 'axios';

export interface IUserLoginParams {
    readonly email: string;
    readonly password: string;
}

export interface IHideServices {
    hideCaregiverVisits: boolean;
    hideDoctorAppointments: boolean;
    hideGroceries: boolean;
    hideMedications: boolean;
    hideRides: boolean;
}

export interface IUserInfo {
    readonly addressList?: HomeAddressType[];
    readonly age?: number;
    readonly dateOfBirth?: number;
    readonly email: string;
    readonly firstName?: string;
    readonly gender?: 'female' | 'male';
    readonly height?: number;
    readonly hideServices: IHideServices;
    readonly imageUrl?: string;
    readonly isFirstVisit: boolean;
    readonly lastName?: string;
    readonly phoneNumber: string;
    readonly weight?: number;
}

export interface IInvitedCaregiverInfo {
    readonly email?: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly phoneNumber?: string;
}

export interface IUserLoginResult {
    readonly accessToken: string;
    readonly invitedCaregiverInfo?: IInvitedCaregiverInfo;
    readonly userId: string;
    readonly userInfo: IUserInfo;
}

export interface IChangePasswordParams {
    readonly oldPassword: string;
    readonly newPassword: string;
}

export interface IUserResetParams {
    readonly email: string;
}

export interface IGetSignedImageURLParams {
    readonly userId: string;
}

export interface IGetSignedImageURLResult {
    readonly getURL: string;
    readonly postURL: string;
}

export interface IGetUploadURLResult {
    readonly postURL: string;
    readonly getURL: string;
}

export interface IUploadUserImageParams {
    readonly userId: string;
    readonly image: File;
}

export interface IUploadUserImageResult {
    readonly getURL: string;
}

export interface IUpdateUserInfoParams
    extends Omit<
        IUserInfo,
        'age' | 'addressList' | 'dateOfBirth' | 'email' | 'phoneNumber' | 'hideServices' | 'isFirstVisit'
    > {
    readonly dateOfBirth?: Date;
    readonly userId: string;
}

interface IChangeEmailParams {
    readonly email: string;
    readonly userId: string;
}

export interface IAddAddressParams {
    readonly address: HomeAddressParams;
    readonly userId: string;
}

interface IChangePhoneNumberParams {
    readonly phoneNumber: string;
    readonly userId: string;
}

export interface IGetUserInfoParams {
    readonly userId: string;
}

export interface IRemoveAddressParams {
    readonly id: string;
    readonly userId: string;
}

export interface IEditAddressParams {
    readonly address: HomeAddressType;
    readonly userId: string;
}

export interface IUpdateSelectedServiceParams {
    readonly hideServices: IHideServices;
    readonly userId: string;
}

export interface IGetGroceryListParams {
    readonly userId: string;
}

export interface IGetGroceryListResult {
    groceryProducts: IGroceryProduct[];
}

export interface ISetIsFirstVisitParams {
    readonly isFirstVist: boolean;
    readonly userId: string;
}

export interface ISetIsFirstVisitResut {
    userInfo: IUserInfo;
}

export interface IUploadImageParams {
    readonly image: File;
}

export interface IUploadImageResult {
    readonly getURL: string;
}

export interface IUserApi {
    changeEmail(params: IChangeEmailParams): Promise<void>;
    changePassword(params: IChangePasswordParams): Promise<void>;
    changePhoneNumber(params: IChangePhoneNumberParams): Promise<void>;
    getSignedImageURL(parasm: IGetSignedImageURLParams): Promise<IGetSignedImageURLResult>;
    getUserInfo(params: IGetUserInfoParams): Promise<IUserInfo>;
    login(params: IUserLoginParams): Promise<IUserLoginResult>;
    logout(): Promise<void>;
    reset(params: IUserResetParams): Promise<void>;
    signUp(params: any): Promise<void>;
    updateUserInfo(params: IUpdateUserInfoParams): Promise<void>;
    uploadUserImage(params: IUploadUserImageParams): Promise<IUploadUserImageResult>;
    addAddress(params: IAddAddressParams): Promise<void>;
    removeAddress(params: IRemoveAddressParams): Promise<void>;
    editAddress(params: IEditAddressParams): Promise<void>;
    updateSelectedServices(params: IUpdateSelectedServiceParams): Promise<IHideServices>;
    getGroceryList(params: IGetGroceryListParams): Promise<IGetGroceryListResult>;
    setIsFirstVisit(params: ISetIsFirstVisitParams): Promise<ISetIsFirstVisitResut>;
    getUploadURL(): Promise<IGetUploadURLResult>;
    uploadImage(params: IUploadImageParams): Promise<IUploadImageResult>;
}

const parseAddressList = (addressList: any): HomeAddressType[] | undefined => {
    if (!Array.isArray(addressList)) {
        addressList = undefined;
    }
    return addressList.filter(
        ({ address, id }: { address: any; id: any }) => typeof address === 'string' && typeof id === 'string'
    );
};

enum ServiceName {
    caregiverVisits = 'caregiverVisits',
    doctorAppointments = 'doctorAppointments',
    groceries = 'groceries',
    medications = 'medications',
    rides = 'rides',
}

const parseHideServicesList = (hideServicesList: string[]): IHideServices => {
    let hideCaregiverVisits = false;
    let hideDoctorAppointments = false;
    let hideGroceries = false;
    let hideMedications = false;
    let hideRides = false;

    if (Array.isArray(hideServicesList)) {
        for (const hideService of hideServicesList as string[]) {
            switch (hideService) {
                case ServiceName.groceries:
                    hideGroceries = true;
                    break;
                case ServiceName.medications:
                    hideMedications = true;
                    break;
                case ServiceName.rides:
                    hideRides = true;
                    break;
                case ServiceName.doctorAppointments:
                    hideDoctorAppointments = true;
                    break;
                case ServiceName.caregiverVisits:
                    hideCaregiverVisits = true;
                    break;
            }
        }
    }

    return {
        hideCaregiverVisits,
        hideDoctorAppointments,
        hideGroceries,
        hideMedications,
        hideRides,
    };
};

const parseUserInfo = ({
    userInfo: {
        addressList,
        age,
        DOB: dateOfBirth,
        firstName,
        gender,
        height,
        hideServices: hideServicesList,
        imageUrl,
        lastName,
        weight,
    },
    email,
    username: phoneNumber,
    isFirstVist,
}: any): IUserInfo => {
    if (typeof age !== 'number') {
        age = undefined;
    }

    if (typeof dateOfBirth === 'string' && dateOfBirth) {
        dateOfBirth = Date.parse(dateOfBirth);
    } else {
        dateOfBirth = undefined;
    }

    if (typeof height === 'string' && height) {
        height = Number.parseFloat(height);
    } else {
        height = undefined;
    }

    if (!(gender === 'female' || gender === 'male')) {
        gender = undefined;
    }

    if (typeof weight === 'string' && weight) {
        weight = Number.parseFloat(weight);
    } else {
        weight = undefined;
    }

    if (!(typeof email === 'string' && email)) {
        throw new Error('API error, no email in response');
    }

    if (!(typeof phoneNumber === 'string' && phoneNumber)) {
        throw new Error('API error, no phoneNumber in response');
    }

    return {
        addressList: parseAddressList(addressList),
        age,
        dateOfBirth,
        email,
        firstName,
        gender,
        height,
        hideServices: parseHideServicesList(hideServicesList),
        imageUrl,
        isFirstVisit: typeof isFirstVist === 'undefined' ? true : isFirstVist,
        lastName,
        phoneNumber,
        weight,
    };
};

const parseInvitedCaregiverInfo = ({
    email,
    firstName,
    lastName,
    phoneNumber,
}: any): IInvitedCaregiverInfo | undefined => {
    if (!(typeof email === 'string' && email)) {
        email = undefined;
    }

    if (!(typeof firstName === 'string' && firstName)) {
        firstName = undefined;
    }

    if (!(typeof lastName === 'string' && lastName)) {
        lastName = undefined;
    }

    if (!(typeof phoneNumber === 'string' && phoneNumber)) {
        phoneNumber = undefined;
    }

    const result = {
        email,
        firstName,
        lastName,
        phoneNumber,
    };

    return Object.values(result).some((value) => value !== undefined) ? result : undefined;
};

export class UserApi implements IUserApi {
    protected apiBase: IApiBase;
    constructor(apiBase: IApiBase) {
        this.apiBase = apiBase;
    }

    async login(params: IUserLoginParams): Promise<IUserLoginResult> {
        const {
            data: {
                id: accessToken,
                user,
                // user: { id: userId, userInfo, username: phoneNumber, invitedCaregiverInfo, email },
            },
        } = await this.apiBase.post('/api/users/login', params);

        const { id: userId, invitedCaregiverInfo } = user;

        if (typeof userId !== 'string' || !userId) {
            throw new Error('API error, no user.id in login response');
        }

        if (typeof accessToken !== 'string' || !accessToken) {
            throw new Error('API error, no id in login response');
        }

        return {
            accessToken,
            invitedCaregiverInfo: parseInvitedCaregiverInfo(invitedCaregiverInfo),
            userId,
            userInfo: parseUserInfo(user),
        };
    }

    async logout(): Promise<void> {
        await this.apiBase.post('/api/users/logout');
    }

    async signUp(params: any): Promise<void> {
        await this.apiBase.post('/api/users', params);
    }

    async changePassword(params: IChangePasswordParams): Promise<void> {
        await this.apiBase.post('/api/users/change-password', params);
    }

    async reset(params: IUserResetParams): Promise<void> {
        await this.apiBase.post('/api/users/reset', params);
    }

    async getSignedImageURL({ userId }: IGetSignedImageURLParams): Promise<IGetSignedImageURLResult> {
        const {
            data: {
                status: { getURL, postURL },
            },
        } = await this.apiBase.get(`/api/users/${userId}/getSignedImageURL`);
        if (!postURL) {
            throw new Error('Missing postURL');
        }
        if (!getURL) {
            throw new Error('Missing getURL');
        }
        return {
            getURL,
            postURL,
        };
    }

    async uploadUserImage({ image, ...rest }: IUploadUserImageParams): Promise<IUploadUserImageResult> {
        const { postURL, getURL } = await this.getSignedImageURL(rest);
        await axios.put(postURL, image, {
            headers: {
                'Content-Type': image.type,
            },
        });
        return {
            getURL,
        };
    }

    async updateUserInfo({ userId, dateOfBirth, ...rest }: IUpdateUserInfoParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/updateUserInfo`, {
            ...rest,
            DOB: dateOfBirth,
        });
    }

    async addAddress({ userId, address, ...rest }: IAddAddressParams): Promise<any> {
        await this.apiBase.post(`/api/users/${userId}/addAddress`, {
            ...rest,
            ...address,
        });
    }

    async changeEmail({ userId, ...rest }: IChangeEmailParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/changeEmail`, {
            ...rest,
        });
    }

    async changePhoneNumber({ userId, ...rest }: IChangePhoneNumberParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/changePhoneNumber`, {
            ...rest,
        });
    }

    async getUserInfo({ userId }: IGetUserInfoParams): Promise<IUserInfo> {
        const {
            // data: { userInfo, email, username: phoneNumber },
            data,
        } = await this.apiBase.get(`/api/users/${userId}`);
        return parseUserInfo(data);
    }

    async removeAddress({ id, userId }: IRemoveAddressParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/removeAddresses`, { id });
    }

    async editAddress({ address, userId }: IEditAddressParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/editAddress`, address);
    }

    async updateSelectedServices({
        userId,
        hideServices: { hideGroceries, hideMedications, hideRides, hideCaregiverVisits, hideDoctorAppointments },
    }: IUpdateSelectedServiceParams): Promise<IHideServices> {
        const array: string[] = [];
        if (hideGroceries) {
            array.push(ServiceName.groceries);
        }
        if (hideMedications) {
            array.push(ServiceName.medications);
        }
        if (hideRides) {
            array.push(ServiceName.rides);
        }
        if (hideCaregiverVisits) {
            array.push(ServiceName.caregiverVisits);
        }
        if (hideDoctorAppointments) {
            array.push(ServiceName.doctorAppointments);
        }
        const { data } = await this.apiBase.post(`/api/users/${userId}/updateSelectedServices`, array);
        return parseHideServicesList(data);
    }

    async getGroceryList({ userId }: IGetGroceryListParams): Promise<IGetGroceryListResult> {
        const { data } = await this.apiBase.get(`/api/users/${userId}/getGrocerList`);

        return {
            groceryProducts: Array.isArray(data) ? data : [],
        };
    }

    async setIsFirstVisit({ userId, isFirstVist }: ISetIsFirstVisitParams): Promise<ISetIsFirstVisitResut> {
        const { data } = await this.apiBase.post(`/api/users/${userId}/set_isFirstVist`, { isFirstVist });
        return { userInfo: parseUserInfo(data) };
    }

    async getUploadURL(): Promise<IGetUploadURLResult> {
        const {
            data: {
                status: { postURL, getURL },
            },
        } = await this.apiBase.get(`/api/users/getUploadURL`);

        return {
            getURL,
            postURL,
        };
    }

    async uploadImage({ image }: IUploadImageParams): Promise<IUploadImageResult> {
        const { getURL, postURL } = await this.getUploadURL();
        await axios.put(postURL, image, {
            headers: {
                'Content-Type': image.type,
            },
        });
        return {
            getURL,
        };
    }
}
