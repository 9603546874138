import React from 'react';
import { Button } from '@material-ui/core';

// styles
import './styles.scss';

type Props = { text: string; onClick?: () => void; disabled?: boolean };

const BaseButton: React.FC<Props> = ({ text, onClick, disabled }) => {
    return (
        <Button variant="outlined" onClick={onClick} disabled={disabled} className="base-button">
            {text}
        </Button>
    );
};

export default BaseButton;
