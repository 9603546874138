import React, { useContext, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Box, Button, CssBaseline, Grid, TextField, Typography } from '@material-ui/core';

import PanelWithImage from '../PanelWithImage/PanelWithImage';

import { ApisContext } from '../../../lib/contexts';
import { DispatchType, RootState } from '../../../state';
import { resetUserAction } from '../../../state/actions/userActions';
import logo from '../../../assets/images/logo.png';

const mapState = ({ user: { passwordResetState, passwordResetErrorMessage } }: RootState) => ({
    passwordResetState,
    passwordResetErrorMessage,
});

const mapDispatch = (dispatch: DispatchType) => ({
    resetUser: resetUserAction(dispatch),
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const connector = connect(mapState, mapDispatch);

interface IState {
    email?: string;
    errorMessage?: string;
    successMessage?: string;
}

const ForgotPassword: React.FC<PropsFromRedux> = ({ resetUser, passwordResetErrorMessage }) => {
    const { userApi } = useContext(ApisContext);

    const [state, setState] = useState<IState>({});

    useEffect(
        () =>
            setState({
                ...state,
                errorMessage: passwordResetErrorMessage,
            }),
        [passwordResetErrorMessage]
    );

    const sendEmail = async () => {
        const { email } = state;
        if (email) {
            setState({
                ...state,
                errorMessage: undefined,
            });

            if (await resetUser(userApi, { email })) {
                setState({
                    ...state,
                    successMessage: 'Email sent',
                });
            }
        } else {
            setState({
                ...state,
                errorMessage: 'Email is required',
            });
        }
    };

    return (
        <Grid container className="root">
            <CssBaseline />
            <PanelWithImage />
            <Grid container item alignItems="center" justify="center" className="right-panel-container" xs={12} md={6}>
                <Box className="right-panel">
                    <Box className="header">
                        <Box>
                            <img className="logo" src={logo} alt="CAN logo" />
                        </Box>
                        <Typography component="h1" variant="h5">
                            Forgot Password
                        </Typography>
                        <Typography component="h1" variant="subtitle1">
                            {state.successMessage}
                        </Typography>
                    </Box>
                    <form
                        className="form"
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    placeholder="Email"
                                    name="email"
                                    autoComplete="email"
                                    InputProps={{
                                        className: 'input',
                                    }}
                                    value={state.email}
                                    onChange={(event) => {
                                        const email = event.target.value;
                                        return setState((prevState) => {
                                            return { ...prevState, email };
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item className="error-message-container">
                                <Typography className="error-message" component="h1" variant="subtitle1">
                                    {state.errorMessage}
                                </Typography>
                            </Grid>
                            <Grid item className="gray-text">
                                Enter your email address connected to your account. A link to generate a new password
                                would be sent there.
                            </Grid>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="submit-btn"
                                onClick={sendEmail}
                            >
                                Send Email
                            </Button>
                        </Grid>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
};

export default connector(ForgotPassword);
