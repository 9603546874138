import {
    IAddAddressParams,
    IChangePasswordParams,
    ISetIsFirstVisitParams,
    IUpdateSelectedServiceParams,
    IUserApi,
    IUserLoginParams,
    IUserResetParams,
} from '../../lib/api';
import { parseResponseStatus } from '../../lib/utils';
import {
    ADD_ADDRESS_ERROR,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGIN,
    LOGOUT,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNUP,
    UPDATE_HIDE_SERVICES,
    UPDATE_LOGGEDIN_USER_DATA_ERROR,
    UPDATE_LOGGEDIN_USER_DATA_SUCCESS,
    UPDATE_LOGGEDIN_USER_DATA,
} from '../constants';

import { DispatchType } from '../';
import { store } from '../store';

const getCurrentUserId = () => {
    const {
        user: { loggedInUser },
    } = store.getState();
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return userId;
    }
    throw new Error('loggedInUser is undefined');
};

export const loginAction = (dispatch: DispatchType) => async (userApi: IUserApi, params: IUserLoginParams) => {
    dispatch({
        type: LOGIN,
    });
    try {
        const result = await userApi.login(params);

        dispatch({
            type: LOGIN_SUCCESS,
            data: result,
        });
        return true;
    } catch (err) {
        console.error('error in loginAction %o', err);
        let loginErrorMessage = 'Something went wrong...';

        const status = parseResponseStatus(err);
        switch (status) {
            case 401:
                loginErrorMessage = 'Wrong email or password';
                break;
        }

        dispatch({
            type: LOGIN_ERROR,
            loginErrorMessage,
        });
    }
    return false;
};

export const logoutAction = (dispatch: DispatchType) => async (userApi: IUserApi) => {
    try {
        await userApi.logout();
    } catch (err) {
        console.log('error while loggin out %o', err);
    }
    dispatch({
        type: LOGOUT,
    });
};

export const signUpAction = (dispatch: DispatchType) => async (userApi: IUserApi, params: any) => {
    dispatch({
        type: SIGNUP,
    });
    try {
        await userApi.signUp(params);
        dispatch({
            type: SIGNUP_SUCCESS,
        });
        return true;
    } catch (err) {
        console.error('Error while signing up %o', err);
        let signUpErrorMessage = 'Something went wrong...';

        const status = parseResponseStatus(err);
        switch (status) {
            case 500:
                signUpErrorMessage = 'Device already belong to other user';
                break;
            case 422:
                signUpErrorMessage = 'This email or phone number is associated with an account. Please sign in.';
                break;
        }

        dispatch({
            type: SIGNUP_ERROR,
            signUpErrorMessage,
        });
    }
    return false;
};

export const changePasswordAction = (dispatch: DispatchType) => async (
    userApi: IUserApi,
    params: IChangePasswordParams
) => {
    dispatch({
        type: CHANGE_PASSWORD,
    });
    try {
        await userApi.changePassword(params);
        dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
        });
        return true;
    } catch (err) {
        let passwordChangeErrorMessage = 'Something went wrong...';
        const status = parseResponseStatus(err);
        switch (status) {
            case 400:
                passwordChangeErrorMessage = 'Invalid Old Password';
                break;
        }
        dispatch({
            type: CHANGE_PASSWORD_ERROR,
            data: {
                passwordChangeErrorMessage,
            },
        });
    }
    return false;
};

export const resetUserAction = (dispatch: DispatchType) => async (userApi: IUserApi, params: IUserResetParams) => {
    dispatch({
        type: RESET_PASSWORD,
    });
    try {
        await userApi.reset(params);
        dispatch({
            type: RESET_PASSWORD_SUCCESS,
        });
        return true;
    } catch (err) {
        let passwordResetErrorMessage = 'Something went wrong...';

        const status = parseResponseStatus(err);
        switch (status) {
            case 404:
                passwordResetErrorMessage = 'Email not found';
                break;
        }

        dispatch({
            type: RESET_PASSWORD_ERROR,
            data: {
                passwordResetErrorMessage,
            },
        });
    }
    return false;
};

export const updateLoggedInUserDataAction = (dispatch: DispatchType) => async (userApi: IUserApi) => {
    dispatch({
        type: UPDATE_LOGGEDIN_USER_DATA,
    });
    try {
        const userInfo = await userApi.getUserInfo({
            userId: getCurrentUserId(),
        });
        dispatch({
            type: UPDATE_LOGGEDIN_USER_DATA_SUCCESS,
            data: {
                userInfo,
            },
        });
    } catch (err) {
        dispatch({
            type: UPDATE_LOGGEDIN_USER_DATA_ERROR,
        });
    }
};

export const addAddressAction = (dispatch: DispatchType) => async (userApi: IUserApi, params: IAddAddressParams) => {
    dispatch({
        type: ADD_ADDRESS,
    });
    try {
        await userApi.addAddress(params);

        dispatch({
            type: ADD_ADDRESS_SUCCESS,
        });

        return true;
    } catch (err) {
        console.error('Error while adding address %o', err);
        const addAddressErrorMessage = 'Something went wrong...';

        dispatch({
            type: ADD_ADDRESS_ERROR,
            addAddressErrorMessage,
        });
    }
    return false;
};

export const updateHideServicesAction = (dispatch: DispatchType) => async (
    userApi: IUserApi,
    params: IUpdateSelectedServiceParams
) => {
    const hideServices = await userApi.updateSelectedServices(params);
    dispatch({
        type: UPDATE_HIDE_SERVICES,
        hideServices,
    });
};

export const setIsFirstVisitAction = (dispatch: DispatchType) => async (
    userApi: IUserApi,
    params: ISetIsFirstVisitParams
) => {
    const { userInfo } = await userApi.setIsFirstVisit(params);
    dispatch({
        type: UPDATE_LOGGEDIN_USER_DATA_SUCCESS,
        data: {
            userInfo,
        },
    });
};
