import { IPaymentSource, IPaymentSourceParams } from '../types';

import { IApiBase } from './api_base';

export interface IGetPaymentSourcesParams {
    userId: string;
}

export interface IGetPaymentMehodsResult {
    paymentSources: IPaymentSource[];
}

export interface IAddPaymentSourceParams {
    paymentSource: IPaymentSourceParams;
    userId: string;
}

export interface IRemovePaymentSourceParams {
    paymentMethodId: string;
    userId: string;
}

export interface ISetPaymentSourceActiveParams {
    id: string;
    userId: string;
}

export interface IPaymentApi {
    getPaymentSources(params: IGetPaymentSourcesParams): Promise<IGetPaymentMehodsResult>;
    addPaymentSource(params: IAddPaymentSourceParams): Promise<void>;
    removePaymentSource(params: IRemovePaymentSourceParams): Promise<void>;
    setPaymentSourceActive(params: ISetPaymentSourceActiveParams): Promise<void>;
}

const parsePaymentSource = ({
    active,
    cardHolderName,
    id,
    paymentMethodId,
    stripePaymentMethod,
}: any): IPaymentSource | undefined => {
    if (typeof paymentMethodId === 'string' && paymentMethodId) {
        return {
            active,
            cardHolderName,
            id,
            paymentMethodId,
            stripePaymentMethod,
        };
    }
    return undefined;
};

export class PaymentApi implements IPaymentApi {
    protected apiBase: IApiBase;

    constructor(apiBase: IApiBase) {
        this.apiBase = apiBase;
    }

    async getPaymentSources({ userId }: IGetPaymentSourcesParams): Promise<IGetPaymentMehodsResult> {
        const { data } = await this.apiBase.get(`/api/users/${userId}/getPaymentSources`);
        if (Array.isArray(data)) {
            return {
                paymentSources: data.map(parsePaymentSource).filter((item) => !!item) as IPaymentSource[],
            };
        }
        return {
            paymentSources: [],
        };
    }

    async addPaymentSource({ userId, paymentSource }: IAddPaymentSourceParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/addPaymentSource`, {
            ...paymentSource,
        });
    }

    async removePaymentSource({ userId, paymentMethodId }: IRemovePaymentSourceParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/removePaymentSource`, { paymentMethodId });
    }

    async setPaymentSourceActive({ id, userId }: ISetPaymentSourceActiveParams): Promise<void> {
        await this.apiBase.post(`/api/users/${userId}/setPaymentSourceActive`, { id });
    }
}
