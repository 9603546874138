import React, { useContext, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import _ from 'lodash';
import { Grid, Button, Box } from '@material-ui/core';

import { ApisContext } from '../../../lib/contexts';
import { DispatchType, RootState } from '../../../state';
import { setIsFirstVisitAction } from '../../../state/actions/userActions';

import imgStep1 from '../../../assets/images/homeOnboarding/homeStep1.png';
import imgStep2 from '../../../assets/images/homeOnboarding/homeStep2.png';
import imgStep3 from '../../../assets/images/homeOnboarding/homeStep3.png';
import imgStep4 from '../../../assets/images/homeOnboarding/homeStep4.png';
import imgStep5 from '../../../assets/images/homeOnboarding/homeStep5.png';
import imgStep6 from '../../../assets/images/homeOnboarding/homeStep6.png';
import imgstep7 from '../../../assets/images/homeOnboarding/homeStep7.png';
import './Onboarding.scss';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return {
            userId,
        };
    }
    return undefined;
};
const mapDispatchToProps = (dispatch: DispatchType) => ({
    setIsFirstVisit: setIsFirstVisitAction(dispatch),
});

const connector = connect(mapState, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Onboarding: React.FC<PropsFromRedux> = ({ setIsFirstVisit, userId }) => {
    const { userApi } = useContext(ApisContext);

    const steps = [
        'welcome',
        'groceries',
        'rides',
        'medications',
        'addresses',
        'doctor_appointments',
        'invite_loved_ones',
    ]; // order of onboarding
    const homeImages = [imgStep1, imgStep2, imgStep3, imgStep4, imgStep5, imgStep6, imgstep7];
    const homeStepMainText = [
        'Welcome to CAN Home',
        'Order groceries',
        'Order rides',
        'Deliver medication',
        'Manage your addresses',
        'Book doctor appointments',
        'Invite loved ones to CAN Home',
    ];

    const homeExplanationText = [
        'We prepared a quick tour for you. Click next to go through it',
        'Choose from a list of the most requested items and get them delivered to your door ',
        'Order a ride to or from one of your most visited spots. We partner with reliable services, such as Uber',
        'Use your doctor prescription to deliver medication to your door step.',
        'Your addresses are integrated with all these services. You can add or changes addresses from the settings page',
        'Use your insurance to book appointments with your physicians or select from our reliable network of healthcare providers ',
        'Invite those who might be interested in using CAN Home with you',
    ];

    const [state, setState] = useState({
        step: 0,
    });
    const lastStep = state.step === steps.length - 1;

    const closeModal = () => {
        if (userId) {
            setIsFirstVisit(userApi, { userId, isFirstVist: false });
        }
    };

    function nextStep() {
        if (lastStep) {
            closeModal();
        } else {
            setState({ step: state.step += 1 });
        }
    }

    return (
        <div className="onboarding">
            <Grid
                container
                item
                direction="column"
                justify="flex-start"
                alignItems="center"
                className="onboarding__top-container"
            >
                <Box
                    className="onboarding__header"
                    style={{
                        backgroundImage: `url(${homeImages[state.step]})`,
                    }}
                ></Box>
                <Grid
                    container
                    item
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    className="onboarding__main-container"
                >
                    <h2 className="onboarding__main-text">{homeStepMainText[state.step]}</h2>
                    <p className="onboarding__explanation-text">{homeExplanationText[state.step]}</p>
                </Grid>
                <Grid item>
                    {_.map(steps, (step, index) => {
                        if (state.step === index) {
                            return <div className="onboarding__step-circle onboarding__step-circle--active" />;
                        }

                        return <div className="onboarding__step-circle onboarding__step-circle--light" />;
                    })}
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="row"
                justify="space-between"
                alignItems="center"
                className="onboarding__footer"
            >
                <Grid item>
                    <Button
                        fullWidth
                        // variant="outlined"
                        className="onboarding__cancel-button"
                        onClick={closeModal}
                    >
                        Skip
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className="onboarding__save-button"
                        onClick={nextStep}
                    >
                        {lastStep ? 'Finish' : 'Next'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default connector(Onboarding);
