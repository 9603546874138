import React, { useContext } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Box, Button, Grid } from '@material-ui/core';

import { GridStringInputItem } from '../../../components/GridInputItem';
import InsuranceProvidersDropdown from '../../../components/InsuranceProvidersDropdown';

import { ApisContext } from '../../../lib/contexts';
import { IInsuranceProvider } from '../../../lib/types';
import { RootState, DispatchType } from '../../../state';

const mapState = ({ user: { loggedInUser } }: RootState) => {
    if (loggedInUser) {
        const { userId } = loggedInUser;
        return { userId };
    }
    return {};
};

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type FormState = Partial<IInsuranceProvider>;

interface Props {
    formState: FormState;
    setFormState: (newFormState: FormState) => void;
}

const InsuranceDialogContent: React.FC<Props & PropsFromRedux> = ({ formState, setFormState, userId }) => {
    const { userApi } = useContext(ApisContext);
    const { rxSubscriber, rxMemberID, rxGroup, rxPCN, rxRelationshipToSubscriber, rxBin, metaData } = formState;
    const uploadInsuranceCard = (side: 'backOfCard' | 'frontOfCard') => async ({
        target: { files },
    }: {
        target: {
            files: FileList | null;
        };
    }) => {
        if (files && userId) {
            const image = files[0];
            if (image) {
                const { getURL } = await userApi.uploadUserImage({
                    image,
                    userId,
                });
                setFormState({
                    ...formState,
                    [side]: getURL,
                });
            }
        }
    };
    return (
        <Grid container spacing={1}>
            <Box className="form-group full-width">
                <Grid container item xs={12}>
                    <Grid container item xs={6} justify="center">
                        <Button
                            variant="contained"
                            component="label"
                            disableRipple={true}
                            className="dashboard-btn filled"
                        >
                            Upload front of card
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={uploadInsuranceCard('frontOfCard')}
                                className="dashboard-btn filled"
                            />
                        </Button>
                    </Grid>
                    <Grid container item xs={6} justify="center">
                        <Button
                            variant="contained"
                            component="label"
                            disableRipple={true}
                            className="dashboard-btn filled"
                        >
                            Upload back of card
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={uploadInsuranceCard('backOfCard')}
                                className="dashboard-btn filled"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid item xs={12}>
                <InsuranceProvidersDropdown
                    insuranceProviderMetaData={metaData}
                    setInsuranceProviderMetaData={(metaData) =>
                        setFormState({
                            ...formState,
                            metaData,
                        })
                    }
                />
            </Grid>
            <GridStringInputItem
                xs={12}
                placeholder="Subscriber"
                value={rxSubscriber}
                setValue={(rxSubscriber) =>
                    setFormState({
                        ...formState,
                        rxSubscriber,
                    })
                }
            />
            <GridStringInputItem
                xs={12}
                placeholder="Member ID"
                value={rxMemberID}
                setValue={(rxMemberID) =>
                    setFormState({
                        ...formState,
                        rxMemberID,
                    })
                }
            />
            <GridStringInputItem
                xs={6}
                placeholder="6-digit number"
                value={rxBin}
                setValue={(rxBin) =>
                    setFormState({
                        ...formState,
                        rxBin,
                    })
                }
            />
            <GridStringInputItem
                xs={6}
                placeholder="Rx GRP"
                value={rxGroup}
                setValue={(rxGroup) =>
                    setFormState({
                        ...formState,
                        rxGroup,
                    })
                }
            />
            <GridStringInputItem
                xs={6}
                placeholder="Rx PCN"
                value={rxPCN}
                setValue={(rxPCN) =>
                    setFormState({
                        ...formState,
                        rxPCN,
                    })
                }
            />
            <GridStringInputItem
                xs={12}
                placeholder="Relationship"
                value={rxRelationshipToSubscriber}
                setValue={(rxRelationshipToSubscriber) =>
                    setFormState({
                        ...formState,
                        rxRelationshipToSubscriber,
                    })
                }
            />
        </Grid>
    );
};

export default connector(InsuranceDialogContent);
