import { CardBrand } from '../lib/types';
import { AxiosError } from 'axios';

export const parseResponseStatus = (err: any) => {
    const { isAxiosError } = err;
    if (isAxiosError) {
        const { response } = err as AxiosError;
        if (response) {
            const { status } = response;
            return status;
        }
    }
    return undefined;
};

export const getEnvironmentVariableOrThrow = (variable_name: string) => {
    const value = process.env[variable_name];
    if (!value) {
        throw new Error(`Missing environment variable ${variable_name}`);
    }
    return value;
};

export interface CardBrandInfo {
    cardBrand?: string;
    cardLogo?: string;
}

export const cardBrandsInfo: Record<CardBrand, CardBrandInfo> = {
    amex: {
        cardBrand: 'American Express',
        cardLogo: 'img/amex_logo.png',
    },
    cartes_bancaires: {
        cardBrand: 'Cartes Bancaires',
        cardLogo: 'img/cartes_bancaires_logo.png',
    },
    diners_club: {
        cardBrand: 'Diners Club',
        cardLogo: 'img/diners_club_logo.png',
    },
    discover: {
        cardBrand: 'Discover',
        cardLogo: 'img/discover_logo.png',
    },
    jcb: {
        cardBrand: 'Japan Credit Bureau',
        cardLogo: 'img/jcb_logo.png',
    },
    mastercard: {
        cardBrand: 'Mastercard',
        cardLogo: 'img/mastercard_logo.png',
    },
    visa: {
        cardBrand: 'Visa',
        cardLogo: 'img/visa_logo.png',
    },
    unionpay: { cardBrand: 'UnionPay', cardLogo: 'img/unionpay_logo.png' },
};

export const insuranceCompanies: { [index: string]: { companyName: string; companyLogo: string } } = {
    anthemBlueCross: {
        companyName: 'Anthem BlueCross',
        companyLogo: 'img/anthem_logo_placeholder.png',
    },
};

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}
