import React from 'react';
import _ from 'lodash';
import { Box, Button, Grid } from '@material-ui/core';

import { IGroceryProduct } from '../../lib/types';

import AddedCount from './AddedCount';

interface Props {
    product: IGroceryProduct;
    countInCart?: number;
    addToCart: (product: IGroceryProduct) => void;
    removeFromCart: (product: IGroceryProduct) => void;
}

const GroceryProduct: React.FC<Props> = ({ product, addToCart, countInCart, removeFromCart }) => {
    const { unit_price, units, image, name, name_long } = product;
    const count = countInCart || 0;
    return (
        <Box className="grocery-product">
            <Box className="productmodule">
                <Box className="hovereffect">
                    <Box className="productmodule-price">
                        ${unit_price} / {units || 'each'}
                    </Box>
                    <Box textAlign="center">
                        <img src={image || 'img/orderhistory/orderhistory_pic04.jpg'} alt={name} />
                    </Box>
                    <Box className="overlay">
                        <Box textAlign="center">
                            <Box className="productmodule-price-wh">${unit_price}</Box>
                            <h6>{_.capitalize(name)}</h6>
                            {name !== name_long && <h6>{_.capitalize(name_long)}</h6>}
                            {count > 0 && (
                                <Box className="btn-group productmodule-counter-wh" role="group" aria-label="...">
                                    <Button className="btn btn-default" disableRipple={true}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={4}
                                                className="productmodule-counter-wh-left"
                                                onClick={() => removeFromCart(product)}
                                            >
                                                -
                                            </Grid>
                                            <Grid item xs={4} className="productmodule-counter-wh-center">
                                                {count}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                className="productmodule-counter-wh-right"
                                                onClick={() => addToCart(product)}
                                            >
                                                +
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Box>
                            )}
                            {!count && (
                                <Box className="btn-group productmodule-counter-wh" role="group">
                                    <Button
                                        className="btn btn-default hovereffect-btadd"
                                        disableRipple={true}
                                        onClick={() => addToCart(product)}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <AddedCount count={count} />
                </Box>
            </Box>
        </Box>
    );
};

export default GroceryProduct;
