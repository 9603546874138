// ──── user ──────────────────────────────────────────────────
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const GROCERIES_ORDER_SUBMIT_ERROR = 'GROCERIES_ORDER_SUBMIT_ERROR';
export const GROCERIES_ORDER_SUBMIT_PENDING = 'GROCERIES_ORDER_SUBMIT_PENDING';
export const GROCERIES_ORDER_SUBMIT_SUCCESS = 'GROCERIES_ORDER_SUBMIT_SUCCESS';

export const GROCERIES_ADD_AVAILABLE_PRODUCTS = 'GROCERIES_ADD_AVAILABLE_PRODUCTS';
export const GROCERIES_ADD_PRODUCT_TO_CART = 'GROCERIES_ADD_PRODUCT_TO_CART';
export const GROCERIES_REMOVE_PRODUCT_FROM_CART = 'GROCERIES_REMOVE_PRODUCT_FROM_CART';
export const GROCERIES_CLEAR_CART = 'GROCERIES_CLEAR_CART';
export const GROCERIES_REORDER_CART = 'GROCERIES_REORDER_CART';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const RIDES_ORDER_SUBMIT_ERROR = 'RIDES_ORDER_SUBMIT_ERROR';
export const RIDES_ORDER_SUBMIT_PENDING = 'RIDES_ORDER_SUBMIT_PENDING';
export const RIDES_ORDER_SUBMIT_SUCCESS = 'RIDES_ORDER_SUBMIT_SUCCESS';
export const RIDES_REORDER_CART = 'RIDES_REORDER_CART';
export const RIDES_SET_CART_DROPOFF = 'RIDES_SET_CART_DROPOFF';
export const RIDES_SET_CART_PICKUP = 'RIDES_SET_CART_PICKUP';
export const RIDES_SET_CART_SERVICE = 'RIDES_SET_CART_SERVICE';
export const RIDES_CLEAR_CART = 'RIDES_CLEAR_CART';

export const MEDICATIONS_ORDER_SUBMIT_SUCCESS = 'MEDICATIONS_ORDER_SUBMIT_SUCCESS';
export const MEDICATIONS_ORDER_SUBMIT_ERROR = 'MEDICATIONS_ORDER_SUBMIT_ERROR';
export const MEDICATIONS_ORDER_SUBMIT_PENDING = 'MEDICATIONS_ORDER_SUBMIT_PENDING';
export const MEDICATIONS_REORDER_CART = 'MEDICATIONS_REORDER_CART';

export const MEDICATIONS_ADD_PRESCRIPTION_MEDICATION_TO_CART = 'MEDICATIONS_ADD_PRESCRIPTION_MEDICATION_TO_CART';
export const MEDICATIONS_ADD_OVER_THE_COUNTER_MEDICATION_TO_CART =
    'MEDICATIONS_ADD_OVER_THE_COUNTER_MEDICATION_TO_CART';
export const MEDICATIONS_CLEAR_CARTS = 'MEDICATIONS_CLEAR_CARTS';

export const DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_FROM =
    'DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_FROM';
export const DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_TO =
    'DOCTOR_APPOINTMENTS_EXISTING_CART_SET_AVAILABILITY_TO';
export const DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_HOSPITAL = 'DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_HOSPITAL';
export const DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_NAME = 'DOCTOR_APPOINTMENTS_EXISTING_CART_SET_DOC_NAME';
export const DOCTOR_APPOINTMENTS_EXISTING_CART_SET_IS_ONLINE_APPOINTMENT =
    'DOCTOR_APPOINTMENTS_EXISTING_CART_SET_IS_ONLINE_APPOINTMENT';
export const DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_FROM = 'DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_FROM';
export const DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_TO = 'DOCTOR_APPOINTMENTS_NEW_CART_SET_AVAILABILITY_TO';
export const DOCTOR_APPOINTMENTS_NEW_CART_SET_DOC_HOSPITAL = 'DOCTOR_APPOINTMENTS_NEW_CART_SET_DOC_HOSPITAL';
export const DOCTOR_APPOINTMENTS_NEW_CART_SET_IS_ONLINE_APPOINTMENT =
    'DOCTOR_APPOINTMENTS_NEW_CART_SET_IS_ONLINE_APPOINTMENT';
export const DOCTOR_APPOINTMENTS_NEW_CART_SET_SPECIALITY = 'DOCTOR_APPOINTMENTS_NEW_CART_SET_SPECIALITY';
export const DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR = 'DOCTOR_APPOINTMENTS_ORDER_SUBMIT_ERROR';
export const DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING = 'DOCTOR_APPOINTMENTS_ORDER_SUBMIT_PENDING';
export const DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS = 'DOCTOR_APPOINTMENTS_ORDER_SUBMIT_SUCCESS';
export const DOCTOR_APPOINTMENTS_SET_IS_EXISTING_DOCTOR_APPOINTMENT =
    'DOCTOR_APPOINTMENTS_SET_IS_EXISTING_DOCTOR_APPOINTMENT';
export const DOCTOR_APPOINTMENTS_CLEAR_NEW_CART = 'DOCTOR_APPOINTMENTS_CLEAR_NEW_CART';
export const DOCTOR_APPOINTMENTS_CLEAR_EXISTING_CART = 'DOCTOR_APPOINTMENTS_CLEAR_EXISTING_CART';
export const DOCTOR_APPOINTMENTS_REORDER_NEW_CART = 'DOCTOR_APPOINTMENTS_REORDER_NEW_CART';
export const DOCTOR_APPOINTMENTS_REORDER_EXISTING_CART = 'DOCTOR_APPOINTMENTS_REORDER_EXISTING_CART';

export const CAREGIVER_ORDER_SUBMIT_SUCCESS = 'CAREGIVER_ORDER_SUBMIT_SUCCESS';
export const CAREGIVER_ORDER_SUBMIT_ERROR = 'CAREGIVER_ORDER_SUBMIT_ERROR';
export const CAREGIVER_ORDER_SUBMIT_PENDING = 'CAREGIVER_ORDER_SUBMIT_PENDING';
export const CAREGIVER_SET_CART_START_DATE = 'CAREGIVER_SET_CART_START_DATE';
export const CAREGIVER_SET_CART_END_DATE = 'CAREGIVER_SET_CART_END_DATE';
export const CAREGIVER_SET_CART_DELIVERY_ADDRESS = 'CAREGIVER_SET_CART_DELIVERY_ADDRESS';
export const CAREGIVER_SET_CART_INSTRUCTIONS = 'RIDES_SET_CART_INSTRUCTIONS';
export const CAREGIVER_CLEAR_CART = 'CAREGIVER_CLEAR_CART';
export const CAREGIVER_REORDER_CART = 'CAREGIVER_REORDER_CART';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'USER_RESET_ERROR';

export const UPDATE_HIDE_SERVICES = 'UPDATE_HIDE_SERVICES';

export const UPDATE_LOGGEDIN_USER_DATA = 'UPDATE_LOGGEDIN_USER_DATA';
export const UPDATE_LOGGEDIN_USER_DATA_ERROR = 'UPDATE_LOGGEDIN_USER_DATA_ERROR ';
export const UPDATE_LOGGEDIN_USER_DATA_SUCCESS = 'UPDATE_LOGGEDIN_USER_DATA_SUCCESS';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_ERROR = 'ADD_ADDRESS_ERROR';

export const PAYMENT_GET_METHODS_PENDING = 'PAYMENT_GET_METHODS_PENDING';
export const PAYMENT_GET_METHODS_SUCCESS = 'PAYMENT_GET_METHODS_SUCCESS';
export const PAYMENT_GET_METHODS_ERROR = 'PAYMENT_GET_METHODS_ERROR';

export const INSURANCE_GET_PROVIDERS_ERROR = 'INSURANCE_GET_PROVIDERS_ERROR';
export const INSURANCE_GET_PROVIDERS_PENDING = 'INSURANCE_GET_PROVIDERS_PENDING';
export const INSURANCE_GET_PROVIDERS_SUCCESS = 'INSURANCE_GET_PROVIDERS_SUCCESS';
