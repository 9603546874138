import { IPaymentApi, IGetPaymentSourcesParams } from '../../lib/api';

import { DispatchType } from '../';
import { PAYMENT_GET_METHODS_ERROR, PAYMENT_GET_METHODS_PENDING, PAYMENT_GET_METHODS_SUCCESS } from '../constants';

export const getPaymentSourcesAction = (dispatch: DispatchType) => async (
    paymentApi: IPaymentApi,
    params: IGetPaymentSourcesParams
) => {
    dispatch({
        type: PAYMENT_GET_METHODS_PENDING,
    });

    try {
        const { paymentSources } = await paymentApi.getPaymentSources(params);
        dispatch({
            type: PAYMENT_GET_METHODS_SUCCESS,
            data: {
                paymentSources,
            },
        });
    } catch (e) {
        dispatch({
            type: PAYMENT_GET_METHODS_ERROR,
            data: {
                errorMessage: 'error',
            },
        });
    }
};
