import React from 'react';

import Grid from '@material-ui/core/Grid';

import { IRideOrder } from '../../lib/types';

import './styles.scss';

interface Props {
    order: IRideOrder;
}

const RideCartList: React.FC<Props> = ({
    order: {
        rideCart: {
            ridesPickup: { address: pickupAddress },
            ridesDropoff: { address: dropoffAddress },
        },
    },
}) => (
    <Grid container>
        <Grid container item xs={12}>
            <Grid item className="RideCartList__circle">
                A
            </Grid>
            <Grid item className="RideCartList__address">
                {pickupAddress}
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid item className="RideCartList__line" />
        </Grid>
        <Grid container item xs={12}>
            <Grid item className="RideCartList__circle">
                B
            </Grid>
            <Grid item className="RideCartList__address">
                {dropoffAddress}
            </Grid>
        </Grid>
    </Grid>
);

export default RideCartList;
